<template>
    <div class="tw-flex tw-flex-col">
        <div class="tw-flex tw-flex-row tw-justify-around tw-bg-white tw-p-4 tw-rounded-b-lg">
            <a
                :href="`/user/${user.id}/followers`"
                class="hover:tw-bg-primary-25 tw-px-4 tw-py-2 tw-rounded-lg"
            >
                <span class="font-bold">{{ followersCount }}</span>
                {{ $t('user-card.followers') }}
            </a>

            <a
                :href="`/user/${user.id}`"
                class="hover:tw-bg-primary-25 tw-px-4 tw-py-2 tw-rounded-lg"
                >{{ $t('user-card.view-profile') }}</a
            >
            <a
                :href="`/user/${user.id}/followings`"
                class="hover:tw-bg-primary-25 tw-px-4 tw-py-2 tw-rounded-lg"
            >
                <span class="tw-font-bold">{{ followingsCount }}</span>
                {{ $t('user-card.following') }}
            </a>
        </div>
    </div>
</template>
<script>
import { serverBus } from '../../app';

export default {
    props: ['currentUser', 'user', 'followers', 'followings'],

    created() {
        serverBus.$on('changeFollowersCount', (data) => {
            if (data == 'addFollower') {
                this.followersCount++;
            } else if (data == 'removeFollower') {
                this.followersCount--;
            }
        });
    },

    data() {
        return {
            followersCount: this.followers.length,
            followingsCount: this.followings.length,
        };
    },
};
</script>
