/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
/**
 * Vue Resource Import (allows us to use this.$http etc)
 */
import VueResource from 'vue-resource';

/**
 * CKEditor
 */
import CKEditor from '@ckeditor/ckeditor5-vue';

/**
 * VueCookie
 */
import VueCookie from 'vue-cookie';

/**
 * Vue internationalization
 */
import VueInternationalization from 'vue-i18n';
import route from 'ziggy';
import strings from '.././assets/js/ll_messages';

/**
 * Vue inputmask
 */

/**
 * Vuex
 */
import { store } from './store/index.js';

import vuetify from './plugins/vuetify';

import { Ziggy } from './ziggy';

import Rollbar from 'rollbar';

require('./bootstrap');

const $ = require('jquery');
Vue.use(VueResource);
Vue.use(CKEditor);
Vue.use(VueCookie);
Vue.use(VueInternationalization);

const lang = document.documentElement.lang.substr(0, 2);

const i18n = new VueInternationalization({
    locale: lang,
    messages: strings,
});

/**
 * Then we require all imports, these are each in their
 * seperate folder to keep things clear
 */
require('./imports/jquery.js');
require('./imports/vanilla.js');
require('./imports/vue.js');
require('./imports/vendor.js');

/**
 * Custom Vue Filters
 */
require('./filters/formatRingnumber.js');
require('./filters/formatDistance.js');

/**
 * Globel Vue Event Bus
 */
export const serverBus = new Vue();

require('./directives/closable');

/**
 * Bind Vue to the #app element
 */
const app = new Vue({
    el: '#application',
    i18n,
    store,
    vuetify
});

// Set the Rollbar instance in the Vue prototype
// before creating the first Vue instance.
// This ensures it is available in the same way for every
// instance in your app.
// Get the current URL
const currentURL = window.location.href;

let environment = "local";

if (currentURL.includes("app")) {
    environment = "production";
}

else if (currentURL.includes("staging")) {
    environment = "staging";
}

Vue.prototype.$rollbar = new Rollbar({
    accessToken: process.env.MIX_ROLLBAR_TOKEN_JS,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        environment: environment,
        code_version: '1.0.0',
    }
});


// If you have already set up a global error handler,
// just add `vm.$rollbar.error(err)` to the top of it.
// If not, this simple example will preserve the app’s existing
// behavior while also reporting uncaught errors to Rollbar.
Vue.config.errorHandler = (err, vm, info) => {
    vm.$rollbar.error(err);
    throw err; // rethrow
};

Vue.mixin({
    methods: {
        // route: route,
        route: (name, params, absolute) => route(name, params, absolute, Ziggy),
    },
});

if (typeof window.currentUserAvatar !== 'undefined') {
    window.currentUserAvatar = currentUserAvatar;
}
