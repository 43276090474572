<template>
    <div class="tw-p-2">
        <div class="tw-shadow tw-rounded-lg">
            <div class="tw-bg-indigo-200 tw-h-24 tw-rounded-t-lg"></div>
            <div class="tw-bg-white tw-p-4">
                <div class="tw-flex tw-flex-row">
                    <a :href="url" class="tw--mt-20 tw-pr-4">
                        <img
                            alt
                            style="width: 200px"
                            class="tw-rounded-full tw-border-4 tw-border-white"
                            :src="user_avatar"
                        />
                    </a>
                    <div class="tw-flex tw-flex-col tw-pb-4">
                        <a
                            :href="url"
                            class="tw-font-bold tw-text-lg tw-whitespace-no-wrap lg:tw-text-xl"
                            >{{ user.name }}</a
                        >
                        <span class="tw-text-sm tw-text-gray-500" v-if="user.country">
                            <i class="fas fa-map-marker-alt"></i>
                            {{ user.country }}
                        </span>
                    </div>
                </div>
                <div class="tw-flex tw-flex-row tw-justify-around tw-py-4">
                    <button
                        v-if="loggedInUserId != user.id"
                        class="tw-rounded tw-w-full tw-mx-2 focus:tw-outline-none"
                        :class="following ? 'btn-red-secondary' : 'btn-blue'"
                        v-on:click="toggleFollow()"
                    >
                        {{ following ? $t('user-card.unfollow') : $t('user-card.follow') }}
                    </button>
                    <a
                        :href="url"
                        class="tw-rounded btn-outline-blue tw-w-full tw-mx-2 tw-text-center focus:tw-outline-none"
                        >{{ $t('user-card.profile') }}</a
                    >
                </div>
            </div>
            <div
                class="tw-flex tw-flex-row tw-justify-around tw-bg-white tw-border-t tw-border-gray-400 tw-p-4 tw-rounded-b-lg"
            >
                <p>
                    <span class="tw-font-bold">{{ followersCount }}</span>
                    {{ $t('user-card.followers') }}
                </p>
                <p>
                    <span class="tw-font-bold">{{ followingsCount }}</span>
                    {{ $t('user-card.following') }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import getAvatarUrl from '../../mixins/avatar';

export default {
    props: ['user', 'cardIndex'],

    data() {
        return {
            following: this.user.isFollowing,
            url: '/user/' + this.user.id,
            followersCount: this.user.followers ? this.user.followers.length : 0,
            followingsCount: this.user.followings ? this.user.followings.length : 0,
            loggedInUserId: document.head.querySelector('meta[name="user"]').content,
        };
    },

    computed: {
        user_avatar() {
            return getAvatarUrl(this.user);
        },
    },

    methods: {
        toggleFollow() {
            axios.post(`/user/${this.user.id}/follow`).then((res) => {
                if (res.data.success == 1) {
                    this.following = true;
                    this.followersCount++;
                } else if (res.data.success == 0) {
                    this.following = false;
                    this.followersCount--;
                }
            });
        },
    },
};
</script>
