$(function () {
    var is_touch_device =
        'ontouchstart' in window || (window.DocumentTouch && document instanceof DocumentTouch);

	/**
     * Init bootstrap tooltips
     */
    $('[data-toggle="tooltip"]').tooltip({
        trigger: is_touch_device ? 'click' : 'hover',
    });

    /**
     * Listen for the show event on any triggered elements
     */
    $('[data-toggle="tooltip"]').on('show.bs.tooltip', function () {

        /**
         * Get a reference to the current element that is showing the tooltip
         */
        var triggeredElement = $(this);

        /**
         * Loop through all tooltips elements
         */
        $('[data-toggle="tooltip"]').each(function () {

            /**
             * If they are not the currently triggered element and have a tooltip,
             * trigger a click to close them
             */
            if ($(this) !== triggeredElement && $(this).next().hasClass('tooltip')) {
                $(this).click();
            }
        });
    });
});
