<template>
    <div>
        <div v-if="groups.length > 0">
            <v-data-iterator
                :items="items"
                :items-per-page.sync="itemsPerPage"
                :page="page"
                :search="search"
                :sort-by="sortBy.toLowerCase()"
                :sort-desc="sortDesc"
                hide-default-footer
            >
                <template v-slot:header>
                    <v-toolbar dark color="green darken-1" class="mb-1">
                        <v-text-field
                            v-model="search"
                            clearable
                            flat
                            solo-inverted
                            hide-details
                            :label="$t('helpers.search')"
                        ></v-text-field>
                        <template v-if="$vuetify.breakpoint.mdAndUp">
                            <v-spacer></v-spacer>
                            <v-select
                                v-model="sortBy"
                                flat
                                solo-inverted
                                hide-details
                                :items="keys"
                                :label="$t('helpers.sort-by')"
                            ></v-select>
                            <v-spacer></v-spacer>
                            <v-btn-toggle v-model="sortDesc" mandatory>
                                <v-btn large depressed color="green" :value="false">
                                    <v-icon>mdi-arrow-up</v-icon>
                                </v-btn>
                                <v-btn large depressed color="green" :value="true">
                                    <v-icon>mdi-arrow-down</v-icon>
                                </v-btn>
                            </v-btn-toggle>
                        </template>
                    </v-toolbar>
                </template>

                <template v-slot:default="props">
                    <v-row>
                        <v-col
                            v-for="item in props.items"
                            :key="item.name"
                            cols="12"
                            sm="6"
                            md="3"
                            lg="3"
                        >
                            <v-card>
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title class="headline">{{
                                            item.clock_group_name
                                        }}</v-list-item-title>
                                        <v-list-item-subtitle>{{
                                            item.flight_datetime
                                        }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-divider></v-divider>

                                <v-list dense>
                                    <v-list-item v-for="(key, index) in filteredKeys" :key="index">
                                        <v-list-item-content
                                            :class="{ 'green--text': sortBy === key }"
                                            >{{ getKeyLabel(key) }}:</v-list-item-content
                                        >
                                        <v-list-item-content
                                            class="align-end"
                                            :class="{ 'green--text': sortBy === key }"
                                            >{{
                                                getKeyValue(item, key, item[key])
                                            }}</v-list-item-content
                                        >
                                    </v-list-item>
                                </v-list>

                                <v-divider></v-divider>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        small
                                        color="green"
                                        class="white--text"
                                        dark
                                        :href="`/clock/${item.id}`"
                                        link
                                        v-text="$t('clock.go-to-group')"
                                    ></v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </template>

                <template v-slot:footer>
                    <v-row class="tw-px-4 mt-2" align="center" justify="center">
                        <v-spacer></v-spacer>
                        <!-- <span class="mr-4 grey--text">Page {{ page }} of {{ numberOfPages }}</span> -->
                        <v-btn fab dark color="green darken-1" class="mr-1" @click="formerPage">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn fab dark color="green darken-1" class="ml-1" @click="nextPage">
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-row>
                </template>
            </v-data-iterator>
        </div>

        <div
            v-else
            class="tw-flex tw-border tw-border-primary-500 tw-text-primary-500 tw-font-bold hover:tw-text-white hover:tw-bg-primary-500 tw-rounded-lg tw-p-3 tw-relative"
        >
            <div
                class="tw-pointer-events-none tw-flex tw-items-center tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3"
            >
                <i class="fas fa-plus-circle fa-2x"></i>
            </div>
            <a class="tw-pl-10" :href="'/clock/new'" jest="create_group">{{
                $t('clock.create-first-group')
            }}</a>
        </div>
    </div>
</template>

<script>
export default {
    props: ['groups'],

    data() {
        return {
            itemsPerPageArray: [4, 8, 12, 16],
            search: '',
            filter: {},
            sortDesc: false,
            page: 1,
            itemsPerPage: 4,
            sortBy: 'flight_datetime',
            keys: ['name', 'flight_distance', 'unloading_location'],
            items: this.groups,
        };
    },

    computed: {
        numberOfPages() {
            return Math.ceil(this.items.length / this.itemsPerPage);
        },

        filteredKeys() {
            return this.keys.filter((key) => key !== `name`);
        },
    },

    methods: {
        nextPage() {
            if (this.page + 1 <= this.numberOfPages) this.page += 1;
        },

        formerPage() {
            if (this.page - 1 >= 1) this.page -= 1;
        },

        updateItemsPerPage(number) {
            this.itemsPerPage = number;
        },

        getKeyLabel(key) {
            switch (key) {
                case 'flight_datetime':
                    return $trans('clock.date');
                    break;
                case 'flight_distance':
                    return $trans('clock.distance');
                case 'unloading_location':
                    return $trans('clock.unloading-place');
                    break;
            }
        },

        getKeyValue(item, key, itemKey) {
            Vue.prototype.$filters = Vue.options.filters;

            if (key == 'flight_distance') {
                return this.$filters.formatDistance(itemKey);
            } else {
                return itemKey;
            }
        },
    },
};
</script>

