(function () {
  const laravel = {
    initialize() {
      this.registerEvents();
    },

    registerEvents() {
      $('body').on('click', 'a[data-method]', this.handleMethod);
    },

    handleMethod(e) {
      const link = $(this);
      const httpMethod = link.data('method').toUpperCase();
      let form;

      // If the data-method attribute is not PUT or DELETE,
      // then we don't know what to do. Just ignore.
      if ($.inArray(httpMethod, ['PUT', 'DELETE']) === -1) {
        return;
      }

      // Allow user to optionally provide data-confirm="Are you sure?"
      if (link.data('confirm')) {
        if (!laravel.verifyConfirm(link)) {
          return false;
        }
      }

      form = laravel.createForm(link);
      form.submit();

      e.preventDefault();
    },

    verifyConfirm(link) {
      return confirm(link.data('confirm'));
    },

    createForm(link) {
      const csrfToken = $('meta[name="csrf-token"]').attr('content');

      const form = $('<form>', {
        method: 'POST',
        action: link.attr('href'),
      });

      const token = $('<input>', {
        name: '_token',
        type: 'hidden',
        value: csrfToken,
      });

      const hiddenInput = $('<input>', {
        name: '_method',
        type: 'hidden',
        value: link.data('method'),
      });

      return form.append(token, hiddenInput)
        .appendTo('body');
    },
  };

  laravel.initialize();
}());
