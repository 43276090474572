<template>
    <div class="tw-w-full">
        <div class="tw-flex tw-flex-col md:tw-flex-row tw-mb-12">
            <div class="tw-w-full md:tw-w-1/3">
                <h2
                    class="tw-text-2xl tw-font-bold"
                    dusk="outside_click"
                    v-text="$t('clock.group')"
                ></h2>
            </div>
            <div class="tw-w-full md:tw-w-2/3">
                <label
                    for="clockgroup-name"
                    class="label-tw"
                    v-text="$t('clock.group-name')"
                ></label>
                <input
                    required
                    v-model="clock_group_name"
                    id="clock_group_name"
                    dusk="clock_group_name"
                    type="text"
                    class="input-field-tw"
                    :placeholder="$t('clock.enter-group-name')"
                    @input="errors.name = undefined"
                />
                <span
                    v-if="errors.name"
                    v-text="errors.name"
                    class="tw-text-red-500 tw-text-xs"
                ></span>
            </div>
        </div>

        <div class="tw-flex tw-flex-col md:tw-flex-row">
            <div class="tw-w-full md:tw-w-1/3">
                <h2 class="tw-text-2xl tw-font-bold" v-text="$t('clock.flight')"></h2>

                <a
                    href="/unloading/new"
                    target="_blank"
                    v-text="$t('clock.ul-not-present')"
                    class="tw-block tw-text-red-600 hover:tw-text-red-900 tw-text-sm tw-mt-2 tw-mr-10"
                ></a>
            </div>

            <div class="tw-w-full md:tw-w-2/3">
                <div class="tw-flex tw-flex-col lg:tw-flex-row">
                    <div
                        class="tw-flex tw-flex-col tw-w-full lg:tw-w-2/3 lg:tw-mr-1 tw-mb-2 lg:tw-mb-0"
                    >
                        <label
                            for="clock_group_date"
                            class="label-tw"
                            v-text="$t('clock.date-flight')"
                        ></label>
                        <b-datepicker
                            id="clock_group_date"
                            icon="calendar-today"
                            v-model="clock_group_date"
                            :placeholder="$t('clock.pick-date')"
                            @input="errors.date = undefined"
                            :first-day-of-week="1"
                        ></b-datepicker>
                        <span
                            v-if="errors.date"
                            v-text="errors.date"
                            class="tw-text-red-500 tw-text-xs"
                        ></span>
                    </div>

                    <div
                        class="tw-flex tw-flex-col tw-w-full lg:tw-w-2/3 lg:tw-ml-1 tw-mb-2 lg:tw-mb-0"
                    >
                        <label
                            for="clock_group_flight_time"
                            class="label-tw"
                            v-text="$t('clock.departure-time')"
                        ></label>
                        <b-timepicker
                            id="clock_group_time"
                            dusk="clock_group_time"
                            icon="clock"
                            v-model="clock_group_flight_time"
                            :placeholder="$t('clock.select-time')"
                            :enable-seconds="enableSeconds"
                            :hour-format="format"
                            @input="errors.time = undefined"
                        ></b-timepicker>
                        <span
                            v-if="errors.time"
                            v-text="errors.time"
                            class="tw-text-red-500 tw-text-xs"
                        ></span>
                    </div>
                </div>

                <div class="tw-flex tw-flex-col lg:tw-flex-row tw-tw-mt-1 lg:tw-mt-3">
                    <div class="tw-w-full lg:tw-w-2/3 lg:tw-mr-1 tw-mb-2 lg:tw-mb-0">
                        <label
                            for="clockgroup_flight_distance"
                            class="label-tw"
                            v-text="$t('clock.distance')"
                        ></label>
                        <select
                            required
                            v-model="clock_group_flight_distance"
                            id="clockgroup_flight_distance"
                            dusk="clockgroup_flight_distance"
                            class="input-field-tw"
                            @input="errors.flight_distance = undefined"
                        >
                            <option
                                value="undefined"
                                disabled
                                selected
                                v-text="$t('clock.choose-a-flight-distance')"
                                class="tw-text-gray-700"
                            ></option>
                            <option
                                value="speed"
                                v-text="$t('clock.distance-speed')"
                                class="tw-text-gray-700"
                            ></option>
                            <option
                                value="middle"
                                v-text="$t('clock.distance-middle')"
                                class="tw-text-gray-700"
                            ></option>
                            <option
                                value="great"
                                v-text="$t('clock.distance-great-middle')"
                                class="tw-text-gray-700"
                            ></option>
                            <option
                                value="long"
                                v-text="$t('clock.distance-long')"
                                class="tw-text-gray-700"
                            ></option>
                            <option
                                value="extreme"
                                v-text="$t('clock.distance-extreme')"
                                class="tw-text-gray-700"
                            ></option>
                        </select>

                        <span
                            v-if="errors.flight_distance"
                            v-text="errors.flight_distance"
                            class="tw-text-red-500 tw-text-xs"
                        ></span>
                    </div>
                    <div class="tw-w-full lg:tw-w-2/3 lg:tw-ml-1">
                        <label
                            for="clockgroup_flight_distance"
                            class="label-tw"
                            v-text="$t('clock.unloading-place')"
                        ></label>
                        <select
                            required
                            v-model="clock_group_unloading_location"
                            id="clock_group_unloading_location"
                            dusk="clock_group_unloading_location"
                            class="input-field-tw"
                            @input="errors.unloading_location = undefined"
                        >
                            <option
                                value="undefined"
                                disabled
                                selected
                                v-text="$t('clock.choose-an-unloading-location')"
                                class="tw-text-gray-700"
                            ></option>
                            <option
                                v-for="(unloading_location, index) in unloadingLocations"
                                :key="index"
                                :value="unloading_location.unloading_location"
                                class="tw-text-gray-700"
                            >
                                {{ unloading_location.unloading_location }}
                            </option>
                        </select>

                        <span
                            v-if="errors.unloading_location"
                            v-text="errors.unloading_location"
                            class="tw-text-red-500 tw-text-xs"
                        ></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="tw-flex tw-flex-col md:tw-flex-row tw-mt-12">
            <div class="tw-w-full md:tw-w-1/3">
                <h2 class="tw-text-2xl tw-font-bold" v-text="$t('clock.add-members')"></h2>
            </div>
            <div class="tw-flex tw-flex-col tw-w-full md:tw-w-2/3 tw-mb-4 tw-relative">
                <label
                    for="clockgroup-members"
                    class="label-tw"
                    v-text="$t('clock.members')"
                ></label>
                <input
                    id="clockgroup-members"
                    dusk="clockgroup_members"
                    type="text"
                    class="input-field-tw"
                    @focus="show = true"
                    v-model="search"
                    autocomplete="off"
                    :placeholder="$t('clock.enter-member-name')"
                    ref="clock-create-input"
                />

                <div
                    class="tw-bg-white tw-absolute tw-w-full max-h-400 tw-overflow-y-scroll tw-shadow-xl tw-mt-16"
                    :class="show ? 'tw-block' : 'tw-hidden'"
                    ref="clock-create-list"
                    v-closable="{
                        exclude: ['clock-create-input', 'clock-create-list'],
                        handler: 'close',
                    }"
                >
                    <ul>
                        <li
                            v-for="(following, index) in filteredMembers"
                            :key="index"
                            class="tw-flex tw-justify-between tw-bg-white hover:tw-bg-blue-100 tw-p-2 tw-cursor-pointer"
                            @click="addMember(following)"
                            :data="'member-' + following.id"
                        >
                            <div class="tw-flex">
                                <img
                                    class="tw-w-10 tw-h-10 tw-rounded-full tw-mr-1"
                                    :src="getUserAvatar(following)"
                                />
                                <span class="tw-self-center tw-pointer-events-none">{{
                                    following.name
                                }}</span>
                            </div>
                        </li>
                    </ul>
                </div>

                <div
                    class="tw-bg-white tw-justify-between tw-w-full"
                    :class="selectedMembers.length > 0 ? 'tw-flex tw-flex-col' : 'tw-hidden'"
                >
                    <ul>
                        <li
                            class="tw-flex tw-justify-between tw-bg-white hover:tw-bg-blue-100 tw-p-2 tw-cursor-default"
                            :key="index"
                            v-for="(selectedMember, index) in selectedMembers"
                        >
                            <div class="tw-flex">
                                <img
                                    class="tw-w-10 tw-h-10 tw-rounded-full tw-mr-1"
                                    :src="getUserAvatar(selectedMember)"
                                />
                                <span class="tw-self-center tw-pointer-events-none">{{
                                    selectedMember.name
                                }}</span>
                            </div>
                            <button
                                v-on:click="removeMember(index)"
                                class="focus:tw-outline-none tw-cursor-pointer tw-text-xl"
                            >
                                <i class="fas fa-times"></i>
                            </button>
                        </li>
                    </ul>
                </div>

                <a
                    class="btn-primary tw-rounded tw-self-end tw-mt-4 tw-cursor-pointer"
                    type="submit"
                    dusk="submit_group"
                    @click.prevent="submitGroup"
                    >{{ $t('clock.create-group') }}</a
                >

                <v-dialog v-model="submitting" persistent width="300">
                    <v-card color="green darken-2" dark>
                        <v-card-text>
                            {{ $t('messages.creating-new-group') }}
                            <v-progress-linear
                                indeterminate
                                color="white"
                                class="mb-0"
                            ></v-progress-linear>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import getAvatarUrl from '../../mixins/avatar';
import { Datepicker, Timepicker } from 'buefy';

export default {
    props: ['followers', 'unloadingLocations'],

    data() {
        return {
            search: '',
            show: false,
            submitting: false,
            clock_group_name: undefined,
            clock_group_date: undefined,
            clock_group_flight_time: undefined,
            clock_group_flight_distance: undefined,
            clock_group_unloading_location: undefined,
            user_avatar: window.currentUserAvatar,

            selectedMembers: [],

            errors: {
                name: '',
                date: '',
                time: '',
                flight_distance: '',
                unloading_location: '',
            },

            formatAmPm: false,
            enableSeconds: true,
        };
    },

    components: {
        Datepicker,
        Timepicker,
    },

    computed: {
        filteredMembers() {
            return this.followers.filter((member) => {
                if (this.search != '') {
                    if (!this.selectedMembers.includes(member)) {
                        return member.name.toLowerCase().includes(this.search.toLowerCase());
                    }
                }
            });
        },

        format() {
            return this.formatAmPm ? '12' : '24';
        },
    },

    methods: {
        close: function () {
            this.show = false;
        },

        addMember(selectedMember) {
            this.show = false;

            if (!this.selectedMembers.includes(selectedMember)) {
                this.selectedMembers.push(selectedMember);
            }

            this.search = '';
        },

        getUserAvatar(user) {
            return getAvatarUrl(user);
        },

        removeMember(index) {
            this.selectedMembers.splice(index, 1);
        },

        submitGroup() {
            this.submitting = true;

            axios
                .post('/clock/new', {
                    name: this.clock_group_name,
                    flight_distance: this.clock_group_flight_distance,
                    unloading_location: this.clock_group_unloading_location,
                    date: this.clock_group_date
                        ? moment(this.clock_group_date).format('YYYY-MM-DD')
                        : undefined,
                    time: this.clock_group_flight_time
                        ? moment(this.clock_group_flight_time).format('HH:mm')
                        : undefined,
                    members: this.selectedMembers,
                })
                .then((res) => {
                    this.clock_group_name = '';
                    this.selectedMembers = [];
                    window.location = '/clock/' + res.data.clock_group_id;
                })
                .catch((error) => {
                    for (let [key, value] of Object.entries(error.response.data.errors)) {
                        this.errors[key] = value[0];
                    }

                    flash(trans('clock.create-failed'), 'error');
                    this.submitting = false;
                });
        },
    },
};
</script>

