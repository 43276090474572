<template>
    <div v-bind:class="{ open: openSuggestion }">
        <input
            class="input-field-tw-ext tw-rounded-l-none tw-relative"
            type="text"
            v-model="selectionMutable"
            @input="
                change();
                $emit('update:selection', $event.target.value);
            "
            @keydown.enter="enter"
            @keydown.down="down"
            @keydown.up="up"
            @focus="open = true"
            v-click-outside="onClickOutside"
        />
        <ul
            class="dropdown-menu-auto-complete tw-absolute tw-appearance-none tw-bg-gray-200 p-2 tw-text-sm tw-rounded tw-shadow-xl tw-overflow-y-auto tw-z-100"
            style="max-height: 300px; width: 212px"
        >
            <li
                v-for="(suggestion, i) in matches"
                v-bind:class="{ active: isActive(i) }"
                @click="suggestionClick(i)"
                :key="i"
            >
                <a href="#" class="tw-text-gray-700 tw-block tw-p-2">{{ suggestion }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
import vClickOutside from 'v-click-outside';

export default {
    data() {
        return {
            open: false,
            current: 0,
            selectionMutable: this.selection,
        };
    },

    props: {
        suggestions: {
            type: Array,
            required: true,
        },

        selection: {
            type: String | null,
            required: true,
            twoWay: true,
        },
    },

    directives: {
        clickOutside: vClickOutside.directive,
    },

    computed: {
        matches() {
            return this.suggestions.filter((str) => {
                return str.indexOf(this.selectionMutable) >= 0;
            });
        },

        openSuggestion() {
            return this.selectionMutable !== '' && this.matches.length != 0 && this.open === true;
        },
    },

    methods: {
        enter() {
            this.selectionMutable = this.matches[this.current];
            this.open = false;
        },

        up() {
            if (this.current > 0) this.current--;
        },

        down() {
            if (this.current < this.suggestions.length - 1) this.current++;
        },

        isActive(index) {
            return index === this.current;
        },

        change() {
            if (this.open == false) {
                this.open = true;
                this.current = 0;
            }
        },

        suggestionClick(index) {
            const match = this.matches[index];

            this.selectionMutable = match;

            this.$emit('update:selection', match);

            this.open = false;
        },

        onClickOutside() {
            this.open = false;
        },
    },
};
</script>

<style scoped>
.dropdown-menu-auto-complete {
    display: none;
}

.open ul {
    display: block;
}
</style>