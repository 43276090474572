<template>
    <v-row justify="center" class="tw-my-2">
        <v-btn color="teal lighten-1" dark @click="goToUrl">
            <i class="fas fa-dove fa-fw tw-mr-2"></i>
            {{ $t('show.profile') }}
        </v-btn>
    </v-row>
</template>

<script>
export default {
    props: ['pigeonId'],

    methods: {
        goToUrl() {
            window.location.href = `/pigeon/${this.pigeonId}`;
        },
    },
};
</script>
