<template>
    <div>
        <div class="tw-bg-gray-100 tw-order-2 lg:tw-order-none tw-shadow tw-mb-4">
            <div
                class="tw-flex tw-justify-between tw-border-b tw-border-gray-400 tw-p-2 lg:tw-px-4 lg:tw-py-2"
            >
                <h5
                    class="tw-font-bold tw-text-base lg:tw-text-lg xl:tw-font-normal xl:tw-text-2xl"
                    v-text="$t('clock.registered-pigeons')"
                ></h5>
                <div v-if="flightNotOverYet" class="tw-flex tw-flex-col lg:tw-flex-row tw-relative">
                    <label
                        for="clockgroup-pigeons"
                        class="label-tw tw-text-xs lg:tw-text-sm tw-text-right lg:tw-text-left tw-whitespace-no-wrap tw-mr-0 lg:tw-mr-2"
                        v-text="$t('clock.add-pigeon')"
                    ></label>
                    <select
                        v-model="pigeonToAdd"
                        @change="addPigeon"
                        name="pigeon-select-before-register"
                        class="tw-w-full tw-bg-white tw-border tw-border-gray-400 hover:tw-border-gray-500 tw-p-2 tw-text-sm tw-rounded tw-shadow tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
                    >
                        <option v-text="$t('clock.pick-a-pigeon')" value="a1a" disabled></option>
                        <option
                            v-for="(pigeon, index) in eligiblePigeons"
                            :key="index"
                            :value="pigeon.ringnumber"
                            class="tw-flex tw-justify-between tw-bg-white hover:tw-bg-blue-100 tw-p-2 tw-cursor-default"
                        >
                            {{ pigeon.ringnumber | formatRingnumber(pigeon.landcode) }}
                        </option>
                    </select>
                </div>
                <span v-else class="tw-text-xs" v-text="$t('clock.flight-is-over')"></span>
            </div>

            <div
                v-if="selectedPigeons.length > 0"
                class="tw-relative tw-bg-gray-100 tw-rounded-lg tw-order-2 lg:tw-order-none tw-shadow tw-p-2 lg:tw-p-4"
            >
                <div
                    v-if="loading"
                    class="tw-p-2 lg:tw-p-4 tw-bg-white tw-flex tw-flex-col tw-items-center"
                >
                    <v-progress-circular
                        :size="70"
                        :width="7"
                        color="green"
                        indeterminate
                    ></v-progress-circular>
                </div>
                <div
                    class="tw-mt-2 tw-justify-between tw-w-full"
                    :class="selectedPigeons.length > 0 ? 'tw-flex tw-flex-col' : 'tw-hidden'"
                >
                    <ul id="selected-pigeons" class="tw-flex tw-flex-wrap">
                        <li
                            :key="index"
                            v-for="(selectedPigeon, index) in selectedPigeons"
                            class="button-metallic--blue tw-select-none tw-mb-1 tw-mr-1 tw-text-xs lg:tw-text-sm tw-py-1"
                        >
                            <span :id="selectedPigeon.ringnumber + '-not-registered'">{{
                                selectedPigeon.ringnumber
                                    | formatRingnumber(selectedPigeon.landcode)
                            }}</span>
                            <button @click="removePigeon(index)" class="tw-self-start">
                                <i
                                    class="fas fa-times tw-pointer-events-auto tw-cursor-pointer tw-ml-2"
                                ></i>
                            </button>
                        </li>
                    </ul>
                </div>
                <div class="tw-flex tw-justify-end">
                    <button
                        v-if="selectedPigeons.length > 0"
                        class="tw-border-2 tw-border-blue-200 tw-p-1 tw-rounded tw-self-end tw-text-sm hover:tw-bg-white"
                        type="submit"
                        @click.prevent="addPigeons"
                        dusk="pigeon-select-before-register-submit"
                        v-text="
                            selectedPigeons.length > 1
                                ? $t('clock.add-pigeons')
                                : $t('clock.add-pigeon')
                        "
                    >
                        }
                    </button>
                </div>
            </div>

            <div v-if="flightNotOverYet" class="tw-bg-white">
                <div
                    v-if="loadingRegisteredPigeons"
                    class="tw-p-2 lg:tw-p-4 tw-bg-white tw-flex tw-flex-col tw-items-center"
                >
                    <v-progress-circular
                        :size="70"
                        :width="7"
                        color="green"
                        indeterminate
                    ></v-progress-circular>
                </div>
                <div
                    v-else-if="registeredPigeonsMutable.length > 0"
                    dusk="clock_show_registered_pigeons"
                    class="tw-p-2 lg:tw-p-4"
                >
                    <ul id="registered-pigeons" class="tw-flex tw-flex-wrap">
                        <li
                            :key="registeredPigeon.id"
                            v-for="(registeredPigeon, index) in registeredPigeonsMutable"
                            class="tw-py-1"
                        >
                            <v-btn
                                @click.stop="openDialog(registeredPigeon, index)"
                                class="block button-metallic tw-cursor-pointer tw-select-none tw-mb-1 tw-mr-1 tw-text-xs lg:tw-text-sm"
                            >
                                <i class="far fa-clock mr-2"></i>
                                {{
                                    registeredPigeon.ringnumber
                                        | formatRingnumber(registeredPigeon.landcode)
                                }}
                            </v-btn>
                        </li>
                    </ul>
                    <span
                        class="tw-group tw-mt-4 tw-text-gray-600 tw-text-xs tw-bg-blue-100 tw-p-2 tw-block tw-rounded-lg"
                    >
                        <i class="fas fa-info-circle group-hover:tw-text-gray-900"></i>
                        <span v-text="$t('clock.click-to-clock-info-text')"></span>
                    </span>
                </div>
                <div class="tw-p-2 lg:tw-p-4" v-else>
                    <span
                        class="tw-text-gray-500 tw-text-xs md:tw-text-base xl:tw-text-md"
                        v-text="$t('clock.no-registered-pigeons-yet')"
                    ></span>
                </div>
            </div>
            <div v-else class="tw-p-2 lg:tw-p-4 tw-bg-white">
                <span
                    class="tw-text-gray-500 tw-text-xs md:tw-text-base xl:tw-text-md"
                    v-text="$t('clock.flight-is-over')"
                ></span>
            </div>
        </div>

        <div class="tw-bg-gray-100 tw-order-2 lg:tw-order-none tw-shadow tw-mb-4">
            <div>
                <h5
                    class="tw-text-md lg:tw-text-2xl tw-border-b tw-border-gray-400 tw-p-2 lg:tw-px-4"
                >
                    <span
                        class="tw-font-bold tw-text-base lg:tw-text-lg xl:tw-font-normal xl:tw-text-2xl tw-whitespace-no-wrap"
                        v-text="$t('clock.clocked')"
                    ></span>
                </h5>

                <div
                    v-if="loadingClockedPigeons"
                    class="tw-p-2 lg:tw-p-4 tw-bg-white tw-flex tw-flex-col tw-items-center"
                >
                    <v-progress-circular
                        :size="70"
                        :width="7"
                        color="green"
                        indeterminate
                    ></v-progress-circular>
                </div>
                <div v-else-if="orderedUserClocked.length > 0" class="tw-p-2 lg:tw-p-4 tw-bg-white">
                    <ul class="tw-flex tw-flex-row tw-flex-wrap" dusk="clock_show_clocked_pigeons">
                        <li
                            :key="pigeon.id"
                            v-for="(pigeon, index) in orderedUserClocked"
                            class="tw-py-1"
                        >
                            <span
                                class="block button-metallic--green tw-pointer-events-none tw-select-none tw-mb-1 tw-mr-1 tw-text-xs lg:tw-text-sm"
                            >
                                <i class="far fa-clock"></i>
                                <span
                                    >{{ pigeon.ringnumber | formatRingnumber(pigeon.landcode) }} |
                                    {{ pigeon.clocked | ago }}</span
                                >
                                <i
                                    v-if="flightNotOverYet"
                                    class="fas fa-times tw-pointer-events-auto tw-cursor-pointer tw-ml-2"
                                    :dusk="'clock_show_unclock_pigeon_' + pigeon.ringnumber"
                                    @click="removeTime(pigeon, index)"
                                ></i>
                            </span>
                        </li>
                    </ul>
                    <span
                        class="tw-group tw-mt-4 tw-text-gray-600 tw-text-xs tw-bg-blue-100 tw-p-2 tw-block tw-rounded-lg"
                    >
                        <i class="fas fa-info-circle group-hover:tw-text-gray-900"></i>
                        <span v-text="$t('clock.click-to-unclock-info-text')"></span>
                    </span>
                </div>
                <div class="tw-p-2 lg:tw-p-4 tw-bg-white" v-else>
                    <span
                        class="tw-text-gray-500 tw-text-xs md:tw-text-base xl:tw-text-md"
                        v-text="$t('clock.no-clocked-pigeons')"
                    ></span>
                </div>
            </div>
        </div>

        <div class="tw-bg-gray-100 tw-order-2 lg:tw-order-none tw-shadow">
            <h5 class="tw-border-b tw-border-gray-400 tw-p-2 lg:tw-px-4">
                <span
                    class="tw-font-bold tw-text-base lg:tw-text-lg xl:tw-font-normal xl:tw-text-2xl"
                    v-text="$t('clock.ranking-list')"
                ></span>
            </h5>
            <clocking-ranking-list
                :ordered-all-clocked="orderedAllClocked"
                :loading-ranking-list="loadingRankingList"
            ></clocking-ranking-list>
        </div>

        <v-dialog v-model="dialog" persistent max-width="600">
            <v-card>
                <v-card-title
                    class="tw-flex tw-justify-between tw-text-gray-600 tw-bg-gray-100 tw-px-4 tw-py-2 tw-mb-2"
                    v-text="$t('clock.clock-pigeon')"
                ></v-card-title>
                <v-card-text>
                    <div class="tw-flex tw-justify-center">
                        <div class="tw-w-full lg:tw-w-1/2 tw-self-center">
                            <h3
                                class="tw-text-xl tw-text-center"
                                v-text="$t('clock.clock-right-now')"
                            ></h3>
                            <span
                                class="tw-block button-metallic tw-select-none tw-text-center"
                                @click="time"
                                dusk="clock_show_time_pigeon_now"
                                v-if="!timing"
                            >
                                <i class="far fa-clock"></i>
                                <span v-text="$t('clock.right-now')"></span>
                            </span>
                            <span v-else class="text-center">
                                <a class="tw-block button-metallic tw-select-none tw-disabled">
                                    <div class="lds-ellipsis">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </a>
                            </span>

                            <h3
                                class="tw-text-xl tw-text-center tw-mt-8"
                                v-text="$t('clock.choose-clock-time')"
                            ></h3>
                            <input
                                id="clock_time"
                                type="text"
                                class="input-field-tw tw-text-4xl tw-text-center"
                                name="clock-time"
                                placeholder="__:__:__"
                                v-model="clock_time"
                                dusk="clock_show_time_pigeon_at_given_input"
                                autocomplete="off"
                                :class="errors.clock_time ? 'tw-border tw-border-red-500' : ''"
                                @input="errors.clock_time = ''"
                            />
                            <span
                                v-if="errors.clock_time"
                                v-text="errors.clock_time"
                                class="tw-text-red-500 tw-text-xs"
                            ></span>
                            <span
                                class="tw-block button-metallic tw-select-none tw-text-center"
                                @click="time"
                                dusk="clock_show_time_pigeon_at_given"
                                v-if="!timing"
                            >
                                <i class="far fa-clock"></i>
                                <span v-text="$t('clock.self-selected-timestamp')"></span>
                            </span>
                            <span v-else class="tw-text-center">
                                <a
                                    class="tw-block button-metallic tw-select-none tw-mb-1 mr-1 tw-rounded tw-disabled"
                                >
                                    <div class="lds-ellipsis">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </a>
                            </span>
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error darken-1"
                        text
                        @click="dialog = false"
                        v-text="$t('helpers.close')"
                    ></v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import axiosInVue from 'axios';
import Inputmask from 'inputmask';
import ClockingRankingList from './ClockingRankingList';
var language = window.location.pathname.substring(1, 3);

export default {
    props: ['userId', 'clockGroupId', 'clockGroupDatetime'],

    data() {
        return {
            dialog: false,
            show: false,
            modalIndex: '',
            modalPigeon: '',
            clock_time: this.clock_time,

            pigeonToAdd: 'a1a',

            pigeons: [],
            allClocked: [],
            selectedPigeons: [],
            registeredPigeonsMutable: [],
            userClocked: [],

            selectedPigeonToClock: null,
            selectedPigeonToClockIndex: null,

            timing: false,
            loading: false,
            loadingRemoveTime: false,
            loadingRegisteredPigeons: false,
            loadingClockedPigeons: false,
            loadingRankingList: false,

            errors: {
                clock_time: '',
            },
        };
    },

    components: {
        ClockingRankingList,
    },

    mounted() {
        this.loadingRegisteredPigeons = true;
        this.loadingClockedPigeons = true;
        this.loadingRankingList = true;

        axiosInVue.get(`/clock_group/${this.clockGroupId}/pigeon`).then((res) => {
            this.pigeons = res.data;
        });

        axiosInVue.get(`/clock_group/${this.clockGroupId}/pigeon/unclocked`).then((res) => {
            this.registeredPigeonsMutable = res.data;
            this.loadingRegisteredPigeons = false;
        });

        axiosInVue.get(`/clock_group/${this.clockGroupId}/pigeon/clocked`).then((res) => {
            this.userClocked = res.data;
            this.loadingClockedPigeons = false;
        });

        axiosInVue.get(`/clock_group/${this.clockGroupId}/pigeon/clocked/all`).then((res) => {
            this.allClocked = res.data;
            this.loadingRankingList = false;
        });

        this.listenForNotification();
    },

    filters: {
        ago(date) {
            return moment(date).format('HH:mm:ss');
        },
    },

    computed: {
        eligiblePigeons() {
            return this.pigeons.filter((pigeon) => {
                if (
                    !JSON.stringify(this.selectedPigeons).includes(pigeon.ringnumber) &&
                    !JSON.stringify(this.registeredPigeonsMutable).includes(pigeon.ringnumber) &&
                    !JSON.stringify(this.userClocked).includes(pigeon.ringnumber)
                ) {
                    return pigeon;
                }
            });
        },

        orderedUserClocked: function () {
            return _.orderBy(this.userClocked, 'clocked');
        },

        orderedAllClocked: function () {
            return _.orderBy(this.allClocked, ['velocity'], ['desc']);
        },
    },

    methods: {
        /**
         * Add a pigeon to the selected pigeons
         */
        addPigeon(event) {
            // We get the index from the target, but we have to substract it with -1 because "Choose a pigeon" has option with index [0]
            this.selectedPigeons.push(this.eligiblePigeons[event.target.selectedIndex - 1]);
            this.pigeonToAdd = 'a1a';
        },

        /**
         * Remove a pigeon from the selected pigeons
         */
        removePigeon(index) {
            this.selectedPigeons.splice(index, 1);
            this.pigeonToAdd = 'a1a';
        },

        /**
         * Save the selected pigeons via a POST call
         */
        addPigeons() {
            this.loading = true;

            axios
                .post(route('clock.pigeon.store', this.clockGroupId), {
                    pigeonBag: this.selectedPigeons,
                })
                .then((res) => {
                    this.registeredPigeonsMutable.push(...res.data);
                    this.selectedPigeons = [];
                    this.loading = false;
                });
        },

        /**
         * Time a pigeon
         */
        time() {
            this.timing = true;

            axios
                .post(
                    route('clock.pigeon.time', [
                        this.clockGroupId,
                        this.userId,
                        this.selectedPigeonToClock.id,
                    ]),
                    {
                        clock_time: this.clock_time || moment(new Date()).format('HH:mm:ss'),
                    }
                )
                .then((res) => {
                    this.clock_time = '';
                    this.allClocked.push(...res.data);
                    this.userClocked.push(...res.data);
                    this.registeredPigeonsMutable.splice(this.selectedPigeonToClockIndex, 1);

                    this.timing = false;
                    this.dialog = false;
                })
                .catch((err) => {
                    let message = trans('clock.error-while-clocking');

                    if (
                        err.response.data.message.substring(1, 10) ==
                        trans('clock.to-slow-or-fast').substring(1, 10)
                    ) {
                        message = err.response.data.message;
                    } else {
                        message = err.response.data.errors.clock_time[0];
                    }

                    flash(message, 'error');

                    this.timing = false;
                });
        },

        /**
         * Set the given time for a pigeon to null, to reclock if you made a mistake
         */
        removeTime(pigeon, index) {
            this.loadingRemoveTime = true;

            axios
                .delete(
                    route('clock.pigeon.untime', [this.clockGroupId, this.userId, pigeon.id]),
                    {}
                )
                .then((res) => {
                    this.registeredPigeonsMutable.push(res.data);
                    this.userClocked = this.userClocked.filter(function (el) {
                        return el.ringnumber !== pigeon.ringnumber;
                    });
                    this.allClocked = this.allClocked.filter(function (el) {
                        return el.ringnumber !== pigeon.ringnumber;
                    });
                    this.loadingRemoveTime = false;
                });
        },

        /**
         * Check to see if an object is empty
         */
        isEmpty(obj) {
            for (var prop in obj) {
                if (obj.hasOwnProperty(prop)) return false;
            }
            return true;
        },

        openDialog(pigeon, index) {
            this.dialog = true;
            this.selectedPigeonToClock = pigeon;
            this.selectedPigeonToClockIndex = index;

            setTimeout(() => {
                this.addInputMask();
            }, 1000);
        },

        /**
         * Add inputmask 99:99:99 to the model input
         */
        addInputMask() {
            var selector = document.getElementById('clock_time');
            Inputmask({ mask: '99:99:99' }).mask(selector);
        },

        /**
         * Check if the clock group datetime is past, if yes, return false
         */
        flightNotOverYet() {
            const tomorrow = new Date();
            return new Date(this.clockGroupDatetime) > tomorrow.setDate(tomorrow.getDate() - 1);
        },

        /**
         * Listen for new clocked pigeons
         *
         */
        listenForNotification() {
            if (this.userId) {
                window.Echo.private('App.User.' + this.userId).notification((notification) => {
                    const notificationTypes = [
                        'App\\Notifications\\PigeonClocked',
                        'App\\Notifications\\PigeonUnclocked',
                    ];

                    // Clocked notifications are pushed on the the allClocked list
                    if (notification.type === notificationTypes[0]) {
                        const newClocked = {
                            clocked: notification.data.clocked,
                            name: notification.data.issuer_name,
                            velocity: notification.data.velocity,
                            id: notification.data.pigeon_id,
                            landcode: notification.data.landcode,
                            ringnumber: notification.data.ringnumber,
                        };
                        this.allClocked.push(newClocked);
                    }

                    // This bit of code removes the unclocked pigeon from the ranking list
                    if (notification.type === notificationTypes[1]) {
                        this.allClocked = this.allClocked.filter((clocked) => {
                            return clocked.id !== notification.data.pigeon_id;
                        });
                    }
                });
            }
        },
    },
};
</script>

<style>
/* Overwrites the ellipsis width so that it centers */
.lds-ellipsis {
    width: 65px;
}
</style>
