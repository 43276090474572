<template v-cloak>
    <div>
        <add-result
            v-if="canUpdate"
            :unloading-locations="unloadingLocations"
            :post-url="postUrl"
            @resultAdded="addResultToTable"
        ></add-result>

        <v-card>
            <v-card-title>
                <span v-text="$t('result.overview-results-of-pigeon')"></span>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('helpers.search')"
                ></v-text-field>
            </v-card-title>
            <v-data-table
                item-key="id"
                class="elevation-1"
                show-expand
                :items="items"
                :search="search"
                :headers="headers"
                :items-per-page="10"
                :single-expand="false"
                :loading="loadingState"
                :expanded.sync="expanded"
                :footer-props="footerProps"
                :sort-by="['date_start_time']"
                :sort-desc="[false, true]"
                :multi-sort="true"
                @click:row="clicked"
            >
                <template v-slot:item.favorited="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                small
                                color="yellow lighten-2"
                                @click.native="toggleFavorite(item, item.id)"
                            >
                                <v-icon v-if="item.favorited">mdi-star</v-icon>
                                <v-icon v-else>mdi-star-outline</v-icon>
                            </v-btn>
                        </template>
                        <span v-text="$t('tooltip.favorite-result')"></span>
                    </v-tooltip>
                </template>
                <template v-slot:item.delete="{ index, item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                small
                                color="red lighten-2"
                                @click.native="deleteResult(item, item.id)"
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <span v-text="$t('tooltip.delete-result')"></span>
                    </v-tooltip>
                </template>
                <template v-slot:item.place="{ item }">
                    <span class="tw-block">
                        <strong>{{ item.place }}</strong>
                        {{ $t('tooltip.out-of') }} {{ item.ad | numberWithSpaces }}
                    </span>
                </template>
                <template v-slot:item.competition="{ item }">
                    <span class="tw-block tw-capitalize">
                        {{ item.competition }}
                    </span>
                </template>
                <template v-slot:item.date_start_time="{ item }">
                    <span>
                        {{ momentWrapper(item.unloading_time, 'DD MMM YYYY') }}
                        <br />
                        <strong>{{ momentWrapper(item.date_start_time, 'HH:mm:ss') }}</strong>
                    </span>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="tw-py-6">
                        <span class="tw-block">
                            <strong>{{ $t('result.gt') }}:</strong>
                            {{ item.gt }}
                        </span>
                        <span class="tw-block">
                            <strong>{{ $t('result.distance') }}:</strong>
                            {{ item.distance | numberWithSpaces }}
                        </span>
                    </td>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import moment from 'moment';
import AddResult from './AddResult';

const headersAlloweduser = [
    { text: '', value: 'favorited', sortable: false, filterable: false },
    { text: trans('pigeon.unloading_location'), value: 'unloading_location' },
    { text: trans('pigeon.place'), value: 'place' },
    { text: trans('pigeon.competition'), value: 'competition' },
    { text: trans('pigeon.wind'), value: 'wind_formatted' },
    { text: trans('pigeon.date_start_time'), value: 'date_start_time' },
    { text: trans('pigeon.velocity'), value: 'velocity' },
    { text: '', value: 'delete', sortable: false, filterable: false },
    { text: trans('list.actions'), value: 'data-table-expand' },

    { text: '', value: 'ad', align: ' d-none' },
];

const headersNonAlloweduser = [
    { text: trans('pigeon.unloading_location'), value: 'unloading_location' },
    { text: trans('pigeon.place'), value: 'place' },
    { text: trans('pigeon.competition'), value: 'competition' },
    { text: trans('pigeon.wind'), value: 'wind_formatted' },
    { text: trans('pigeon.date_start_time'), value: 'date_start_time' },
    { text: trans('pigeon.velocity'), value: 'velocity' },
    { text: trans('list.actions'), value: 'data-table-expand' },

    { text: '', value: 'ad', align: ' d-none' },
];

export default {
    props: ['results', 'canUpdate', 'unloadingLocations', 'postUrl'],

    data() {
        return {
            headers: this.canUpdate ? headersAlloweduser : headersNonAlloweduser,
            items: this.results,
            expanded: [],
            search: '',
            footerProps: { 'items-per-page-options': [5, 10, 15, 30, 50, 100] },

            dialog: false,
            loadingState: false,
        };
    },

    components: {
        AddResult,
    },

    methods: {
        getStarMarkup(favorited) {
            return favorited != null || favorited != undefined
                ? "<i class='fas fa-star yellow-icon cursor-pointer hover:opacity-75'></i>"
                : "<i class='far fa-star yellow-icon cursor-pointer hover:opacity-75'></i>";
        },

        toggleFavorite(item, resultId) {
            this.loadingState = true;

            axios
                .get(`/result/${resultId}/favorite`)
                .then((res) => {
                    // This loop lets us change the markup of the star
                    if (res.data.toggle == 'off') {
                        item.favorited = null;
                        flash(res.data.message, res.data.type);
                    } else {
                        item.favorited = 'full';
                        flash(res.data.message, res.data.type);
                    }

                    this.loadingState = false;
                })
                .catch((err) => {
                    flash(err.response.data.message, 'error');
                    this.loadingState = false;
                });
        },

        addResultToTable(result) {
            this.items.unshift(result);
        },

        deleteResult(item, resultId) {
            this.loadingState = true;

            axios
                .delete(`/result/${resultId}/delete`)
                .then((res) => {
                    flash(res.data.message, res.data.type);
                    this.loadingState = false;
                    location.reload();
                })
                .catch((err) => {
                    flash(err.response.data.message, 'error');
                    this.loadingState = false;
                });
        },

        clicked(value, data) {
            if (event.target.tagName == 'TD') {
                if (!data.isExpanded) {
                    this.expanded.push(value);
                } else {
                    this.expanded = this.expanded.filter((pigeon) => {
                        return value.id != pigeon.id;
                    });
                }
            }
        },

        momentWrapper(value, format) {
            return moment(value).format(format);
        },
    },

    filters: {
        numberWithSpaces(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        },
    },
};
</script>
