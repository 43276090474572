export const pigeon_name = {
    state: () => ({
        name: null,
        isPigeonNameSetViaPedigreeDetails: false,
    }),

    mutations: {
        changePigeonName(state, payload) {
            state.name = payload;
        },

        changeIsPigeonNameSetViaPedigreeDetails(state, payload) {
            state.isPigeonNameSetViaPedigreeDetails = payload;
        },
    },

    actions: {
        changePigeonNameAction({ commit }, name) {
            commit("changePigeonName", name);
        },

        changeIsPigeoNameSetViaPedigreeDetailsAction({ commit }, name) {
            commit("changeIsPigeoNameSetViaPedigreeDetails", name);
        },
    },
};
