Vue.filter('formatDistance', function (distance) {
    switch (distance) {
        case 'speed':
            return trans('clock.distance-speed');
        case 'middle':
            return trans('clock.distance-middle');
        case 'great':
            return trans('clock.distance-great-middle');
        case 'long':
            return trans('clock.distance-long');
        case 'extreme':
            return trans('clock.distance-extreme');
    }
});
