<template>
    <v-tabs v-model="tab" show-arrows class="elevation-1">
        <v-tab>{{ $t('recover.received-disputes') }}</v-tab>
        <v-tab>{{ $t('recover.issued-disputes') }}</v-tab>

        <v-tabs-items v-model="tab">
            <v-tab-item>
                <received-disputes-table
                    :received-disputes="receivedDisputes"
                ></received-disputes-table>
            </v-tab-item>
            <v-tab-item>
                <issued-disputes-table :issued-disputes="issuedDisputes"></issued-disputes-table>
            </v-tab-item>
        </v-tabs-items>
    </v-tabs>
</template>

<script>
export default {
    props: ['issuedDisputes', 'receivedDisputes'],

    data() {
        return {
            tab: null,
        };
    },
};
</script>
