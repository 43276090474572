<template>
    <div v-if="showBetaMessage">
        <v-dialog v-model="dialog" persistent max-width="600" v-if="showBetaMessage">
            <template v-slot:activator="{ on }">
                <v-btn
                    color="info"
                    v-on="on"
                    class="tw-bottom-0 tw-fixed tw-mr-6 tw-mb-6 tw-right-0 tw-cursor-pointer tw-z-50 tw-flex tw-items-center hover:tw-shadow-md"
                >
                    <i class="fas fa-hand-pointer"></i>
                    <span class="tw-normal-case tw-ml-2" v-text="$t('beta.read-me')"></span>
                </v-btn>
            </template>
            <v-card>
                <v-card-title class="headline">
                    <span class="tw-flex tw-justify-between tw-cursor-pointer">
                        <h2
                            class="tw-font-bold tw-text-gray-800 tw-text-xl"
                            v-text="$t('beta.title')"
                        ></h2>
                    </span>
                </v-card-title>
                <v-card-text>
                    <div class="tw-p-4 tw-bg-gray-200 tw-rounded">
                        <p
                            class="tw-text-gray-800 tw-text-base tw-mb-2"
                            v-text="$t('beta.line1')"
                        ></p>
                        <p
                            class="tw-text-gray-800 tw-text-base tw-mb-2"
                            v-text="$t('beta.line2')"
                        ></p>
                        <p
                            class="tw-text-gray-800 tw-text-base tw-mb-2"
                            v-text="$t('beta.line3')"
                        ></p>
                        <p
                            class="tw-text-gray-800 tw-text-base tw-mb-4"
                            v-text="$t('beta.line4')"
                        ></p>
                        <span
                            class="tw-text-gray-800 tw-text-base tw-inline-block tw-mb-4"
                            v-text="$t('beta.mailto')"
                        ></span>
                        <a
                            class="tw-text-primary-400 hover:tw-opacity-75"
                            href="mailto:info@pigero.com"
                            >info@pigero.com</a
                        >
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red darken-3"
                        text
                        @click="cookieClose"
                        v-text="$t('beta.dont-show-again')"
                    ></v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="dialog = false"
                        v-text="$t('helpers.close')"
                    ></v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
const path = window.location.pathname;

export default {
    data() {
        return {
            dialog: false,
            showBetaMessage:
                this.$cookie.get('pigero-beta-message') == 'true' ||
                this.$cookie.get('pigero-beta-message') == null,
        };
    },

    computed: {
        isInPedigreePage() {
            return path.includes('tree/view') || path.includes('pedigree');
        },
    },

    methods: {
        cookieClose() {
            this.showBetaMessage = false;
            this.$cookie.set('pigero-beta-message', false, 30);
            this.dialog = false;
        },

        findOutIfMessageShouldShow() {
            if (this.$cookie.get('pigero-beta-message') == null) {
                return true;
            } else {
                return false;
            }

            if (true == 'true') {
                return true;
            } else {
                return false;
            }
        },
    },

    mounted() {
        if (this.isInPedigreePage) {
            this.showBetaMessage = false;
        }
    },
};
</script>
