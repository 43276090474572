<template>
    <v-card>
        <v-data-table :headers="headers" :items="activeUserArray" :items-per-page="50" class="elevation-1">
            <template v-slot:user_name="{ item }">
                <span>{{ item.user_name }}</span>
            </template>

            <template v-slot:amount_of_pigeons="{ item }">
                <span>{{ item.pigeons.length }}</span>
            </template>

            <template v-slot:first_active="{ item }">
                <span>{{ firstActive(item.pigeons) }}</span>
            </template>

            <template v-slot:last_active="{ item }">
                <span>{{ lastActive(item.pigeons) }}</span>
            </template>
        </v-data-table>

    </v-card>
</template>

<script>
import moment from 'moment';

export default {
    props: ['active'],

    data() {
        return {
            headers: [
                {
                    text: 'User',
                    value: 'user_name',
                    align: 'start',
                    sortable: true,
                },
                {
                    text: 'Amount of pigeons',
                    value: 'amount_of_pigeons',
                    align: 'start',
                    sortable: true,
                },
                {
                    text: 'First active',
                    value: 'first_active',
                    align: 'start',
                    sortable: true,
                },
                {
                    text: 'Last active',
                    value: 'last_active',
                    align: 'start',
                    sortable: true,
                },
            ],
        };
    },

    computed: {
        activeUserArray() {
            return Object.entries(this.active).map(([user_name, pigeons]) => ({
                user_name,
                amount_of_pigeons: pigeons.length,
                pigeons,
                first_active: this.firstActive(pigeons),
                last_active: this.lastActive(pigeons),
            }));
        },
    },

    methods: {
        firstActive(pigeons) {
            const firstCreated = pigeons.reduce((a, b) =>
                a.created_at < b.created_at ? a : b
            ).created_at;

            return moment(firstCreated).fromNow();
        },

        lastActive(pigeons) {
            const lastUpdated = pigeons.reduce((a, b) =>
                a.updated_at > b.updated_at ? a : b
            ).updated_at;

            return moment(lastUpdated).fromNow();
        },
    },
};
</script>