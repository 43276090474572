<template v-cloak>
    <v-card>
        <v-card-title>
            <span v-text="$t('list.your-co-breeders')"></span>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('helpers.search')"
            ></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :single-expand="false"
            item-key="id"
            :items-per-page="10"
            class="elevation-1"
            :footer-props="footerProps"
            :loading="loadingState"
            :sort-by="['ringnumber']"
            :sort-desc="[false, true]"
            :multi-sort="true"
            @click:row="clicked"
        >
            <template v-slot:item.pigeon_name="{ item }">
                <a
                    v-if="item.public"
                    v-text="item.pigeon_name"
                    :href="`/pigeon/${item.id}`"
                    class="tw-cursor-pointer hover:tw-text-blue-400"
                ></a>
                <span v-else v-text="item.pigeon_name"></span>
            </template>
            <template v-slot:item.ringnumber="{ item }">
                <a
                    v-if="item.public"
                    v-text="item.ringnumber"
                    :href="`/pigeon/${item.id}`"
                    class="tw-cursor-pointer hover:tw-text-blue-400"
                ></a>
                <span v-else v-text="item.ringnumber"></span>
            </template>
            <template v-slot:item.actions="{ item }">
                <div class="tw-flex tw-flex-col lg:tw-flex-row tw-py-3">
                    <a
                        :title="$t('tooltip.edit-pedigree-details')"
                        :href="`co-breeders/${item.id}/pedigree/details`"
                    >
                        <v-btn text small dusk="pigeon-edit">
                            <i class="fas fa-edit fa-fw tw-mr-1"></i>
                            {{ $t('tooltip.edit-pedigree-details') }}
                        </v-btn>
                    </a>
                    <a
                        v-if="item.public"
                        :title="$t('tooltip.view-pigeon-profile')"
                        :href="`pigeon/${item.id}`"
                    >
                        <v-btn text small dusk="pigeon-profile">
                            <i class="far fa-eye fa-fw tw-mr-1"></i>
                            {{ $t('tooltip.view-pigeon-profile') }}
                        </v-btn>
                    </a>
                </div>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
export default {
    props: ['pigeons'],

    data() {
        return {
            headers: [
                { text: trans('list.name'), value: 'pigeon_name', align: 'start' },
                { text: trans('list.landcode'), value: 'landcode', align: 'start' },
                { text: trans('list.ringnumber'), value: 'ringnumber', align: 'start' },
                { text: trans('list.actions-real'), value: 'actions', sortable: false },
            ],
            items: this.pigeons,
            search: '',
            footerProps: { 'items-per-page-options': [5, 10, 15, 30, 50, 100] },

            dialog: false,
            loadingState: false,
        };
    },

    methods: {
        clicked(value, data) {
            if (event.target.tagName == 'TD') {
                if (!data.isExpanded) {
                    this.expanded.push(value);
                } else {
                    this.expanded = this.expanded.filter((pigeon) => {
                        return value.id != pigeon.id;
                    });
                }
            }
        },
    },
};
</script>
