window.onload = function () {
    /**
    * Prevent hover
    */
    function fix() {
        const el = this;
        const par = el.parentNode;
        const next = el.nextSibling;
        par.removeChild(el);
        setTimeout(() => {
            par.insertBefore(el, next);
        }, 0);
    }
};
