<template>
    <v-tabs v-model="tab" show-arrows class="elevation-1">
        <v-tab>{{ $t('recover.received-requests') }}</v-tab>
        <v-tab>{{ $t('recover.issued-requests') }}</v-tab>

        <v-tabs-items v-model="tab">
            <v-tab-item>
                <received-requests-table
                    :received-recover-requests="receivedRecoverRequests"
                ></received-requests-table>
            </v-tab-item>
            <v-tab-item>
                <issued-requests-table
                    :issued-recover-requests="issuedRecoverRequests"
                ></issued-requests-table>
            </v-tab-item>
        </v-tabs-items>
    </v-tabs>
</template>

<script>
export default {
    props: ['issuedRecoverRequests', 'receivedRecoverRequests'],

    data() {
        return {
            tab: null,
        };
    },
};
</script>
