<template v-cloak></template>

<script>
export default {
    name: 'DropdownMenu',

    data() {
        return {
            show: false,
        };
    },

    methods: {
        /**
         * Toggle notifications dropdown visibility
         */
        toggle: function() {
            this.show = !this.show;
        },

        /**
         * Close notifications dropdown visibility
         */
        close: function() {
            this.show = false;
        },
    },
};
</script>

