<template v-cloak>
    <v-card>
        <v-card-title>
            <span v-text="$t('recover.received-disputes')"></span>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('helpers.search')"
            ></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page="10"
            :footer-props="footerProps"
            :loading="loadingState"
            :multi-sort="true"
            item-key="id"
            class="elevation-1"
        >
            <template v-slot:item.pigeon.ringnumber="{ item }">
                <a
                    :href="`/pigeon/${item.pigeon.id}`"
                    class="tw-cursor-pointer hover:tw-text-blue-400"
                >
                    <span>{{ item.pigeon.ringnumber }}</span>
                </a>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
export default {
    props: ['receivedDisputes'],

    data() {
        return {
            headers: [
                { text: trans('recover.landcode'), value: 'landcode' },
                { text: trans('recover.ringnumber'), value: 'pigeon.ringnumber' },
                { text: trans('recover.issueing-user'), value: 'issueing_user.name' },
                { text: trans('recover.winning-user'), value: 'winner.name' },
            ],
            items: this.receivedDisputes || [],
            search: '',
            footerProps: { 'items-per-page-options': [5, 10, 15, 30, 50, 100] },

            dialog: false,
            infoToShow: null,
            loadingState: false,
        };
    },
};
</script>
