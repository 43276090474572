<template>
    <div>
        <div
            class="tw-bg-primary-200 tw-flex tw-flex-rowtw- py-1 tw-px-2 tw-shadow tw-rounded-t-lg"
        >
            <a :href="'/user/' + post.user.id">
                <div class="tw-p-2">
                    <img
                        alt
                        class="tw-w-16 tw-h-16 tw-border-2 tw-border-white tw-rounded tw-shadow"
                        :src="this.user_avatar"
                    />
                </div>
            </a>

            <a :href="'/user/' + post.user.id">
                <div class="tw-flex tw-flex-col tw-ml-2 tw-pt-2">
                    <span>{{ post.user.name }}</span>
                    <span class="tw-text-xs">{{ post.created_at | ago }}</span>
                </div>
            </a>
        </div>

        <div
            class="tw-bg-white tw-px-4 tw-py-3 md:tw-pl-14 md:tw-pl-4 tw-text-sm tw-h-12 tw-block tw-h-full"
        >
            {{ post.body }}
        </div>

        <post-pigeon-display :post="post"></post-pigeon-display>

        <div
            class="tw-flex tw-flex-row tw-bg-white tw-py-2 tw-pl-4 tw-border-t tw-rounded-b-lg tw-mb-4"
        >
            <a
                :href="`/post/${post.id}`"
                class="tw-text-xs tw-font-bold tw-text-primary-300 hover:tw-bg-primary-100 tw-p-1 tw-rounded"
                :class="'reply-to-' + `${post.id}`"
                :data-reply_to="`${post.id}`"
            >
                <i class="fas fa-comments"></i>
                {{ commentCount }} {{ 'Comment' | pluralize(commentCount) }}
            </a>

            <span>
                <span v-if="!deleting">
                    <a
                        @click="deletePost"
                        class="tw-text-xs tw-font-bold tw-text-primary-300 hover:tw-bg-primary-100 tw-p-1 tw-ml-2 tw-rounded tw-cursor-pointer"
                        :class="'delete-post-' + `${post.id}`"
                        data-post_delete_button
                        dusk="post-delete"
                        v-if="post.authorizations.delete"
                    >
                        <i class="fas fa-trash"></i>
                        {{ $t('social.delete') }}
                    </a>
                </span>
                <span v-else>
                    <a
                        class="tw-text-xs tw-font-bold tw-text-primary-300 hover:tw-bg-primary-100 tw-p-1 tw-ml-2 tw-rounded tw-disabled"
                    >
                        <i class="fas fa-trash"></i>
                        <div class="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </a>
                </span>
            </span>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import PostPigeonDisplay from './PostPigeonDisplay';
import getAvatarUrl from '../../mixins/avatar';

var language = window.location.pathname.substring(1, 3);

export default {
    props: ['post', 'index'],

    data() {
        return {
            deleting: false,
            commentCount: this.post.comments.length,
        };
    },

    computed: {
        user_avatar() {
            return getAvatarUrl(this.post.user);
        },
    },

    components: {
        PostPigeonDisplay,
    },

    filters: {
        ago(date) {
            return moment(date).locale(language).local().fromNow();
        },

        pluralize(word, amount) {
            return amount > 1 || amount == 0 ? `${word}s` : word;
        },
    },

    methods: {
        deletePost() {
            this.deleting = true;

            axios.delete(route('post.destroy', this.post.id)).then(() => {
                this.deleting = false;
                this.$emit('delete', this.index);
                flash(trans('social.post-deleted'), 'success');
            });
        },
    },
};
</script>
