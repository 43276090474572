<template>
    <v-card class="geographics-card">
        <v-card-title class="headline">Geographical Information</v-card-title>

        <v-card-text>
            <v-row>
                <v-col>
                    <div id="userMap" class="map-container"></div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

export default {
    props: {
        users: Array, // Array of users with latitude and longitude information
    },

    mounted() {
        this.initializeMap();
    },

    methods: {
        initializeMap() {
            const map = L.map('userMap');
            const markers = [];

            this.users.forEach((user) => {
                if (user.fancier_lat && user.fancier_long) {
                    const marker = L.marker([user.fancier_lat, user.fancier_long], {
                        icon: L.icon({
                            iconUrl: '/images/vendor/leaflet/dist/marker-icon.png',
                            iconSize: [25, 41],
                            iconAnchor: [12, 41],
                            popupAnchor: [1, -34],
                        })
                    });

                    marker.bindPopup(
                        `<strong>${user.user_name}</strong><br/>Lat: ${user.fancier_lat}, Long: ${user.fancier_long}<br/>Country: ${user.fancier_country || 'Unknown'}`
                    );

                    marker.addTo(map);
                    markers.push([user.fancier_lat, user.fancier_long]);
                }
            });

            // Fit the map to contain all markers
            if (markers.length > 0) {
                map.fitBounds(markers);
            }

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '© OpenStreetMap contributors',
            }).addTo(map);
        }
    },
};
</script>

<style scoped>
#userMap {
    height: 600px;
}
</style>
