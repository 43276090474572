<template>
    <div class="tw-flex tw-justify-center">
        <div class="tw-w-full md:tw-w-3/4 tw-mt-4">
            <post-create @newPost="newPost" :is-member="isMember"></post-create>

            <div v-if="amountMutable > 0">
                <!-- <transition-group name="list"> -->
                <div :key="post.id" v-for="(post, index) in posts">
                    <post :post="post" :index="index" @delete="deletePostFromFeed"></post>
                </div>
                <!-- </transition-group> -->

                <infinite-loading @infinite="infiniteHandler">
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
                </infinite-loading>
            </div>

            <div v-else>
                <div
                    class="tw-text-primary-800 tw-text-sm tw-center tw-bg-white tw-p-3 tw-rounded-lg"
                >
                    <div class="tw-flex tw-flex-row w-full lg:tw-w-1/2 tw-mx-auto">
                        <i class="fas fa-info-circle fa-3x tw-mr-2" style="line-height: 64px"></i>
                        <span
                            class="tw-flex tw-mb-3 tw-mx-auto"
                            v-text="$t('social.up-to-speed')"
                        ></span>
                    </div>
                    <img
                        :src="'/img/svg/undraw_people_search_wctu.svg'"
                        class="tw-mx-auto"
                        style="width: 600px"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Post from './Post';
import PostCreate from './PostCreate';
import InfiniteLoading from 'vue-infinite-loading';

export default {
    props: ['amount', 'isMember'],

    data() {
        return {
            page: 1,
            posts: [],
            amountMutable: this.amount,
        };
    },

    components: {
        Post,
        PostCreate,
        InfiniteLoading,
    },

    methods: {
        newPost(post) {
            if (this.amount === 0) {
                window.location.reload();
            } else {
                this.posts.unshift(post);
            }
        },

        deletePostFromFeed(index) {
            this.posts.splice(index, 1);
        },

        infiniteHandler($state) {
            axios
                .get('/posts', {
                    params: {
                        page: this.page,
                    },
                })
                .then(({ data }) => {
                    if (this.page <= data.last_page) {
                        this.posts = this.posts.concat(data.data);
                        $state.loaded();
                        if (this.posts.length / 20 === 10) {
                            $state.complete();
                        }
                        this.page = this.page + 1;
                    } else {
                        $state.complete();
                    }
                });
        },
    },
};
</script>
