/**
 * Avatar
 */
Vue.component('avatar', require('../components/Avatar/UploadCropper.vue').default);

/**
 * Clock
 */
Vue.component('clock-interface', require('../components/Clock/ClockingInterface.vue').default);
Vue.component('clock-index', require('../components/Clock/ClockIndex.vue').default);
Vue.component('clock-create', require('../components/Clock/ClockCreate.vue').default);
Vue.component('clock-member-list', require('../components/Clock/MemberList.vue').default);

/**
 * Flash
 */
Vue.component('flash', require('../components/Flash/Flash.vue').default);

/**
 * Navigation
 */
Vue.component('dropdown-nav', require('../components/Navigation/DropdownNav.vue').default);
Vue.component('notification', require('../components/Navigation/Notification.vue').default);
Vue.component('dropdown-menu', require('../components/Navigation/DropdownMenu.vue').default);
Vue.component('notification-bell', require('../components/Navigation/NotificationBell.vue').default);
Vue.component('notification-wrapper', require('../components/Navigation/NotificationWrapper.vue').default);

/**
 * User
 */
Vue.component('user-card', require('../components/User/UserCard.vue').default);
Vue.component('user-list', require('../components/User/UserList.vue').default);
Vue.component('edit-button', require('../components/User/EditButton.vue').default);
Vue.component('follow-button', require('../components/User/FollowButton.vue').default);
Vue.component('user-result-list', require('../components/User/ResultList.vue').default);
Vue.component('user-pigeon-list', require('../components/User/PigeonList.vue').default);
Vue.component('follow-count-display', require('../components/User/FollowCountDisplay.vue').default);
Vue.component('infinite-loading-users', require('../components/User/InfiniteLoadingUsers.vue').default);
Vue.component('pigeon-and-result-container', require('../components/User/PigeonAndResultContainer.vue').default);

/**
 * Social
 */
Vue.component('comment', require('../components/Social/Comments.vue').default);
Vue.component('post-index', require('../components/Social/PostIndex.vue').default);
Vue.component('single-post', require('../components/Social/SinglePost.vue').default);

/**
 * Pedigree
 */
Vue.component('button-modal', require('../components/Pedigree/ButtonModal.vue').default);

/**
 * Pigeon
 */
Vue.component('pigeon-list', require('../components/Pigeon/PigeonList.vue').default);
Vue.component('change-owner', require('../components/Pigeon/ChangeOwner.vue').default);
Vue.component('change-owner', require('../components/Pigeon/ChangeOwner.vue').default);
Vue.component('pedigree-details', require('../components/Pigeon/PedigreeDetails.vue').default);
Vue.component('edit-pigeon-button', require('../components/Pigeon/EditPigeonButton.vue').default);
Vue.component('view-pedigree-button', require('../components/Pigeon/ViewPedigreeButton.vue').default);
Vue.component('add-co-breeder-button', require('../components/Pigeon/AddCoBreederButton.vue').default);
Vue.component('pigeon-profile-button', require('../components/Pigeon/PigeonProfileButton.vue').default);
Vue.component('pigeon-list-co-breeders', require('../components/Pigeon/PigeonListCoBreeders.vue').default);
Vue.component('pigeon-exists-in-database', require('../components/Pigeon/PigeonExistsInDatabase.vue').default);
/**
 * Pigeon/Recover
 */
Vue.component('recover-container', require('../components/Pigeon/Recover/RecoverContainer.vue').default);
Vue.component('issued-requests-table', require('../components/Pigeon/Recover/IssuedRequestsTable.vue').default);
Vue.component('received-requests-table', require('../components/Pigeon/Recover/ReceivedRequestsTable.vue').default);
/**
 * Pigeon/Recover/Disputes
 */
Vue.component('dispute-container', require('../components/Pigeon/Recover/Dispute/DisputeContainer.vue').default);
Vue.component('issued-disputes-table', require('../components/Pigeon/Recover/Dispute/IssuedDisputesTable.vue').default);
Vue.component('received-disputes-table', require('../components/Pigeon/Recover/Dispute/ReceivedDisputesTable.vue').default);
/**
 * Pigeon/Result
 */
Vue.component('result-list', require('../components/Pigeon/Result/ResultList.vue').default);
/**
 * Pigeon/Form
 */
Vue.component('pigeon-create-update', require('../components/Pigeon/Form/PigeonCreateUpdate.vue').default);

/**
 * Temporary
 */
Vue.component('beta-message', require('../components/BetaMessage.vue').default);

/**
 * Dashboard
 */
Vue.component('dashboard', require('../components/Dashboard/Dashboard.vue').default);

/**
 * Dashboard
 */
Vue.component('rollbar-test', require('../components/RollbarTest.vue').default);
