<template>
    <a
        v-if="this.user.id != this.currentUser.id"
        class="tw-rounded focus:tw-outline-none tw-cursor-pointer"
        :class="following ? 'tw-bg-red-100' : 'tw-bg-blue-100'"
        v-on:click="toggleFollow()"
    >
        <i
            :class="following ? 'fas fa-comment-slash' : 'far fa-comment'"
            class="tw-self-center tw-text-blue-500 tw-mr-2"
        ></i>
        {{ following ? $t('user-card.unfollow') : $t('user-card.follow') }}
    </a>
</template>
<script>
import { serverBus } from '../../app';

export default {
    props: ['user', 'currentUser', 'isFollowing'],

    data() {
        return {
            following: this.isFollowing,
        };
    },

    methods: {
        toggleFollow() {
            axios.post(route('user.follow.toggle', this.user.id)).then((res) => {
                if (res.data.success == 1) {
                    this.following = true;
                    serverBus.$emit('changeFollowersCount', 'addFollower');
                } else if (res.data.success == 0) {
                    this.following = false;
                    serverBus.$emit('changeFollowersCount', 'removeFollower');
                }
            });
        },
    },
};
</script>
