<template>
    <div class="alert-wrap alert-dismissible relative" v-if="notifications.length > 0">
        <transition-group :name="transition" tag="div">
            <div
                :class="item.typeObject"
                role="alert"
                :key="index + 1"
                v-for="(item, index) in notifications"
                class="tw-relative"
            >
                <span v-html="item.message" class="tw-mr-4"></span>
                <a
                    href="#"
                    class="
                        close
                        tw--mt-2 tw-absolute tw-top-0 tw-right-0 tw-mr-2 tw-text-2xl
                        hover:tw-opacity-75
                    "
                    data-dismiss="alert"
                    aria-label="close"
                    >&times;</a
                >
            </div>
        </transition-group>
    </div>
</template>
<script>
export default {
    props: {
        message: {
            type: String,
            default: '',
        },

        phptype: {
            type: String,
            default: '',
        },

        timeout: {
            type: Number,
            default: 7000,
        },

        transition: {
            type: String,
            default: 'slide-fade',
        },

        types: {
            type: Object,
            default: () => ({
                base: 'alert',
                success: 'alert-success',
                error: 'alert-danger',
                warning: 'alert-warning',
                info: 'alert-info',
            }),
        },

        sound: {
            type: String,
            default: 'no',
        },
    },

    data: () => ({
        notifications: [],
    }),

    /**
     * On creation Flash a message if a message exists otherwise listen for
     * flash event from global event bus
     */
    created() {
        window.events.$on('flash', (message, type) => this.flash(message, type));

        if (this.phptype != '') {
            flash(this.message, this.phptype);
        }
    },

    methods: {
        /**
         * Flash our alert to the screen for the user to see
         * and begin the process to hide it
         *
         * @param message
         * @param type
         */
        flash(message, type) {
            this.notifications.push({
                message: message,
                type: type,
                typeObject: this.classes(this.types, type),
            });

            var typeErrorSound = '';

            if (this.sound == 'yes') {
                switch (type) {
                    case 'error':
                        typeErrorSound = new Audio('/sound/FlashError.mp3');
                        break;
                    case 'success':
                        typeErrorSound = new Audio('/sound/FlashSuccess.mp3');
                        break;
                    case 'info':
                        typeErrorSound = new Audio('/sound/FlashInfo.wav');
                        break;
                    case 'warning':
                        typeErrorSound = new Audio('/sound/FlashWarning.wav');
                        break;
                }
                typeErrorSound.play();
            }

            setTimeout(this.hide, this.timeout);
        },

        /**
         * Sets and returns the values needed
         *
         * @param type
         */
        classes(propObject, type) {
            let classes = {};
            if (propObject.hasOwnProperty('base')) {
                classes[propObject.base] = true;
            }
            if (propObject.hasOwnProperty(type)) {
                classes[propObject[type]] = true;
            }
            return classes;
        },

        /**
         * Hide Our Alert
         *
         * @param item
         */
        hide(item = this.notifications[0]) {
            let key = this.notifications.indexOf(item);
            this.notifications.splice(key, 1);
        },
    },
};
</script>

<style scoped>
.alert-wrap {
    position: fixed !important;
    z-index: 9999;
    right: 25px;
    bottom: 55px;
    font-size: 14.4px;
}

.alert {
    border: 0;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border-radius: 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.alert-success {
    color: #545669;
    border-left: 5px solid #42b983;
    background-color: #f8fafc;
}

.alert-success::before {
    content: '\f00c';
    background-color: #42b983;
    font-family: 'Font Awesome 5 Free' !important;
}

.alert-info {
    color: #545669;
    border-left: 5px solid blue;
    background-color: #f8fafc;
}

.alert-warning {
    color: #545669;
    border-left: 5px solid yellow;
    background-color: #f8fafc;
}

.alert-danger {
    color: #545669;
    border-left: 5px solid #f66;
    background-color: #f8fafc;
}

.alert-danger::before {
    content: '!';
    background-color: #f66;
}

.alert::before {
    position: absolute;
    left: -13px;
    width: 20px;
    height: 20px;
    text-align: center;
    color: #fff;
    border-radius: 100%;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
}

/**
     * Fade transition styles
     */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
}

/**
     * Bounce transition styles
     */
.bounce-enter-active {
    animation: bounce-in 0.5s;
}

.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

/**
     * Slide transition styles
     */
.slide-fade-enter-active {
    transition: all 0.3s ease;
}

.slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}
</style>
