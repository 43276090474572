<template>
    <div class="tw-flex tw-flex-col lg:tw-flex-row tw-mb-2" data-pigeon-added-to-post-before-save>
        <span class="tw-w-full lg:tw-w-1/3">
            <span
                v-if="pigeon.pigeon_name"
                class="tw-text-base tw-font-bold tw-bg-indigo-100 tw-block tw-font-bold tw-pl-2 tw-rounded-t-lg"
                >{{ pigeon.pigeon_name }}</span
            >
            <img
                class="tw-w-1/2 tw-mx-auto lg:tw-w-full lg:tw-mx-0 tw-border tw-border-1 tw-border-indigo-100 tw-h-auto tw-rounded-b-lg"
                :src="pigeon_avatar(pigeon)"
                :alt="pigeon.ringnumber + ' avatar'"
            />
        </span>

        <div class="tw-flex tw-flex-col tw-w-full tw-mt-2 lg:tw-mt-0 lg:tw-ml-4 tw-overflow-auto">
            <div
                class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-bg-indigo-100 tw-px-3 tw-py-1 tw-rounded"
            >
                <span class="tw-flex tw-flex-col">
                    <span class="tw-text-xs" v-text="$t('social.ringno')"></span>
                    <span class="tw-text-xl tw-font-bold tw-mt-1 tw-leading-none">{{
                        pigeon.ringnumber | formatRingnumber(pigeon.landcode)
                    }}</span>
                </span>

                <span
                    class="tw-flex tw-flex-col tw-mt-2 lg:tw-mt-0 lg:tw-ml-4"
                    v-if="pigeon.ringnumber_father"
                >
                    <span class="tw-text-xs" v-text="$t('social.father')"></span>
                    <span class="tw-font-bold" v-if="pigeon.father.public == 1">{{
                        pigeon.ringnumber_father | formatRingnumber(pigeon.father.landcode)
                    }}</span>
                    <span v-else v-text="$t('pigeon.not-available')" dusk="father_invisible"></span>
                </span>

                <span
                    class="tw-flex tw-flex-col tw-mt-2 lg:tw-mt-0 lg:tw-ml-4"
                    v-if="pigeon.ringnumber_mother"
                >
                    <span class="tw-text-xs tw-p-1" v-text="$t('social.mother')"></span>
                    <span class="tw-font-bold" v-if="pigeon.mother.public == 1">{{
                        pigeon.ringnumber_mother | formatRingnumber(pigeon.mother.landcode)
                    }}</span>
                    <span v-else v-text="$t('pigeon.not-available')" dusk="mother_invisible"></span>
                </span>
            </div>

            <div class="tw-w-full tw-overflow-auto">
                <table
                    class="tw-text-left tw-w-full tw-border-collapse tw-mt-2"
                    v-if="pigeon.chosen_results.length > 0"
                >
                    <thead class="tw-rounded-t-lg">
                        <tr class="tw-bg-indigo-100">
                            <th
                                class="tw-border-b tw-border-gray-light tw-text-xs tw-pl-3 tw-pr-1 tw-whitespace-no-wrap"
                                v-text="$t('social.date')"
                            ></th>
                            <th
                                class="tw-border-b tw-border-gray-light tw-text-xs tw-p-1 tw-whitespace-no-wrap"
                                v-text="$t('social.place')"
                            ></th>
                            <th
                                class="tw-border-b tw-border-gray-light tw-text-xs tw-p-1 tw-whitespace-no-wrap"
                                v-text="$t('social.ad')"
                            ></th>
                            <th
                                class="tw-border-b tw-border-gray-light tw-text-xs tw-p-1 tw-whitespace-no-wrap"
                                v-text="$t('social.gt')"
                            ></th>
                            <th
                                class="tw-border-b tw-border-gray-light tw-text-xs tw-p-1 tw-whitespace-no-wrap"
                                v-text="$t('social.ul')"
                            ></th>
                            <th
                                class="tw-border-b tw-border-gray-light tw-text-xs tw-p-1 tw-whitespace-no-wrap"
                                v-text="$t('social.distance')"
                            ></th>
                            <th
                                class="tw-border-b tw-border-gray-light tw-text-xs tw-p-1 tw-whitespace-no-wrap"
                                v-text="$t('social.velocity')"
                            ></th>
                        </tr>
                    </thead>
                    <tbody class="tw-rounded-b">
                        <tr
                            :key="index"
                            v-for="(result, index) in pigeon.chosen_results"
                            class="odd:tw-bg-white even:tw-bg-gray-200 tw-p-1"
                            data-result-added-to-post-before-save
                        >
                            <td class="tw-py-1 tw-text-xs tw-pl-3 tw-pr-1 tw-whitespace-no-wrap">
                                {{ result.date_start_time }}
                            </td>
                            <td class="tw-py-1 tw-text-xs tw-p-1 tw-whitespace-no-wrap">
                                {{ result.place }}
                            </td>
                            <td class="tw-py-1 tw-text-xs tw-p-1 tw-whitespace-no-wrap">
                                {{ result.ad }}
                            </td>
                            <td class="tw-py-1 tw-text-xs tw-p-1 tw-whitespace-no-wrap">
                                {{ result.gt }}
                            </td>
                            <td class="tw-py-1 tw-text-xs tw-p-1 tw-whitespace-no-wrap">
                                {{ result.unloading_location }}
                            </td>
                            <td class="tw-py-1 tw-text-xs tw-p-1 tw-whitespace-no-wrap">
                                {{ result.distance }}
                            </td>
                            <td class="tw-py-1 tw-text-xs tw-p-1 tw-whitespace-no-wrap">
                                {{ result.velocity }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import getAvatarUrl from '../../mixins/avatar';

export default {
    props: ['pigeon'],

    methods: {
        pigeon_avatar(pigeon) {
            return getAvatarUrl(pigeon, 'pigeon_avatar');
        },
    },
};
</script>
