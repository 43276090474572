<template v-cloak>
    <v-card>
        <v-card-title>
            <span v-text="$t('recover.received-requests')"></span>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('helpers.search')"
            ></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page="10"
            :footer-props="footerProps"
            :loading="loadingState"
            :multi-sort="true"
            item-key="id"
            class="elevation-1"
        >
            <template v-slot:item.landcode="{ item }">
                <span>{{ item.landcode }}</span>
            </template>

            <template v-slot:item.ringnumber="{ item }">
                <a :href="`/pigeon/${item.id}`" class="tw-cursor-pointer hover:tw-text-blue-400">
                    <span>{{ item.ringnumber }}</span>
                </a>
            </template>
            <template v-slot:item.info="{ item }">
                <span
                    class="tw-cursor-pointer hover:tw-text-primary-400"
                    @click="openMoreInfoModal(item)"
                    v-html="parseLongInfoText(item.info)"
                ></span>
            </template>

            <template v-slot:item.status="{ item }">
                <span class="tw-flex tw-justify-between">
                    {{ item.status }}
                    <a
                        v-if="item.status == 'rejected'"
                        class="btn-primary inline-block tw-rounded-lg tw-py-1 tw-px-2 tw-cursor-pointer tw-outline-none"
                        :href="`/pigeon/${item.pigeon_id}/dispute`"
                        v-text="$t('dispute.start-dispute')"
                    ></a>
                </span>
            </template>

            <template v-slot:item.action="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            color="green lighten-2"
                            @click.native="handleRequest('accepted', item.id)"
                        >
                            <v-icon>mdi-check</v-icon>
                        </v-btn>
                    </template>
                    <span v-text="$t('recover.accept-request-tooltip')"></span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            color="red lighten-2"
                            @click.native="handleRequest('rejected', item.id)"
                        >
                            <v-icon>mdi-window-close</v-icon>
                        </v-btn>
                    </template>
                    <span v-text="$t('recover.reject-request-tooltip')"></span>
                </v-tooltip>
            </template>
        </v-data-table>

        <v-dialog v-model="dialog" max-width="600" @keydown.esc="dialog = false">
            <v-card>
                <v-card-title class="headline" v-text="$t('recover.info')"></v-card-title>
                <v-card-text>{{ infoToShow }}</v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
export default {
    props: ['receivedRecoverRequests'],

    data() {
        return {
            headers: [
                { text: trans('recover.landcode'), value: 'landcode' },
                { text: trans('recover.ringnumber'), value: 'ringnumber' },
                { text: trans('recover.extra-info'), value: 'info' },
                {
                    text: trans('recover.action'),
                    value: 'action',
                    sortable: false,
                    filterable: false,
                },
            ],
            items: this.receivedRecoverRequests,
            search: '',
            footerProps: { 'items-per-page-options': [5, 10, 15, 30, 50, 100] },

            dialog: false,
            infoToShow: null,
            loadingState: false,
        };
    },

    methods: {
        openMoreInfoModal(itemId) {
            // Very weird way to get the index of the clicked row
            // We pass that index to the items array to get the current info to display in the modal
            this.infoToShow = this.items[this.items.indexOf(itemId)].info;

            // Show the modal
            this.dialog = true;
        },

        parseLongInfoText(info) {
            if (info != null && info != undefined) {
                return info.length > 45 ? info.substring(0, 45) + '...' : info;
            }
        },

        handleRequest(status, id) {
            this.loadingState = true;

            axios
                .patch(route('pigeon.recover.update', id), {
                    status: status,
                })
                .then((res) => {
                    flash(res.data[1], 'success');

                    this.items = this.items.filter((dataToBeFiltered) => {
                        return dataToBeFiltered.id != id;
                    });

                    this.loadingState = false;
                });
        },
    },
};
</script>
