<template>
    <div class="tw-flex tw-flex-col tw-w-full">
        <div class="tw-flex tw-flex-row lg:tw-mb-4" v-if="isCoBreederDetailScreen">
            <div class="tw-w-1/2 lg:tw-mr-4">
                <label class="label-tw" for="color">{{ $t('create.breeder') }}</label>
                <input
                    type="text"
                    v-model="breeder"
                    class="input-field-tw-ext"
                    dusk="breeder_ped_details"
                    max="50"
                />
            </div>

            <div class="tw-w-1/2">
                <label class="label-tw" for="color">{{ $t('create.pigeon_name') }}</label>
                <input
                    type="text"
                    v-model="pigeon_name"
                    class="input-field-tw-ext"
                    dusk="pigeon_name_ped_details"
                    max="50"
                />
            </div>
        </div>

        <div class="tw-flex tw-flex-row lg:tw-mb-4">
            <div class="tw-w-1/2">
                <v-switch
                    v-model="show_breeder"
                    :label="$t('pedigree.show-breeder')"
                    @change="updateShowBreeder"
                    dusk="showBreeder"
                ></v-switch>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['endpoint_show_breeder'],

    data() {
        return {
            isCoBreederDetailScreen: location.href.includes('pedigree/detail'),
        };
    },

    mounted() {
        this.$nextTick(() => {
            this.$store.dispatch(
                'changeShowBreederAction',
                this.pigeon.info ? this.pigeon.info.show_breeder : false
            );
        });
    },

    computed: {
        pigeon() {
            return this.$store.state.pigeon.pedigreeDetailsPigeonObject;
        },

        show_breeder: {
            get() {
                return this.$store.state.breeder.show_breeder;
            },
            set(value) {
                return this.$store.dispatch('changeShowBreederAction', value);
            },
        },

        pigeon_name: {
            get() {
                return this.$store.state.pigeon_name.name;
            },
            set(value) {
                return this.$store.dispatch('changePigeonNameAction', value);
            },
        },

        breeder: {
            get() {
                return this.$store.state.breeder.breeder;
            },
            set(value) {
                // If there is no value, enable the breeder field up top
                this.$store.dispatch(
                    'changeIsBreederSetViaPedigreeDetailsAction',
                    value == '' || value == undefined ? false : true
                );

                return this.$store.dispatch('changeBreederAction', value);
            },
        },
    },

    methods: {
        updateShowBreeder(show_breeder) {
            axios
                .post(route(this.endpoint_show_breeder, this.pigeon.id), {
                    show_breeder: show_breeder,
                })
                .then((res) => {
                    this.$emit('showBreederUpdated', show_breeder);
                    flash(res.data.message, 'success');
                })
                .catch((err) => {
                    flash(err, 'success');
                });
        },

        ifBreeder() {
            return this.breeder != undefined
                ? this.breeder.length > 0
                : this.pigeon.breeder.length > 0;
        },
    },
};
</script>