<template>
    <v-tabs v-model="tab" show-arrows class="elevation-1">
        <v-tab>{{ $t('user.favorite-results') }}</v-tab>
        <v-tab>{{ $t('user.public-pigeons') }}</v-tab>

        <v-tabs-items v-model="tab">
            <v-tab-item>
                <user-result-list :results="results"></user-result-list>
            </v-tab-item>
            <v-tab-item>
                <user-pigeon-list :public-pigeons="publicPigeons"></user-pigeon-list>
            </v-tab-item>
        </v-tabs-items>
    </v-tabs>
</template>

<script>
export default {
    props: ['results', 'publicPigeons'],

    data() {
        return {
            tab: null,
        };
    },
};
</script>
