<template>
    <div class="tw-flex tw-flex-col tw-mb-4">
        <div class="tw-shadow-md">
            <div class="tw-bg-white tw-py-1 tw-pl-2 tw-font-bold">
                <span v-text="$t('social.create-post')"></span>
            </div>

            <textarea
                :rows="rows"
                class="tw-bg-primary-200 tw-border tw-border-1 tw-outline-none tw-w-full tw-h-full tw-py-1 tw-pl-2 tw-block tw-z-0"
                id="create-post-field"
                min="2"
                max="200"
                required
                dusk="create_post_field"
                data-create_post_field
                :placeholder="$t('social.whats-up')"
                v-model="body"
                @focus="typing"
                @blur="noLongerTyping"
                @input="clearErrors"
            ></textarea>

            <div
                class="tw-flex tw-flex-col tw-bg-white tw-p-2 tw-overflow-y-auto"
                v-if="selectedPigeons.length > 0"
            >
                <div class="scroll-height">
                    <div :key="index" v-for="(pigeon, index) in selectedPigeons" class>
                        <post-create-pigeon-and-results-display
                            :pigeon="pigeon"
                        ></post-create-pigeon-and-results-display>
                    </div>
                </div>
            </div>
        </div>
        <span v-if="errors.body" class="tw-text-sm tw-text-red-700 tw-mt-1">{{ errors.body }}</span>

        <div class="tw-flex tw-justify-between tw-mt-2">
            <post-create-add-pigeon
                :is-member="isMember"
                :selectedPigeonsInStoreInComponent="selectedPigeonsInStoreInParentComponent"
            ></post-create-add-pigeon>

            <v-btn color="green" dusk="share" @click="submitPost" onclick="this.blur();">
                <span class="tw-text-white tw-capitalize" v-text="$t('social.share')"></span>
            </v-btn>
        </div>

        <v-dialog v-model="submitting" persistent width="300">
            <v-card color="green darken-2" dark>
                <v-card-text>
                    {{ $t('messages.posting') }}
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import PostCreateAddPigeon from './PostCreateAddPigeon';
import PostCreatePigeonAndResultsDisplay from './PostCreatePigeonAndResultsDisplay';

export default {
    props: ['isMember'],

    data() {
        return {
            rows: 1,
            body: this.body,
            submitting: false,
            charactersPresent: false,
            selectedPigeonsInStoreInParentComponent: [],
            errors: {
                body: '',
            },
        };
    },

    computed: {
        selectedPigeons: {
            get: function () {
                return this.$store.getters.getPostCreateModalSelectedPigeons;
            },
            set: function () {
                this.$store.commit('UPDATE_SELECTED_PIGEONS');
            },
        },
    },

    components: {
        PostCreateAddPigeon,
        PostCreatePigeonAndResultsDisplay,
    },

    methods: {
        typing() {
            if (this.body == undefined || this.charactersPresent) {
                this.charactersPresent = true;
                this.rows = 3;
            }
        },

        noLongerTyping() {
            if (this.body == undefined || this.body.length == 0) {
                this.charactersPresent = false;
                this.rows = 1;
            }
        },

        clearErrors() {
            this.errors.body = '';
        },

        perPigeonChosenResults(index) {
            if (this.selectedPigeons[index].hasOwnProperty('chosenResults')) {
                return this.selectedPigeons[index].chosenResults[0];
            } else {
                return [];
            }
        },

        submitPost() {
            this.submitting = true;
            axios
                .post('/post', {
                    body: this.body,
                    pigeon: this.selectedPigeons,
                })
                .then((res) => {
                    this.$emit('newPost', res.data);
                    flash(res.data.message, 'success');

                    this.body = '';
                    this.selectedPigeons = [];
                    this.rows = 1;
                    this.errors.body = '';
                    this.$store.commit('UPDATE_SELECTED_PIGEONS', undefined);
                    this.selectedPigeonsInStoreInParentComponent = [];
                    this.submitting = false;
                })
                .catch((err) => {
                    this.errors.body = err.response.data.errors.body[0];
                    this.submitting = false;
                });
        },
    },

    mounted() {
        $('.data-post_add_pigeon_modal_button-v-u-e').tooltip({ trigger: 'hover' });
    },
};
</script>
