<template
    class="v-cloak"
    >
    <div>
        <div class="notification-counter-wrapper" @click="toggle()" ref="wrapper">
            <span
                class="notification-counter tw-cursor-pointer"
                v-if="showCount == true"
                v-text="countAmount"
            ></span>
            <button key="on" class="tw-flex tw-items-center tw-text-2xl focus:tw-outline-none">
                <i :class="show ? 'fas' : 'far'" class="fa-bell fa-fw"></i>
            </button>
        </div>
        <notification-wrapper
            :notifications="this.notifications"
            :class="show ? 'tw-block' : 'tw-hidden'"
            v-closable="{
                exclude: ['wrapper'],
                handler: 'close',
            }"
        ></notification-wrapper>
    </div>
</template>

<script>
export default {
    props: ['userId'],

    data() {
        return {
            count: 0,
            show: false,
            showCount: false,
            notifications: [],
        };
    },

    mounted() {
        this.checkForNotifications();
        this.listenForNotification();
    },

    computed: {
        countAmount: function () {
            return this.notifications.length;
        },
    },

    methods: {
        /**
         * Toggle notifications dropdown visibility
         */
        toggle: function () {
            this.show = !this.show;
        },

        /**
         * Close notifications dropdown visibility
         */
        close: function () {
            this.show = false;
        },

        /**
         * Play a little notification sound
         */
        playSound() {
            var audio = new Audio('/sound/Bell.mp3');
            if (audio) {
                audio.loop = false;
                audio.play();
            }
        },

        /**
         * Check if the user is logged in, if yes get all of his notifications
         */
        checkForNotifications() {
            if (this.userId) {
                axios
                    .get(route('user.notifications.index'))
                    .then((response) => {
                        response.data.forEach((item) => {
                            this.notifications.push(item);
                        });
                        if (response.data.length > 0) {
                            this.showCount = true;
                        }
                    })
                    .catch(function (error) {});
            }
        },

        /**
         * Listen for new notifications
         */
        listenForNotification() {
            if (this.userId) {
                window.Echo.private('App.User.' + this.userId).notification((notification) => {
                    if (notification.type != 'App\\Notifications\\PigeonUnclocked') {
                        this.notifications.unshift(notification);
                        this.playSound();
                        this.showCount = true;
                    }
                });
            }
        },
    },
};
</script>
