<template>
    <div class="
            tw-bg-white tw-w-full tw-mx-auto tw-px-2 tw-py-2
            lg:tw-px-3 lg:tw-py-3
            tw-rounded tw-shadow tw-mb-4
        " style="max-width: 800px">
        <div class="tw-flex tw-flex-col lg:tw-flex-row">
            <div class="tw-w-full lg:tw-w-1/3 tw-block tw-mb-3 lg:tw-mb-0">
                <span class="tw-text-xl tw-relative">
                    {{ $t('create.ringnumber-label') }}
                    <div class="help-tip" data-ringnumbers_tooltip data-toggle="tooltip" data-placement="bottom"
                        :title="$t('create.ringnumber-extra-info')"></div>
                    <a :href="userSettings" class="
                            tw-inline-block tw-text-orange-600
                            hover:tw-text-gray-800
                            tw-underline tw-text-sm
                        " v-text="$t('create.configure-standard-landcode')">
                    </a>
                </span>
            </div>
            <div class="tw-w-full lg:tw-w-2/3">
                <div class="tw-flex tw-flex-row">
                    <div class="tw-w-3/12 lg:tw-w-2/12">
                        <label class="label-tw tw-mb-1 tw-text-sm" for="landcode" id="landcode"
                            v-text="$t('create.landcode')"></label>
                        <select id="landcode_node" class="input-field-tw-ext tw-rounded-r-none" v-model="form.landcode"
                            dusk="landcode" @change="changeMask($event.target.value)">
                            <option v-for="(country, i) in countries" :value="country" :key="i" v-text="country"></option>
                        </select>
                    </div>
                    <div class="tw-w-9/12 lg:tw-w-10/12">
                        <label class="label-tw tw-mb-1 tw-text-sm" for="ringnumber" id="ringnumber"
                            v-text="$t('create.ringnumber')"></label>
                        <input type="text" id="ringnumber_node" class="input-field-tw-ext tw-rounded-l-none"
                            dusk="ringnumber" autocomplete="off" v-model="form.ringnumber" />
                    </div>
                </div>
                <small class="tw-text-red-500 tw-mt-1 tw-block" v-if="form.errors.has('landcode')"
                    v-text="form.errors.get('landcode')"></small>
                <small class="tw-text-red-500 tw-block" :class="{ 'tw-mt-1': !form.errors.has('landcode') }"
                    v-if="form.errors.has('ringnumber')" v-text="form.errors.get('ringnumber')"></small>

                <div class="tw-flex tw-flex-row">
                    <div class="tw-w-full tw-pr-1 tw-mt-4">
                        <label class="label-tw tw-relative" for="ringnumber_father" v-text="$t('create.father')"></label>
                        <div class="tw-flex tw-flex-row">
                            <div class="tw-w-3/12 lg:tw-w-2/12">
                                <select class="input-field-tw-ext tw-rounded-r-none" v-model="form.landcode_father"
                                    dusk="landcode_father">
                                    <option :value="country" v-for="(country, i) in countries" :key="i" v-text="country">
                                    </option>
                                </select>
                            </div>
                            <div class="tw-w-9/12 lg:tw-w-10/12">
                                <autocomplete :suggestions="ringnumbers" :selection.sync="form.ringnumber_father"
                                    id="ringnumber_father">
                                </autocomplete>
                            </div>
                        </div>
                        <small class="tw-text-red-500 tw-mt-1 tw-block" v-if="form.errors.has('landcode_father')"
                            v-text="form.errors.get('landcode_father')"></small>
                        <small class="tw-text-red-500 tw-block" :class="{ 'tw-mt-1': !form.errors.has('landcode_father') }"
                            v-if="form.errors.has('ringnumber_father')"
                            v-text="form.errors.get('ringnumber_father')"></small>
                    </div>

                    <div class="tw-w-full tw-pl-1 tw-mt-4">
                        <label class="label-tw tw-relative" for="ringnumber_mother" v-text="$t('create.mother')"></label>
                        <div class="tw-flex tw-flex-row">
                            <div class="tw-w-3/12 lg:tw-w-2/12">
                                <select class="input-field-tw-ext tw-rounded-r-none" v-model="form.landcode_mother"
                                    dusk="landcode_mother">
                                    <option :value="country" v-for="(country, i) in countries" :key="i" v-text="country">
                                    </option>
                                </select>
                            </div>
                            <div class="tw-w-9/12 lg:tw-w-10/12">
                                <autocomplete :suggestions="ringnumbers" :selection.sync="form.ringnumber_mother"
                                    id="ringnumber_mother">
                                </autocomplete>
                            </div>
                        </div>
                        <small class="tw-text-red-500 tw-mt-1 tw-block" v-if="form.errors.has('landcode_mother')"
                            v-text="form.errors.get('landcode_mother')"></small>
                        <small class="tw-text-red-500 tw-block" :class="{ 'tw-mt-1': !form.errors.has('landcode_mother') }"
                            v-if="form.errors.has('ringnumber_mother')"
                            v-text="form.errors.get('ringnumber_mother')"></small>
                    </div>
                </div>
            </div>
        </div>

        <div class="tw-flex tw-flex-col lg:tw-flex-row tw-mt-8">
            <div class="tw-w-full lg:tw-w-1/3 tw-block tw-mb-3 lg:tw-mb-0">
                <span class="tw-text-xl tw-relative">
                    {{ $t('create.details-label') }}
                    <div class="help-tip" data-details_tooltip data-toggle="tooltip" data-placement="bottom"
                        :title="$t('create.details-extra-info')"></div>
                </span>
            </div>
            <div class="tw-w-full lg:tw-w-2/3">
                <div class="tw-flex tw-flex-row">
                    <div class="tw-flex tw-flex-col tw-w-full tw-pr-1">
                        <label class="label-tw tw-mb-1 tw-text-sm" for="pigeon_name" v-text="$t('create.name')"></label>
                        <input type="text" v-model="form.pigeon_name" class="input-field-tw-ext" dusk="pigeon_name"
                            max="25" />
                        <small class="tw-text-red-500 tw-mt-1" v-if="form.errors.has('pigeon_name')"
                            v-text="form.errors.get('pigeon_name')"></small>
                    </div>

                    <div class="tw-flex tw-flex-col tw-w-full tw-pl-1">
                        <label class="label-tw mb-1 tw-text-sm" for="gender" v-text="$t('create.gender')"></label>
                        <select id="genderselect" v-model="form.gender" class="input-field-tw-ext" dusk="gender">
                            <option :value="null" v-text="$t('create.choose-a-gender')" disabled></option>
                            <option value="male" v-text="$t('create.male')"></option>
                            <option value="female" v-text="$t('create.female')"></option>
                        </select>
                        <small class="tw-text-red-500 tw-mt-1" v-if="form.errors.has('gender')"
                            v-text="form.errors.get('gender')"></small>
                    </div>
                </div>

                <div class="tw-flex tw-flex-row tw-mt-4">
                    <div class="tw-w-1/2 tw-pr-1">
                        <label class="label-tw" for="color" v-text="$t('create.color')"></label>
                        <select id="colorselect" class="input-field-tw-ext" v-model="form.color" dusk="color">
                            <option :value="null" v-text="$t('create.pick-a-color')"></option>
                            <optgroup :label="$t('create.common')">
                                <option v-for="(color, i) in colors[0]" v-text="color" :value="i" :key="i"></option>
                            </optgroup>
                            <optgroup :label="$t('create.special')">
                                <option v-for="(color, i) in colors[1]" v-text="color" :value="i" :key="i"></option>
                            </optgroup>
                        </select>
                    </div>
                    <small class="tw-text-red-500 tw-mt-4" v-if="form.errors.has('color')"
                        v-text="form.errors.get('color')"></small>

                    <div class="tw-w-1/2 tw-pl-1">
                        <span class="tw-relative">
                            <label class="label-tw" for="color">{{ $t('create.breeder') }}</label>
                            <div class="exclamation-mark" data-details_tooltip data-toggle="tooltip" data-placement="bottom"
                                :title="$t('create.if-breeder-empty')"></div>
                        </span>
                        <input type="text" v-model="form.breeder" class="input-field-tw-ext" dusk="breeder" max="50" />
                        <small class="tw-text-red-500 tw-mt-4" v-if="form.errors.has('breeder')"
                            v-text="form.errors.get('breeder')"></small>
                        <!-- <small
                            class="tw-text-orange-500 tw-mt-4"
                            v-if="isBreederSetViaPedigreeDetails"
                            v-text="'Breeder is now controlled via the field below'"
                        ></small> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="tw-flex tw-flex-col lg:tw-flex-row tw-mt-8">
            <div class="tw-w-full lg:tw-w-1/3 tw-block tw-mb-3 lg:tw-mb-0">
                <span class="tw-text-xl tw-relative">
                    {{ $t('create.flight-distance') }}
                    <div class="help-tip" data-flight_distance_tooltip data-toggle="tooltip" data-placement="bottom"
                        :title="$t('create.flight-distance-help')"></div>
                </span>
            </div>

            <div class="tw-w-full lg:tw-w-2/3">
                <div class="tw-flex tw-flex-row">
                    <div class="tw-flex tw-flex-col tw-w-full lg:tw-w-1/2">
                        <div class="tw-flex tw-flex-col lg:tw-flex-row tw-mb-3 lg:tw-mb-6">
                            <input class="apple-switch" type="checkbox" v-model="form.flight_distance" value="speed"
                                id="speed" dusk="speed" />
                            <label class="label-tw lg:tw-ml-2" for="speed" v-text="$t('clock.distance-speed')"></label>
                        </div>

                        <div class="tw-flex tw-flex-col lg:tw-flex-row tw-mb-3 lg:tw-mb-6">
                            <input class="apple-switch" type="checkbox" v-model="form.flight_distance" value="great"
                                id="great" dusk="great" />
                            <label class="label-tw lg:tw-ml-2" for="great"
                                v-text="$t('clock.distance-great-middle')"></label>
                        </div>
                    </div>
                    <div class="tw-flex tw-flex-col tw-w-full lg:tw-w-1/2">
                        <div class="tw-flex tw-flex-col lg:tw-flex-row tw-mb-3 lg:tw-mb-6">
                            <input class="apple-switch" type="checkbox" v-model="form.flight_distance" value="middle"
                                id="middle" dusk="middle" />
                            <label class="label-tw lg:tw-ml-2" for="middle" v-text="$t('clock.distance-middle')"></label>
                        </div>

                        <div class="tw-flex tw-flex-col lg:tw-flex-row tw-mb-3 lg:tw-mb-6">
                            <input class="apple-switch" type="checkbox" v-model="form.flight_distance" value="long"
                                id="long" dusk="long" />
                            <label class="label-tw lg:tw-ml-2" for="long" v-text="$t('clock.distance-long')"></label>
                        </div>
                    </div>
                </div>
                <div class="tw-w-full">
                    <div class="tw-flex tw-flex-col lg:tw-flex-row">
                        <div class="tw-flex tw-flex-col lg:tw-flex-row tw-w-full lg:tw-w-1/2">
                            <input class="apple-switch" type="checkbox" v-model="form.flight_distance" value="extreme"
                                id="extreme" dusk="extreme" />
                            <label class="label-tw lg:tw-ml-2" for="extreme" v-text="$t('clock.distance-extreme')"></label>
                        </div>
                    </div>
                </div>
                <small class="tw-block tw-text-red-500 tw-mt-4" v-if="form.errors.has('flight_distance')"
                    v-text="form.errors.get('flight_distance')"></small>
            </div>
        </div>

        <div class="tw-flex tw-flex-col lg:tw-flex-row tw-mt-8">
            <div class="tw-w-full lg:tw-w-1/3 tw-block tw-mb-3 lg:tw-mb-0">
                <span class="tw-text-xl tw-relative">{{ $t('create.public') }}
                    <div class="help-tip" data-flight_distance_tooltip data-toggle="tooltip" data-placement="bottom"
                        :title="$t('create.public-help')"></div>
                </span>
            </div>

            <div class="tw-w-full lg:tw-w-2/3">
                <div class="tw-w-full">
                    <div class="tw-flex tw-flex-col lg:tw-flex-row">
                        <div class="tw-flex tw-flex-col lg:tw-flex-row tw-w-full lg:tw-w-1/2">
                            <input class="apple-switch" type="checkbox" v-model="form.public" value="1" id="public"
                                dusk="set-public" />
                            <label class="label-tw lg:tw-ml-2" for="public" v-text="$t('create.public-visible')"></label>
                        </div>
                    </div>
                </div>
                <small class="tw-block tw-text-red-500 tw-mt-4" v-if="form.errors.has('public')"
                    v-text="form.errors.get('public')"></small>
            </div>
        </div>

        <div class="tw-flex tw-justify-end tw-mt-3">
            <span>
                <button v-if="!submitting" :disabled="submitting" @click="submitForm"
                    class="btn-primary tw-rounded tw-cursor-pointer" dusk="submit-pigeon" v-text="buttonText" />
                <span v-else class="
                        tw-flex tw-items-center tw-justify-center
                        btn-primary
                        tw-rounded tw-cursor-pointer
                    " style="width: 119.77px; height: 40px">
                    <v-progress-circular slot="progress" color="white" indeterminate />
                </span>
            </span>
        </div>

        <pigeon-exists-in-database v-if="existsInDatabase" :url="existInDbUrl">
        </pigeon-exists-in-database>
    </div>
</template>

<script>
import Inputmask from 'inputmask';
import Form from './../../helpers/Form';
import { figureOutMask } from './helpers/masks';
import Autocomplete from '../../Standalone/Autocomplete.vue';

export default {
    props: [
        'pigeon',
        'endpoint',
        'ringnumbers',
        'user',
        'countries',
        'colors',
        'flightDistance',
        'type',
        'existInDbUrl',
    ],

    components: {
        Autocomplete,
    },

    data() {
        return {
            submitting: false,
            form: new Form({
                landcode: null,
                ringnumber: this.pigeon ? this.pigeon.ringnumber : null,
                ringnumber_father: this.pigeon ? this.pigeon.ringnumber_father : null,
                landcode_father: this.landcodeFather,
                ringnumber_mother: this.pigeon ? this.pigeon.ringnumber_mother : null,
                landcode_mother: this.landcodeMother,
                pigeon_name: this.pigeon?.info ? this.pigeon.info.pigeon_name : null,
                gender: this.pigeon ? this.pigeon.gender : null,
                color: this.pigeon ? this.pigeon.color : null,
                breeder: this.pigeon?.info ? this.pigeon.info.breeder : null,
                flight_distance: this.flightDistance || [],
                public: this.pigeon ? this.pigeon.public : null,
            }),
            existsInDatabase: false,
        };
    },

    mounted() {
        this.form.landcode = this.landcode();
        this.form.landcode_father = this.landcodeFather();
        this.form.landcode_mother = this.landcodeMother();

        this.changeMask(this.form.landcode);

        // if (this.pigeon) {
        //     this.$store.dispatch(
        //         'changeIsBreederSetViaPedigreeDetailsAction',
        //         this.pigeon.info.breeder != '' ? true : false
        //     );
        // }
    },

    computed: {
        // isBreederSetViaPedigreeDetails() {
        //     return this.$store.state.breeder.isBreederSetViaPedigreeDetails;
        // },

        breeder: {
            get() {
                return this.$store.state.breeder.breeder;
            },
            set(value) {
                return this.$store.dispatch('changeBreederAction', value);
            },
        },

        userSettings() {
            return route('user.system-settings', this.user.id);
        },

        pigeonCreate() {
            return route('pigeon.create');
        },

        ringnumberMask() {
            return figureOutMask(this.form.landcode);
        },

        buttonText() {
            if (this.type == 'create') {
                return this.$t('create.submit-pigeon');
            } else {
                return this.$t('update.edit-pigeon');
            }
        },
    },

    methods: {
        submitForm() {
            this.submitting = true;
            if (this.pigeon) {
                this.form
                    .patch(route('pigeon.update', this.pigeon.id))
                    .then((response) => {
                        flash(response.message, response.type);
                        this.form.errors.clear();
                        this.submitting = false;
                    })
                    .catch((e) => {
                        this.form.errors.record(e.errors);
                        if (this.form.errors.has('ringnumber')) {
                            if (
                                this.form.errors
                                    .get('ringnumber')
                                    .includes('Ringnummer bestaat reeds in onze database') ||
                                this.form.errors
                                    .get('ringnumber')
                                    .includes('Ringnumber already exists')
                            ) {
                                this.existsInDatabase = true;
                            }
                        }
                        this.submitting = false;
                    });
            } else {
                this.form
                    .post(this.endpoint)
                    .then((response) => {
                        flash(response.message, response.type);
                        window.location.href =
                            '/pigeon/' + response.pigeonId + '/edit#pigeon_edit_pedigree_details';
                        this.form.errors.clear();
                        this.submitting = false;
                    })
                    .catch((e) => {
                        this.form.errors.record(e.errors);
                        if (this.form.errors.has('ringnumber')) {
                            if (
                                this.form.errors
                                    .get('ringnumber')
                                    .includes('Ringnummer bestaat reeds in onze database') ||
                                this.form.errors
                                    .get('ringnumber')
                                    .includes('Ringnumber already exists')
                            ) {
                                this.existsInDatabase = true;
                            }
                        }
                        this.submitting = false;
                    });
            }
        },

        landcode() {
            let landcode = 'AU';

            if (this.user.settings.standard_landcode) {
                landcode = this.user.settings.standard_landcode;
            }

            if (this.pigeon) {
                landcode = this.pigeon.landcode;
            }

            return landcode;
        },

        landcodeFather() {
            let landcode = 'AU';

            if (this.user.settings.standard_landcode) {
                landcode = this.user.settings.standard_landcode;
            }

            if (this.pigeon && this.pigeon.father) {
                landcode = this.pigeon.father.landcode;
            }

            return landcode;
        },

        landcodeMother() {
            let landcode = 'AU';

            if (this.user.settings.standard_landcode) {
                landcode = this.user.settings.standard_landcode;
            }

            if (this.pigeon && this.pigeon.mother) {
                landcode = this.pigeon.mother.landcode;
            }

            return landcode;
        },

        changeMask(value) {
            Inputmask({ mask: figureOutMask(value) }).mask(
                document.getElementById('ringnumber_node')
            );
        },
    },
};
</script>