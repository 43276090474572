import Vue from 'vue';
import Vuetify from 'vuetify';
import en from '../../../node_modules/vuetify/src/locale/en.ts';
import nl from '../../../node_modules/vuetify/src/locale/nl.ts';
import 'vuetify/dist/vuetify.min.css';

var appLang = window.location.pathname.substring(1, 3);

const opts = {
    lang: {
        locales: { en, nl },
        current: appLang,
    },
    theme: {
        themes: {
            light: {
                primary: '#59c9a5',
                secondary: '#424242',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
            },
            dark: {
                primary: '#59c9a5',
                secondary: '#424242',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
            },
        },
    },
};

export default new Vuetify(opts);

Vue.use(Vuetify);
