<template v-cloak>
    <v-card>
        <v-card-title>
            <span v-text="$t('result.overview-results-of-pigeon')"></span>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('helpers.search')"
            ></v-text-field>
        </v-card-title>
        <v-data-table
            item-key="id"
            class="elevation-1"
            show-expand
            :items="items"
            :search="search"
            :headers="headers"
            :items-per-page="10"
            :single-expand="false"
            :loading="loadingState"
            :expanded.sync="expanded"
            :footer-props="footerProps"
            :sort-by="['place']"
            :sort-desc="[false, true]"
            :multi-sort="true"
            @click:row="clicked"
        >
            <template v-slot:[`item.pigeon.ringnumber`]="{ item }">
                <span class="tw-block">
                    <a
                        v-text="item.pigeon.ringnumber"
                        :href="`/pigeon/${item.pigeon.id}`"
                        class="tw-cursor-pointer hover:tw-text-blue-400"
                    ></a>
                </span>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="tw-py-6">
                    <span class="tw-block">
                        <strong>{{ $t('result.ad') }}:</strong>
                        {{ item.ad }}
                    </span>
                    <span class="tw-block">
                        <strong>{{ $t('result.gt') }}:</strong>
                        {{ item.gt }}
                    </span>
                    <span class="tw-block">
                        <strong>{{ $t('result.distance') }}:</strong>
                        {{ item.distance }}
                    </span>
                </td>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
export default {
    props: ['results'],

    data() {
        return {
            headers: [
                { text: 'Co.', value: 'pigeon.landcode' },
                { text: 'Ringnummer', value: 'pigeon.ringnumber' },
                { text: 'Plaats', value: 'place' },
                { text: 'Los', value: 'date_start_time' },
                { text: 'Aankomst', value: 'arrival_time' },
                { text: 'Losplaats', value: 'unloading_location' },
                { text: 'Snelheid', value: 'velocity' },
                { text: trans('list.actions'), value: 'data-table-expand' },
            ],
            items: this.results,
            expanded: [],
            search: '',
            footerProps: { 'items-per-page-options': [5, 10, 15, 30, 50, 100] },

            dialog: false,
            loadingState: false,
        };
    },

    methods: {
        clicked(value, data) {
            if (event.target.tagName == 'TD') {
                if (!data.isExpanded) {
                    this.expanded.push(value);
                } else {
                    this.expanded = this.expanded.filter((pigeon) => {
                        return value.id != pigeon.id;
                    });
                }
            }
        },
    },
};
</script>
