<template>
    <a v-if="this.userId == this.currentUserId" :href="userEditRoute">
        {{ $t('user.edit') }}
        <i class="fas fa-ellipsis-h tw-self-center tw-ml-2"></i>
    </a>
</template>
<script>
export default {
    props: ['userId', 'currentUserId'],

    computed: {
        userEditRoute() {
            return route('user.edit', this.currentUserId);
        },
    },
};
</script>
