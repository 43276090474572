<template>
    <section>
        <div
            v-if="loadingRankingList"
            class="tw-p-2 lg:tw-p-4 tw-bg-white tw-flex tw-flex-col tw-items-center"
        >
            <v-progress-circular
                :size="70"
                :width="7"
                color="green"
                indeterminate
            ></v-progress-circular>
        </div>
        <div
            v-else-if="orderedAllClocked.length > 0"
            class="tw-p-2 lg:tw-px-4 tw-bg-white"
            dusk="clock_show_ranked_pigeons"
        >
            <div class="tw-overflow-x-auto tw-whitespace-no-wrap">
                <table class="tw-text-left tw-border-collapse tw-w-full" id="ranking-list">
                    <thead>
                        <tr>
                            <th
                                class="tw-p-1 tw-bg-grey-lightest tw-font-bold tw-text-sm tw-text-grey-dark tw-border-b tw-border-grey-light"
                            >
                                #
                            </th>
                            <th
                                class="tw-p-1 tw-bg-grey-lightest tw-font-bold tw-text-sm tw-text-grey-dark tw-border-b tw-border-grey-light"
                                v-text="$t('clock.ringnumber')"
                            ></th>
                            <th
                                class="tw-p-1 tw-bg-grey-lightest tw-font-bold tw-text-sm tw-text-grey-dark tw-border-b tw-border-grey-light"
                                v-text="$t('clock.fancier')"
                            ></th>
                            <th
                                class="tw-p-1 tw-bg-grey-lightest tw-font-bold tw-text-sm tw-text-grey-dark tw-border-b tw-border-grey-light"
                                v-text="$t('clock.velocity')"
                            ></th>
                            <th
                                class="tw-p-1 tw-bg-grey-lightest tw-font-bold tw-text-sm tw-text-grey-dark tw-border-b tw-border-grey-light"
                                v-text="$t('clock.arrival-time')"
                            ></th>
                        </tr>
                    </thead>

                    <transition-group tag="tbody" name="fade-out-up">
                        <tr
                            v-for="(pigeon, index) in orderedAllClocked"
                            :key="pigeon.id"
                            class="hover:tw-bg-grey-lighter tw-text-sm"
                        >
                            <td
                                class="tw-p-1 tw-border-b tw-border-grey-light hover:tw-cursor-pointer"
                            >
                                {{ index + 1 }}
                            </td>
                            <td class="tw-border-b tw-border-grey-light hover:tw-cursor-pointer">
                                <a
                                    class="tw-p-1 hover:tw-text-primary-400"
                                    :href="`/pigeon/${pigeon.id}`"
                                    >{{ pigeon.ringnumber | formatRingnumber(pigeon.landcode) }}</a
                                >
                            </td>
                            <td
                                class="tw-p-1 tw-border-b tw-border-grey-light hover:tw-cursor-pointer"
                            >
                                {{ pigeon.name }}
                            </td>
                            <td
                                class="tw-p-1 tw-border-b tw-border-grey-light hovertw-:cursor-pointer"
                            >
                                {{ pigeon.velocity }}
                            </td>
                            <td
                                class="tw-p-1 tw-border-b tw-border-grey-light hover:tw-cursor-pointer"
                            >
                                {{ pigeon.clocked }}
                            </td>
                        </tr>
                    </transition-group>
                </table>
            </div>
        </div>

        <div class="tw-p-2 lg:tw-p-4 tw-bg-white" v-else>
            <span
                class="tw-text-gray-500 tw-text-xs md:tw-text-base xl:tw-text-md"
                v-text="$t('clock.no-results')"
            ></span>
        </div>
    </section>
</template>

<script>
export default {
    props: ['orderedAllClocked', 'loadingRankingList'],
};
</script>
