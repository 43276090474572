<template v-cloak>
    <div>
        <div class="tw-mb-4">
            <button
                class="tw-mt-3 btn-primary tw-rounded tw-mb-2 tw-shadow-md"
                @click="showResultAdder"
            >
                <i class="fas" :class="show ? 'fa-minus' : 'fa-plus'"></i>
                <span class="adder-text" v-text="$t('result.add-result')"></span>
            </button>

            <transition name="slide">
                <div v-if="show" class="add-result-form tw-p-2 tw-bg-white tw-rounded">
                    <div class="tw-flex tw-flex-wrap">
                        <div class="tw-w-full md:tw-w-1/3 tw-px-1 md:tw-px-3 tw-mb-4">
                            <label for="place" class="label-tw" v-text="$t('result.place')"></label>
                            <input
                                type="number"
                                v-model="place"
                                :placeholder="$t('result.ph-place')"
                                class="input-field-tw focus:tw-outline-none focus:tw-bg-white"
                            />
                            <small
                                v-if="vueErrors.place"
                                class="tw-text-red-500 tw-text-xs"
                                v-text="vueErrors.place"
                            ></small>
                        </div>

                        <div class="tw-w-1/2 md:tw-w-1/3 tw-px-1 md:tw-px-3 tw-mb-4">
                            <label for="ad" class="label-tw" v-text="$t('result.ad')"></label>
                            <input
                                type="number"
                                v-model="ad"
                                :placeholder="$t('result.ph-ad')"
                                class="input-field-tw focus:tw-outline-none focus:tw-bg-white"
                            />
                            <small
                                v-if="vueErrors.ad"
                                class="tw-text-red-500 tw-text-xs"
                                v-text="vueErrors.ad"
                            ></small>
                        </div>

                        <div class="tw-w-1/2 md:tw-w-1/3 tw-px-1 md:tw-px-3 tw-mb-4">
                            <label for="gt" class="label-tw" v-text="$t('result.gt')"></label>
                            <input
                                type="number"
                                v-model="gt"
                                :placeholder="$t('result.ph-gt')"
                                class="input-field-tw focus:tw-outline-none focus:tw-bg-white"
                            />
                            <small
                                v-if="vueErrors.gt"
                                class="tw-text-red-500 tw-text-xs"
                                v-text="vueErrors.gt"
                            ></small>
                        </div>
                    </div>

                    <div class="tw-flex tw-flex-wrap">
                        <div class="tw-w-full md:tw-w-1/3 tw-px-1 md:tw-px-3 tw-mb-2">
                            <label
                                for="unloading_location"
                                class="label-tw"
                                v-text="$t('result.unloading-location')"
                            ></label>
                            <select
                                v-model="unloading_location"
                                class="input-field-tw focus:tw-outline-none focus:tw-bg-white"
                            >
                                <option
                                    disabled
                                    value
                                    v-text="$t('clock.choose-an-unloading-location')"
                                ></option>
                                <option
                                    :key="index"
                                    v-for="(unloading_location, index) in unloadingLocations"
                                    :value="unloading_location.unloading_location"
                                    v-text="unloading_location.unloading_location"
                                ></option>
                            </select>
                            <small
                                v-if="vueErrors.unloading_location"
                                class="tw-text-red-500 tw-text-xs"
                                v-text="vueErrors.unloading_location"
                            ></small>
                            <br v-if="vueErrors.unloading_location" />
                            <a
                                target="_blank"
                                href="/unloading/new"
                                class="
                                    tw-text-xs
                                    tw-text-primary-500
                                    tw-mt-1
                                    tw-block
                                    tw-leading-normal
                                "
                                v-text="$t('clock.ul-not-present')"
                            ></a>
                        </div>

                        <div
                            class="
                                tw-flex tw-flex-col tw-w-full
                                md:tw-w-1/3
                                tw-px-1
                                md:tw-px-3
                                tw-mb-2
                            "
                        >
                            <label class="label-tw">{{ $t('result.date') }}</label>
                            <b-datetimepicker
                                v-model="date_start_time"
                                rounded
                                placeholder="Click to select..."
                                icon="calendar-today"
                                :datepicker="{ showWeekNumber }"
                                :timepicker="{ enableSeconds, hourFormat: '24' }"
                                :first-day-of-week="1"
                            ></b-datetimepicker>
                            <small
                                v-if="vueErrors.date_start_time"
                                class="tw-text-red-500 tw-text-xs"
                                v-text="vueErrors.date_start_time"
                            ></small>
                        </div>

                        <div
                            class="
                                tw-flex tw-flex-col tw-w-full
                                md:tw-w-1/3
                                tw-px-1
                                md:tw-px-3
                                tw-mb-2
                            "
                        >
                            <div>
                                <div v-if="noArrivalTimeGiven == false">
                                    <label class="label-tw">{{ $t('result.time') }}</label>
                                    <b-datetimepicker
                                        v-model="arrival_time"
                                        rounded
                                        placeholder="Click to select..."
                                        icon="calendar-today"
                                        :datepicker="{ showWeekNumber }"
                                        :timepicker="{ enableSeconds, hourFormat: '24' }"
                                        :first-day-of-week="1"
                                    ></b-datetimepicker>
                                    <small
                                        v-if="vueErrors.arrival_time"
                                        class="tw-text-red-500 tw-text-xs"
                                        v-text="vueErrors.arrival_time"
                                    ></small>
                                </div>
                                <div v-else>
                                    <label
                                        for="ad"
                                        class="label-tw"
                                        v-text="$t('result.velocity')"
                                    ></label>
                                    <input
                                        type="number"
                                        v-model="mpm"
                                        :placeholder="$t('result.ph-velocity')"
                                        class="
                                            input-field-tw
                                            focus:tw-outline-none focus:tw-bg-white
                                        "
                                    />
                                    <small
                                        v-if="vueErrors.mpm"
                                        class="tw-text-red-500 tw-text-xs"
                                        v-text="vueErrors.mpm"
                                    ></small>
                                </div>
                            </div>
                            <v-checkbox v-model="noArrivalTimeGiven">
                                <template v-slot:label>
                                    <span
                                        class="tw-text-xs"
                                        v-text="$t('result.click-to-set-velocity')"
                                    ></span>
                                </template>
                            </v-checkbox>
                        </div>

                        <div class="tw-w-full md:tw-w-1/3 tw-px-1 md:tw-px-3 tw-mb-4">
                            <label
                                for="competition"
                                class="label-tw"
                                v-text="$t('result.competition')"
                            ></label>
                            <select
                                v-model="competition"
                                class="input-field-tw focus:tw-outline-none focus:tw-bg-white"
                            >
                                <option
                                    disabled
                                    selected
                                    value
                                    v-text="$t('result.ph-competition')"
                                ></option>
                                <option value="club">Club</option>
                                <option value="provincial">Provincial</option>
                                <option value="interprovincial">Interprovincial</option>
                                <option value="zonal">Zonal</option>
                                <option value="national">National</option>
                                <option value="international">International</option>
                                <option value="union">Union</option>
                                <option value="league">League</option>
                                <option value="olr">One Loft Race</option>
                            </select>
                            <small
                                v-if="vueErrors.competition"
                                class="tw-text-red-500 tw-text-xs"
                                v-text="vueErrors.competition"
                            ></small>
                        </div>

                        <div class="tw-w-full md:tw-w-1/3 tw-px-1 md:tw-px-3 tw-mb-4">
                            <label for="wind" class="label-tw" v-text="$t('result.wind')"></label>
                            <select
                                v-model="wind"
                                class="input-field-tw focus:tw-outline-none focus:tw-bg-white"
                            >
                                <option
                                    disabled
                                    selected
                                    value
                                    v-text="$t('result.ph-wind')"
                                ></option>
                                <option value="north" v-text="$t('result.north')"></option>
                                <option
                                    value="north_east"
                                    v-text="$t('result.north_east')"
                                ></option>
                                <option
                                    value="north_north_east"
                                    v-text="$t('result.north_north_east')"
                                ></option>
                                <option
                                    value="east_north_east"
                                    v-text="$t('result.east_north_east')"
                                ></option>
                                <option value="east" v-text="$t('result.east')"></option>
                                <option
                                    value="east_south_east"
                                    v-text="$t('result.east_south_east')"
                                ></option>
                                <option
                                    value="south_east"
                                    v-text="$t('result.south_east')"
                                ></option>
                                <option
                                    value="south_south_east"
                                    v-text="$t('result.south_south_east')"
                                ></option>
                                <option value="south" v-text="$t('result.south')"></option>
                                <option
                                    value="south_south_west"
                                    v-text="$t('result.south_south_west')"
                                ></option>
                                <option
                                    value="south_west"
                                    v-text="$t('result.south_west')"
                                ></option>
                                <option
                                    value="west_south_west"
                                    v-text="$t('result.west_south_west')"
                                ></option>
                                <option value="west" v-text="$t('result.west')"></option>
                                <option
                                    value="west_norht_west"
                                    v-text="$t('result.west_norht_west')"
                                ></option>
                                <option
                                    value="north_west"
                                    v-text="$t('result.north_west')"
                                ></option>
                                <option
                                    value="north_north_west"
                                    v-text="$t('result.north_north_west')"
                                ></option>
                            </select>
                            <small
                                v-if="vueErrors.wind"
                                class="tw-text-red-500 tw-text-xs"
                                v-text="vueErrors.wind"
                            ></small>
                        </div>
                    </div>

                    <div class="tw-flex tw-justify-end tw-px-1 md:tw-px-3 tw-mt-2">
                        <input
                            type="submit"
                            id="submit-result"
                            dusk="submit-pigeon"
                            :value="$t('result.submit-result')"
                            class="btn-primary tw-cursor-pointer tw-rounded"
                            @click="saveResult"
                        />
                    </div>
                </div>
            </transition>
        </div>

        <v-dialog v-model="loading" persistent width="300">
            <v-card color="green darken-2" dark>
                <v-card-text>
                    {{ $t('messages.saving-result') }}
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import moment from 'moment';
import Inputmask from 'vue-inputmask';
import { Datetimepicker } from 'buefy';

export default {
    props: ['unloadingLocations', 'postUrl'],

    data() {
        return {
            show: false,
            loading: false,
            showWeekNumber: false,
            formatAmPm: false,
            enableSeconds: true,
            locale: window.location.pathname.substring(1, 3),
            noArrivalTimeGiven: false,

            place: null,
            ad: null,
            gt: null,
            date_start_time: null,
            arrival_time: null,
            unloading_location: '',
            mpm: null,
            competition: '',
            wind: '',
            vueErrors: {
                place: null,
                ad: null,
                gt: null,
                date_start_time: null,
                arrival_time: null,
                unloading_lation: null,
                mpm: null,
                competition: null,
                wind: null,
            },
        };
    },

    components: {
        Inputmask,
        Datetimepicker,
    },

    methods: {
        showResultAdder() {
            if (latLongVerified != '1') {
                window.location.href = route('user.latLong.verification');
            } else {
                this.show = !this.show;
            }
        },

        saveResult() {
            this.loading = true;

            this.vueErrors.place = null;
            this.vueErrors.ad = null;
            this.vueErrors.gt = null;
            this.vueErrors.date_start_time = null;
            this.vueErrors.unloading_location = null;
            this.vueErrors.date_start_time = null;
            this.vueErrors.arrival_time = null;
            this.vueErrors.mpm = null;
            this.vueErrors.competition = null;
            this.vueErrors.wind = null;
            axios
                .post(this.postUrl, {
                    place: this.place,
                    ad: this.ad,
                    gt: this.gt,
                    unloading_location: this.unloading_location,
                    date_start_time: this.date_start_time
                        ? moment(this.date_start_time).format('D-M-Y H:mm')
                        : null,
                    arrival_time: this.arrival_time
                        ? moment(this.arrival_time).format('D-M-Y H:mm:ss')
                        : null,
                    mpm: this.mpm ? this.mpm : null,
                    noArrivalTimeGiven: this.noArrivalTimeGiven,
                    competition: this.competition,
                    wind: this.wind,
                })
                .then((res) => {
                    this.$emit('resultAdded', res.data.result);

                    // Reset the form
                    this.place = null;
                    this.ad = null;
                    this.gt = null;
                    this.date_start_time = null;
                    this.arrival_time = null;
                    this.date_start_time = null;
                    this.unloading_location = null;
                    this.mpm = null;
                    this.competition = null;
                    this.wind = null;
                    this.noArrivalTimeGiven = false;

                    // Reset the errors
                    this.vueErrors.place = null;
                    this.vueErrors.ad = null;
                    this.vueErrors.gt = null;
                    this.vueErrors.date_start_time = null;
                    this.vueErrors.unloading_location = null;
                    this.vueErrors.date_start_time = null;
                    this.vueErrors.arrival_time = null;
                    this.vueErrors.mpm = null;
                    this.vueErrors.competition = null;
                    this.vueErrors.wind = null;

                    this.loading = false;

                    flash(res.data.message, res.data.type);
                })
                .catch((err) => {
                    // This bit of code traverses over all errors in the response
                    // and adds them to the vueErrors object to display in the front-end
                    if (err.response.data.errors) {
                        const errors = err.response.data.errors;
                        Object.keys(errors).forEach((validationError) => {
                            this.vueErrors[validationError] = errors[validationError][0];
                        });
                    } else {
                        flash(err.response.data.message, err.response.data.type);
                    }

                    this.loading = false;
                });
        },
    },
};
</script>

<style scoped>
.icon {
    font-size: 0.75rem;
}

.label {
    font-weight: normal;
}

.add-result-form {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.slide-leave-active,
.slide-enter-active {
    transition: all 0.3s ease;
}

.slide-enter,
.slide-leave-to {
    opacity: 0;
    transform: translateY(100%);
    margin-bottom: -100px;
}

.v-input__control {
    height: 20px !important;
}

.datepicker >>> .control.has-icons-left .icon.is-left {
    font-size: 12px !important;
}
</style>
