<template>
    <div class="tw-w-full tw-mt-2 tw-relative">
        <v-dialog v-model="dialog" persistent max-width="600">
            <template v-slot:activator="{ on }">
                <v-btn
                    color="primary"
                    dark
                    v-on="on"
                    dusk="open-member-add-modal"
                    v-text="$t('clock.add-a-member')"
                ></v-btn>
            </template>
            <v-card>
                <v-card-title class="headline" v-text="$t('clock.add-a-member')"></v-card-title>
                <v-card-text>
                    <v-progress-circular
                        v-if="loadingFollowings && loadingMembers && inTheProcessOfAddingMember"
                        :size="70"
                        :width="7"
                        color="green"
                        indeterminate
                    ></v-progress-circular>
                    <select
                        :class="
                            eligibleMembers.length == 0
                                ? 'tw-text-gray-600'
                                : 'hover:tw-border-gray-500'
                        "
                        :disabled="eligibleMembers.length == 0"
                        class="tw-block tw-appearance-none tw-w-full tw-bg-white tw-border tw-border-gray-400 tw-px-4 tw-py-2 tw-pr-8 tw-rounded tw-shadow tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline lg:tw-text-sm"
                        name="member-add"
                        v-model="selectedMemberId"
                    >
                        <option disabled selected value="a1a">
                            {{ $t('clock.choose-a-member') }}
                        </option>
                        <option
                            class="tw-flex tw-justify-between tw-bg-white hover:tw-bg-blue-100 tw-p-2 tw-cursor-default"
                            v-for="(member, index) in eligibleMembers"
                            :key="index"
                            :value="member.id"
                            :dusk="'option-' + member.id"
                            v-text="member.name"
                        ></option>
                    </select>

                    <span
                        v-if="eligibleMembers.length == 0"
                        class="tw-group tw-mt-4 tw-text-gray-600 tw-text-xs tw-bg-red-100 tw-p-2 tw-block tw-rounded-lg"
                    >
                        <i class="fas fa-info-circle group-hover:tw-text-gray-900"></i>
                        <span v-text="$t('clock.no-followers-left-to-add')"></span>
                    </span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red darken-1"
                        text
                        @click="dialog = false"
                        v-text="$t('helpers.close')"
                    ></v-btn>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="addMemberToGroup"
                        dusk="add-member-to-group"
                        v-text="$t('clock.add-member')"
                    ></v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axiosInVue from 'axios';
import MemberAddModal from './MemberAddModal';

export default {
    props: ['clockGroupId'],

    components: {
        MemberAddModal,
    },

    data() {
        return {
            members: [],
            dialog: false,
            followings: [],
            loadingMembers: false,
            selectedMemberId: null,
            loadingFollowings: false,
            inTheProcessOfAddingMember: false,
        };
    },

    computed: {
        eligibleMembers() {
            const members = JSON.stringify(this.members);

            return this.followings.filter((following) => {
                if (!members.includes(following.email)) {
                    return following;
                }
            });
        },
    },

    methods: {
        addMemberToGroup() {
            this.inTheProcessOfAddingMember = true;

            axiosInVue
                .post(`/clock/${this.clockGroupId}/member/add`, {
                    memberId: this.selectedMemberId,
                })
                .then((res) => {
                    flash(trans('clock.members-added'), 'success');
                    location.reload();
                    this.inTheProcessOfAddingMember = false;
                    this.dialog = false;
                });
        },
    },

    mounted() {
        this.loadingMembers = true;
        this.loadingFollowings = true;
        this.selectedMemberId = 'a1a';

        axiosInVue.get('/clock_group/user/followings').then((res) => {
            this.followings = res.data;
            this.loadingFollowings = false;
        });

        axiosInVue.get(`/clock_group/${this.clockGroupId}/members`).then((res) => {
            this.members = res.data;
            this.loadingMembers = false;
        });
    },
};
</script>
