<template>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600"
        @keydown.esc="dialog = false"
        @keydown.enter="addPigeonsToPostCreate"
    >
        <template v-slot:activator="{ on }">
            <v-btn color="green lighten-1" v-on="on" dusk="open-post-create-add-pigeon">
                <i class="fas fa-dove tw-text-white tw-mr-1"></i>
                <span
                    class="tw-text-white tw-capitalize"
                    v-text="$t('social.add-pigeons-to-post')"
                ></span>
            </v-btn>
        </template>
        <v-card>
            <v-card-title
                class="tw-flex tw-justify-between tw-text-gray-600 tw-bg-gray-100 tw-px-4 tw-py-2 tw-mb-2"
                v-text="$t('social.add-pigeon-to-post')"
            ></v-card-title>
            <ul>
                <li
                    :key="index"
                    class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center tw-content-center tw-bg-blue-100 hover:tw-bg-blue-200 tw-px-4 tw-p-2 tw-rounded-lg tw-mb-2"
                    v-for="(currentlySelectedPigeon, index) in selectedPigeonsInStoreInComponent"
                >
                    <span>{{
                        currentlySelectedPigeon.ringnumber
                            | formatRingnumber(currentlySelectedPigeon.landcode)
                    }}</span>

                    <post-create-add-result
                        :index="index"
                        :pigeon="currentlySelectedPigeon"
                    ></post-create-add-result>

                    <button @click="removeSelectedPigeon(index)" class="hover:tw-opacity-75">
                        <i class="far fa-times-circle"></i>
                    </button>
                </li>
            </ul>

            <div class="tw-my-8">
                <div
                    v-if="fetchingPigeons"
                    class="tw-p-2 lg:tw-p-4 tw-bg-white tw-flex tw-flex-col tw-items-center"
                >
                    <v-progress-circular
                        :size="70"
                        :width="7"
                        color="green"
                        indeterminate
                    ></v-progress-circular>
                </div>
                <div v-else-if="canUserAddMorePigeons" class="tw-flex tw-flex-col tw-px-4">
                    <v-autocomplete
                        v-model="selected"
                        :items="pigeonsNotYetSelectedForSubmission"
                        :label="$t('social.choose-pigeon')"
                        :item-text="(item) => `${item.landcode} ${item.ringnumber}`"
                        item-value="id"
                        outlined
                        clearable
                        name="add-pigeon-to-post-select"
                        @change="addPigeonToInterface($event)"
                        @input="afterselection"
                    >
                        <!-- <template v-slot:item="{ item }">
                                <span>
                                    <strong>{{ item.landcode }}</strong>
                                    {{ item.ringnumber }}
                                </span>
                            </template>-->
                        <template v-slot:selection="{ item }">
                            <span>
                                <strong>{{ item.landcode }}</strong>
                                {{ item.ringnumber }}
                            </span>
                        </template>
                    </v-autocomplete>
                </div>
                <v-alert
                    v-else-if="!canUserAddMorePigeons && !this.isMember"
                    border="left"
                    type="error"
                    icon="mdi-alert"
                    elevation="2"
                    colored-border
                    dismissible
                    dense
                    >{{ $t('social.pigeon-add-limit') }}</v-alert
                >
            </div>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="error darken-1"
                    text
                    @click="dialog = false"
                    v-text="$t('helpers.close')"
                ></v-btn>
                <v-btn
                    color="primary darken-1"
                    text
                    @click="addPigeonsToPostCreate"
                    v-text="$t('social.add-to-post')"
                    dusk="add-pigeon-to-post"
                ></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import PostCreateAddResult from './PostCreateAddResult';

export default {
    props: ['isMember', 'selectedPigeonsInStoreInComponent'],

    data() {
        return {
            dialog: false,
            pigeons: [],
            selected: $trans('social.choose-a-pigeon'),
            canUserClickButton: true,
            showAddResultInput: false,
            fetchingPigeons: false,
        };
    },

    components: {
        PostCreateAddResult,
    },

    computed: {
        pigeonsNotYetSelectedForSubmission() {
            return this.pigeons.filter((pigeon) => !this.selectedPigeonsInStore.includes(pigeon));
        },

        selectedPigeonsInStore: {
            get: function () {
                return this.$store.getters.getPostCreateModalSelectedPigeons;
            },

            set: function () {
                this.$store.commit(
                    'UPDATE_SELECTED_PIGEONS',
                    this.selectedPigeonsInStoreInComponent
                );
            },
        },

        canUserAddMorePigeons() {
            if (this.isMember && this.selectedPigeonsInStore.length < 5) {
                return true;
            }

            return this.selectedPigeonsInStore.length == 0 || false;
        },
    },

    methods: {
        addPigeonToInterface() {
            const pigeon = this.pigeons.filter((pigeon) => {
                return pigeon.id === this.selected;
            });

            if (!this.selectedPigeonsInStoreInComponent.includes(pigeon[0])) {
                this.selectedPigeonsInStoreInComponent.push(pigeon[0]);
            }

            this.selectedPigeonsInStore = this.selectedPigeonsInStoreInComponent;
            this.selected = $trans('social.choose-a-pigeon');
        },

        removeSelectedPigeon(index) {
            this.$store.commit('DELETE_SELECTED_PIGEON', index);
            this.selectedPigeonsInStoreInComponent.splice(index, 1);
            this.selected = $trans('social.choose-a-pigeon');
        },

        addPigeonsToPostCreate() {
            this.dialog = false;
        },

        afterselection(item) {
            this.$nextTick(() => {
                this.selected = null;
            });
        },
    },

    mounted() {
        this.fetchingPigeons = true;

        axios.get(route('post.pigeon.index')).then((res) => {
            this.pigeons = res.data;
            this.fetchingPigeons = false;
        });
    },
};
</script>
