<template>
    <div class="comments-app">
        <!-- From -->
        <div class="comment-form" v-if="user">
            <form class="form comment-form" name="form">
                <div class="form-row">
                    <textarea
                        v-model="messageMain"
                        required
                        class="input reply-add-comment-field tw-border-b-lg"
                        :class="errorComment ? 'tw-border tw-border-red-500' : ''"
                        :placeholder="$t('comment.add-comment')"
                        @input="errorComment = null"
                        dusk="add_comment_to_post_textarea"
                    ></textarea>
                    <span class="tw-w-full tw-text-red-600 tw-text-xs" v-if="errorComment">{{
                        errorComment
                    }}</span>
                </div>

                <div class="form-row tw-flex jtw-ustify-end">
                    <input
                        type="button"
                        class="btn-primary reply-add-comment tw-rounded tw-cursor-pointer tw-text-sm"
                        @click="saveComment"
                        :value="$t('comment.add-comment-button')"
                        dusk="add_comment_to_post"
                    />
                </div>
            </form>
        </div>
        <div class="comment-form" v-else>
            <form class="form comment-form" name="form">
                <div class="form-row">
                    <a href="login">
                        <textarea
                            class="input"
                            :placeholder="$t('comment.add-comment')"
                            required
                        ></textarea>
                    </a>
                </div>
            </form>
        </div>
        <!-- Comments List -->
        <div v-if="comments" class="relative">
            <a
                :href="'#' + commentId"
                v-if="commentId && fullyLoaded"
                class="tw-absolute tw-bg-gray-700 cursor-pointer tw-flex hover:tw-bg-gray-900 tw-mt-2 pin-x-auto tw-px-4 tw-py-2 tw-rounded-lg tw-shadow-2xl tw-text-white tw-z-20 tw-text-xs md:tw-text-sm"
                :class="alreadyScrolled ? 'tw-hidden' : 'tw-flex'"
                @click="alreadyScrolled = true"
                v-smooth-scroll
            >
                <i class="fas fa-arrow-down tw-mr-2 tw-leading-normal"></i>
                Scroll to comment
            </a>

            <div class="comments" :key="index" v-for="(comment, index) in commentsData">
                <!-- Comment -->
                <div v-if="!spamComments[index] || !comment.spam" class="comment">
                    <!-- Comment Avatar -->
                    <div class="comment-avatar hiero">
                        <img :src="comment.user_avatar" />
                    </div>

                    <!-- Comment Box -->
                    <div class="comment-box" :id="comment.commentid" dusk="comment_box">
                        <div class="tw-text-sm" style="color: #555f77">{{ comment.comment }}</div>
                        <div
                            class="tw-flex tw-flex-row tw-justify-between tw-text-sm tw-mt-2"
                            style="color: #acb4c2"
                        >
                            <div class="tw-flex tw-flex-col lg:tw-flex-row">
                                <span class="comment-author">
                                    <em>{{ comment.name }}</em>
                                </span>
                                <span class="comment-date">
                                    <span
                                        class="tw-hidden lg:tw-inline"
                                        style="margin: 0 10px"
                                        v-text="'|'"
                                    ></span>
                                    {{ comment.date | ago }}
                                </span>
                            </div>

                            <ul>
                                <li class="tw-flex tw-flex-row">
                                    <a
                                        v-on:click="openComment(index)"
                                        class="reply-to-reply tw-cursor-pointer hover:tw-opacity-75"
                                        dusk="open_reply_to_comment"
                                    >
                                        <i class="fas fa-reply"></i>
                                        {{ $t('comments.reply') }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- From -->
                    <div class="comment-form comment-v" v-if="commentBoxs[index]">
                        <form class="form comment-form" name="form">
                            <div class="form-row">
                                <textarea
                                    class="input reply-add-comment-field reply-add-comment-field-from"
                                    :class="errorReply ? 'tw-border tw-border-red-500' : ''"
                                    @input="errorReply = null"
                                    :placeholder="$t('comment.add-comment')"
                                    required
                                    v-model="message"
                                    rows="1"
                                    dusk="add_reply_to_comment_textarea"
                                ></textarea>
                                <span
                                    class="tw-w-full tw-text-red-600 tw-text-xs"
                                    v-if="errorReply"
                                    >{{ errorReply }}</span
                                >
                            </div>

                            <div class="form-row tw-flex tw-justify-end">
                                <input
                                    type="button"
                                    class="btn-primary reply-add-comment tw-rounded tw-cursor-pointer tw-text-sm"
                                    v-on:click="replyComment(comment.commentid, index)"
                                    :value="$t('comment.add-comment-button')"
                                    dusk="add_reply_to_comment"
                                />
                            </div>
                        </form>
                    </div>
                    <!-- Comment - Reply -->
                    <div v-if="comment.replies">
                        <div
                            class="comments"
                            :key="index2"
                            v-for="(replies, index2) in comment.replies"
                        >
                            <div
                                v-if="!spamCommentsReply[index2] || !replies.spam"
                                class="comment reply"
                            >
                                <!-- Comment Avatar -->
                                <div class="comment-avatar daaro">
                                    <img :src="replies.user_avatar" />
                                </div>
                                <!-- Comment Box -->
                                <div
                                    class="comment-box"
                                    data-comment_box
                                    :id="replies.commentid"
                                    style="background: #4a5568"
                                >
                                    <div
                                        class="comment-text"
                                        style="color: white"
                                        dusk="add_reply_to_comment_box"
                                    >
                                        {{ replies.comment }}
                                    </div>
                                    <div class="comment-footer mt-2">
                                        <div class="comment-info">
                                            <span class="comment-author">{{ replies.name }}</span>
                                            <span class="comment-date">
                                                <span
                                                    class="tw-hidden lg:tw-inline"
                                                    style="margin: 0 10px"
                                                    v-text="'|'"
                                                ></span>
                                                {{ replies.date | ago }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- From -->
                                <div class="comment-form reply" v-if="replyCommentBoxs[index2]">
                                    <!-- Comment Avatar -->
                                    <div class="comment-avatar">
                                        <img :src="replies.avatar_url" />
                                    </div>

                                    <form class="form comment-form" name="form">
                                        <div class="form-row">
                                            <textarea
                                                class="input reply-add-comment-field reply-add-comment-field-from2"
                                                :placeholder="$t('comment.add-comment')"
                                                required
                                                v-model="message"
                                            ></textarea>
                                            <span
                                                class="input"
                                                v-if="errorReply"
                                                style="color: red"
                                                >{{ errorReply }}</span
                                            >
                                        </div>
                                        <div class="form-row tw-flex tw-justify-end">
                                            <input
                                                type="button"
                                                class="btn-primary reply-add-comment tw-rounded tw-cursor-pointer tw-text-sm"
                                                v-on:click="replyComment(comment.commentid, index)"
                                                dusk="add_reply_on_comment"
                                                :value="$t('comment.add-comment-button')"
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
var _ = require('lodash');
import moment from 'moment';
import vueSmoothScroll from 'vue2-smooth-scroll';
var language = window.location.pathname.substring(1, 3);

export default {
    props: ['commentUrl', 'currentUser'],

    data() {
        return {
            show: [],
            comments: 0,
            comments: [],
            message: null,
            commentBoxs: [],
            viewcomment: [],
            commentsData: [],
            spamComments: [],
            errorReply: null,
            fullyLoaded: null,
            messageMain: null,
            errorComment: null,
            commentreplies: [],
            replyCommentBoxs: [],
            spamCommentsReply: [],
            user: this.currentUser,
            alreadyScrolled: false,
            commentId: window.location.hash.substring(1),
        };
    },

    components: {
        vueSmoothScroll,
    },

    filters: {
        ago(date) {
            return moment(date).locale(language).local().fromNow();
        },
    },

    http: {
        headers: {
            'X-CSRF-TOKEN': document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute('content'),
        },
    },

    methods: {
        fetchComments() {
            axios.get('/comments/' + this.commentUrl).then((res) => {
                this.commentData = res.data;
                this.commentsData = _.orderBy(res.data, ['votes'], ['desc']);
                this.comments = 1;
                this.fullyLoaded = true;
            });
        },

        showComments(index) {
            if (!this.viewcomment[index]) {
                Vue.set(this.show, index, 'hide');
                Vue.set(this.viewcomment, index, 1);
            } else {
                Vue.set(this.show, index, 'view');
                Vue.set(this.viewcomment, index, 0);
            }
        },

        openComment(index) {
            if (this.user) {
                if (this.commentBoxs[index]) {
                    Vue.set(this.commentBoxs, index, 0);
                } else {
                    Vue.set(this.commentBoxs, index, 1);
                }
            }
        },

        replyCommentBox(index) {
            if (this.user) {
                if (this.replyCommentBoxs[index]) {
                    Vue.set(this.replyCommentBoxs, index, 0);
                } else {
                    Vue.set(this.replyCommentBoxs, index, 1);
                }
            }
        },

        saveComment() {
            if (this.messageMain != null && this.messageMain != ' ') {
                this.errorComment = null;
                axios
                    .post('/comments', {
                        page_id: this.commentUrl,
                        post_id: this.commentUrl,
                        comment: this.messageMain,
                        user_id: this.user.id,
                    })
                    .then((res) => {
                        if (res.data.status) {
                            this.commentsData.push({
                                commentid: res.data.commentId,
                                name: this.user.name,
                                user_avatar: res.data.user_avatar,
                                comment: this.messageMain,
                                votes: 0,
                                reply: 0,
                                replies: [],
                            });
                            this.messageMain = null;
                        }
                    })
                    .catch((err) => {});
            } else {
                this.errorComment = window.trans('comment.please-enter-comment');
            }
        },

        replyComment(commentId, index) {
            if (this.message != null && this.message != ' ') {
                this.errorReply = null;
                axios
                    .post('/comments', {
                        comment: this.message,
                        post_id: this.commentUrl,
                        user_id: this.user.id,
                        reply_id: commentId,
                    })
                    .then((res) => {
                        if (res.data.status) {
                            if (!this.commentsData[index].reply) {
                                this.commentsData[index].replies.push({
                                    commentid: res.data.commentId,
                                    name: this.user.name,
                                    user_avatar: res.data.user_avatar,
                                    comment: this.message,
                                    votes: 0,
                                });
                                this.commentsData[index].reply = 1;
                                Vue.set(this.replyCommentBoxs, index, 0);
                                Vue.set(this.commentBoxs, index, 0);
                            } else {
                                this.commentsData[index].replies.push({
                                    commentid: res.data.commentId,
                                    name: this.user.name,
                                    user_avatar: res.data.user_avatar,
                                    comment: this.message,
                                    votes: 0,
                                });
                                Vue.set(this.replyCommentBoxs, index, 0);
                                Vue.set(this.commentBoxs, index, 0);
                            }
                            this.message = null;
                        }
                    });
            } else {
                this.errorReply = window.trans('comment.please-enter-comment');
            }
        },

        voteComment(commentId, commentType, index, index2, voteType) {
            if (this.user) {
                axios
                    .post('/comments/' + commentId + '/vote', {
                        user_id: this.user.id,
                        vote: voteType,
                    })
                    .then((res) => {
                        if (res.data) {
                            if (commentType == 'directcomment') {
                                if (voteType == 'up') {
                                    this.commentsData[index].votes++;
                                } else if (voteType == 'down') {
                                    this.commentsData[index].votes--;
                                }
                            } else if (commentType == 'replycomment') {
                                if (voteType == 'up') {
                                    this.commentsData[index].replies[index2].votes++;
                                } else if (voteType == 'down') {
                                    this.commentsData[index].replies[index2].votes--;
                                }
                            }
                        }
                    });
            }
        },

        spamComment(commentId, commentType, index, index2) {
            if (this.user) {
                axios
                    .post('/comments/' + commentId + '/spam', {
                        user_id: this.user.id,
                    })
                    .then((res) => {
                        if (commentType == 'directcomment') {
                            Vue.set(this.spamComments, index, 1);
                            Vue.set(this.viewcomment, index, 1);
                        } else if (commentType == 'replycomment') {
                            Vue.set(this.spamCommentsReply, index2, 1);
                        }
                    });
            }
        },
    },

    mounted() {
        this.fetchComments();
    },
};
</script>
