<template>
    <div>
        <button @click="triggerError">Trigger Error</button>

        <button @click="triggerErrorHelper">Trigger Error Via Helper</button>
    </div>
</template>

<script>
export default {
    methods: {
        triggerError() {
            throw new Error('Testing Rollbar integration');
        },

        triggerErrorHelper() {
            this.$rollbar.info('Testing Rollbar integration via helper');
        },
    },
};
</script>