<template></template>

<script>
import axiosInVue from 'axios';

export default {
    props: ['clockGroupId'],

    data() {
        return {
            selectedMemberId: null,
            loadingMembers: false,
            loadingFollowings: false,
            inTheProcessOfAddingMember: false,
            members: [],
            followings: [],
        };
    },

    computed: {
        eligibleMembers() {
            const members = JSON.stringify(this.members);

            return this.followings.filter(following => {
                if (!members.includes(following.email)) {
                    return following;
                }
            });
        },
    },

    methods: {
        addMemberToGroup() {
            this.inTheProcessOfAddingMember = true;

            axiosInVue
                .post(`/clock/${this.clockGroupId}/member/add`, {
                    memberId: this.selectedMemberId,
                })
                .then(res => {
                    flash(trans('clock.members-added'), 'success');
                    location.reload();
                    this.inTheProcessOfAddingMember = false;
                });
        },
    },

    mounted() {
        this.loadingMembers = true;
        this.loadingFollowings = true;

        axiosInVue.get('/clock_group/user/followings').then(res => {
            this.followings = res.data;
            this.loadingFollowings = false;
        });

        axiosInVue.get(`/clock_group/${this.clockGroupId}/members`).then(res => {
            this.members = res.data;
            this.loadingMembers = false;
        });
    },
};
</script>
