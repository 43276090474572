<template v-cloak>
    <v-card>
        <v-card-title>
            <!-- <span v-text="$t('result.overview-results-of-pigeon')"></span> -->
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('helpers.search')"
            ></v-text-field>
        </v-card-title>
        <v-data-table
            item-key="id"
            class="elevation-1"
            :items="items"
            :search="search"
            :headers="headers"
            :items-per-page="10"
            :loading="loadingState"
            :footer-props="footerProps"
            :sort-by="['ringnumber']"
            :sort-desc="[false, true]"
            :multi-sort="true"
            @click:row="clicked"
        >
            <template v-slot:[`item.pigeon_name`]="{ item }">
                <a
                    v-text="item.pigeon_name"
                    :href="`/pigeon/${item.id}`"
                    class="tw-cursor-pointer hover:tw-text-blue-400"
                ></a>
            </template>
            <template v-slot:[`item.ringnumber`]="{ item }">
                <a
                    v-text="item.ringnumber"
                    :href="`/pigeon/${item.id}`"
                    class="tw-cursor-pointer hover:tw-text-blue-400"
                ></a>
            </template>
            <template v-slot:[`item.ringnumber_father`]="{ item }">
                <span v-if="item.father">
                    <a
                        v-if="item.father.public"
                        v-text="item.ringnumber_father"
                        :href="`/pigeon/${item.father_id}`"
                        class="tw-cursor-pointer hover:tw-text-blue-400"
                    ></a>
                    <span v-else v-text="$t('user.not-available')"></span>
                </span>
            </template>
            <template v-slot:[`item.ringnumber_mother`]="{ item }">
                <span v-if="item.mother">
                    <a
                        v-if="item.mother.public"
                        v-text="item.ringnumber_mother"
                        :href="`/pigeon/${item.mother_id}`"
                        class="tw-cursor-pointer hover:tw-text-blue-400"
                    ></a>
                    <span v-else v-text="$t('user.not-available')"></span>
                </span>
            </template>
            <template v-slot:[`item.gender`]="{ item }">
                <span v-html="formatGender(item.gender)"></span>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
export default {
    props: ['publicPigeons'],

    data() {
        return {
            headers: [
                { text: trans('list.name'), value: 'pigeon_name', align: 'start' },
                { text: trans('list.landcode'), value: 'landcode', align: 'start' },
                { text: trans('list.ringnumber'), value: 'ringnumber', align: 'start' },
                {
                    text: trans('list.ringnumberfather'),
                    value: 'ringnumber_father',
                    align: 'start',
                },
                {
                    text: trans('list.ringnumbermother'),
                    value: 'ringnumber_mother',
                    align: 'start',
                },
                { text: trans('list.color'), value: 'pigeon_color', align: 'start' },
                { text: trans('list.gender'), value: 'gender', align: 'start' },
            ],
            items: this.publicPigeons,
            expanded: [],
            search: '',
            footerProps: { 'items-per-page-options': [5, 10, 15, 30, 50, 100] },

            dialog: false,
            loadingState: false,
        };
    },

    methods: {
        formatGender(gender) {
            return gender == 'male'
                ? '<i class="fas fa-mars fa-fw tw-text-blue-500 tw-text-xl"></i>'
                : '<i class="fas fa-venus fa-fw tw-text-red-500 tw-text-xl"></i>';
        },

        clicked(value, data) {
            if (event.target.tagName == 'TD') {
                if (!data.isExpanded) {
                    this.expanded.push(value);
                } else {
                    this.expanded = this.expanded.filter((pigeon) => {
                        return value.id != pigeon.id;
                    });
                }
            }
        },
    },
};
</script>
