
<template v-cloak>
    <div
        id="pigeon_edit_pedigree_details"
        class="
            tw-bg-white tw-w-full tw-mx-auto tw-px-2 tw-py-2
            lg:tw-px-3 lg:tw-py-3
            tw-rounded tw-shadow
        "
        style="max-width: 800px"
    >
        <span
            class="tw-text-xl tw-relative tw-block"
            v-text="$t('pedigree.pedigree-details')"
        ></span>

        <div class="tw-flex tw-flex-col lg:tw-flex-row tw-mt-2">
            <div class="tw-w-full lg:tw-w-1/3 tw-block tw-mb-3 lg:tw-mb-0">
                <div id="pedigree-preview" class="node tw-node tw-relative hover:tw-shadow">
                    <span
                        class="pedigree--pigeon_gender pedigree-details-fix"
                        v-html="formatGender()"
                    ></span>
                    <span
                        class="pedigree--pigeon_ringnumber"
                        v-text="`${pigeon.landcode} ${pigeon.ringnumber}`"
                    ></span>
                    <span class="pedigree--pigeon_name" v-text="pigeon_name"></span>
                    <span v-if="show_breeder">
                        <span class="pedigree--pigeon_owner" v-text="printBreeder()"></span>
                    </span>
                    <span
                        class="pedigree--pigeon_color"
                        v-if="pigeon.pigeon_color"
                        v-text="pigeon.pigeon_color"
                    ></span>
                    <div
                        class="pedigree--pigeon_info"
                        v-if="strippedData"
                        v-html="strippedData"
                    ></div>
                </div>
                <!-- <span class="text-xs relative block mt-1" v-text="$t('pedigree.example')"></span> -->
            </div>

            <div class="tw-flex tw-flex-col tw-w-full lg:tw-w-2/3">
                <div class="tw-flex tw-flex-row lg:tw-mb-4">
                    <div class="tw-w-full tw-pr-1">
                        <ckeditor
                            :editor="editor"
                            v-model="editorData"
                            :config="editorConfig"
                            @ready="onEditorReady"
                            @input="onEditorInput"
                        ></ckeditor>
                        <p id="counter"></p>
                        <!-- <span
                            :class="currentLengthColor"
                            class="tw-float-right"
                        >{{ currentLength }}/150</span>-->
                    </div>
                </div>

                <co-breeder-overwrite-pedigree-details
                    :endpoint_show_breeder="endpoint_show_breeder"
                ></co-breeder-overwrite-pedigree-details>
            </div>
        </div>

        <div class="tw-flex tw-justify-end tw-mt-3">
            <input
                type="submit"
                class="btn-primary tw-rounded tw-cursor-pointer"
                :value="$t('pedigree.update-pedigree-details')"
                @click="submit"
            />
        </div>

        <v-dialog v-model="submitting" persistent width="300">
            <v-card color="green darken-2" dark>
                <v-card-text>
                    {{ $t('messages.saving-pedigree-details') }}
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import xss from 'xss';
import TurndownService from 'turndown';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/nl';
import CoBreederOverwritePedigreeDetails from './CoBreederOverwritePedigreeDetails.vue';

const toolbarNonMember = ['undo', 'redo', '|'];

const toolbarMember = [
    '|',
    'bold',
    'italic',
    '|',
    'bulletedList',
    'numberedList',
    'blockquote',
    '|',
    'undo',
    'redo',
    '|',
];

export default {
    props: ['pigeon', 'isMember', 'endpoint_details', 'endpoint_show_breeder'],

    data() {
        return {
            editor: ClassicEditor,
            editorData: this.pigeon.info ? this.pigeon.info.formatted_info : '',
            editorConfig: {
                language: window.location.pathname.substring(1, 3),
                toolbar: this.isMember ? toolbarMember : toolbarNonMember,
                removePlugins: ['Link'],
            },
            currentLength: 0,
            currentLengthColor: 'text-green-500',
            submitting: false,
        };
    },

    components: {
        CoBreederOverwritePedigreeDetails,
    },

    mounted() {
        this.$store.dispatch('changePedigreeDetailsPigeonObjectAction', this.pigeon);

        this.$store.dispatch('changeBreederAction', this.pigeon.info.breeder);
        this.$store.dispatch('changePigeonNameAction', this.pigeon.info.pigeon_name);
    },

    computed: {
        xssProtectedData() {
            const options = {
                whiteList: {
                    p: [],
                    i: [],
                    br: [],
                    em: [],
                    h2: [],
                    h3: [],
                    h4: [],
                    ul: [],
                    ol: [],
                    li: [],
                    strong: [],
                    blockquote: [],
                },
            };
            return xss(this.editorData, options);
        },

        strippedData() {
            return this.xssProtectedData;
        },

        markdownData() {
            return new TurndownService().turndown(this.xssProtectedData);
        },

        pigeon_name() {
            return this.$store.state.pigeon.pigeon_name;
        },

        breeder() {
            return this.$store.state.breeder.breeder;
        },

        show_breeder() {
            return this.$store.state.breeder.show_breeder;
        },
    },

    methods: {
        submit() {
            this.submitting = true;
            axios
                .post(route(this.endpoint_details, this.pigeon.id), {
                    pigeon_info: this.markdownData,
                    pigeon_name: this.pigeon_name,
                    breeder: this.breeder,
                })
                .then((res) => {
                    flash(res.data, 'success');
                    this.submitting = false;
                })
                .catch((err) => {
                    flash(err.response.data.errors.pigeon_info[0], 'error');
                    this.submitting = false;
                });
        },

        onEditorReady(editor) {
            this.currentLength = this.markdownData
                .replace(/<[^>]*>?/gm, '')
                .replace(/(\r\n|\n|\r)/gm, '').length;

            this.getLengthColor();
        },

        onEditorInput(value) {
            this.currentLength = value
                .replace(/<[^>]*>?/gm, '')
                .replace(/(\r\n|\n|\r)/gm, '').length;

            this.getLengthColor();
        },

        getLengthColor() {
            if (this.currentLength > 90 && this.currentLength < 125) {
                this.currentLengthColor = 'tw-text-orange-500';
            } else if (this.currentLength > 150) {
                this.currentLengthColor = 'tw-text-red-500';
            } else {
                this.currentLengthColor = 'tw-text-green-500';
            }
        },

        formatGender() {
            if (this.pigeon.gender == null || this.pigeon.gender == undefined) {
                return '';
            } else {
                return this.pigeon.gender === 'male'
                    ? '<i class="fas fa-mars male-color-strong tw-text-xl"></i>'
                    : '<i class="fas fa-venus female-color-strong tw-text-xl"></i>';
            }
        },

        printBreeder() {
            return this.pigeon.info.breeder;
        },
    },
};
</script>

<style>
.pedigree-details-fix {
    top: 5px;
}

.v-input__slot {
    margin: 0;
}
</style>
