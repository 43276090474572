export const pigeon = {
    state: () => ({
        pigeons: [],
        pedigreeDetailsPigeonObject: {},
        pigeon_name: ''
    }),

    getters: {
        getPostCreateModalSelectedPigeons: state => {
            return state.pigeons;
        },

        getPostCreateSelectedResults: (state) => (index) => {
            return state.pigeons[index].chosen_results;
        },
    },

    mutations: {
        UPDATE_SELECTED_PIGEONS(state, pigeons) {
            if (typeof pigeons === 'undefined') {
                state.pigeons = [];
            } else {
                pigeons.forEach(pigeon => {
                    if (!state.pigeons.includes(pigeon)) {
                        state.pigeons.push(pigeon);
                    }
                });
            }
        },
        DELETE_SELECTED_PIGEON(state, index) {
            state.pigeons.splice(index, 1);
        },

        UPDATE_SELECTED_RESULTS(state, payload) {
            const results = payload[0];
            const index = payload[1];

            Vue.set(state.pigeons[index], 'chosen_results', results);
        },

        changePedigreeDetailsPigeonObject(state, payload) {
            state.pedigreeDetailsPigeonObject = payload;
        },

        changePigeonName(state, payload) {
            state.pigeon_name = payload;
        },
    },

    actions: {
        changePedigreeDetailsPigeonObjectAction({ commit }, pedigreeDetailsPigeonObject) {
            commit("changePedigreeDetailsPigeonObject", pedigreeDetailsPigeonObject);
        },

        changePigeonNameAction({ commit }, pigeon_name) {
            commit("changePigeonName", pigeon_name);
        },
    },
};
