<template>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600"
        @keydown.esc="dialog = false"
        @keydown.enter="addResultsToPostCreate"
        content-class="tw-overflow-y-visible"
    >
        <template v-slot:activator="{ on }">
            <v-btn
                v-if="pigeon.results.length > 0"
                color="primary darken-1"
                small
                v-on="on"
                dusk="add-results-with-pigeon"
            >
                <i class="far fa-plus-square"></i>
                <span v-text="$t('social.add-results')"></span>
                <span v-text="'(' + pigeon.chosen_results.length + ')'"></span>
            </v-btn>
            <span v-else class="tw-text-sm" v-t="'social.pigeon-no-results-found'"></span>
        </template>
        <v-card>
            <v-card-title
                class="tw-flex tw-justify-between tw-text-gray-600 tw-bg-gray-100 tw-px-4 tw-py-2 tw-mb-2"
            >
                {{ $t('social.add-results-for') }}
                <strong>{{ pigeon.ringnumber | formatRingnumber(pigeon.landcode) }}</strong>
            </v-card-title>
            <v-card-text>
                <div class="tw-px-4 tw-py-2">
                    <label class="typo__label" v-text="$t('social.pick-some-results')"></label>
                    <multiselect
                        v-model="value"
                        :options="options"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        :placeholder="$t('social.pick-some')"
                        :custom-label="resultLabel"
                        track-by="id"
                        :max="5"
                    >
                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                            <span
                                class="multiselect__single"
                                v-if="values.length &amp;&amp; !isOpen"
                                >{{ values.length }} {{ $t('social.options-selected') }}</span
                            >
                        </template>
                        <template slot="option" slot-scope="props"
                            >{{ props.option.date_start_time }} • {{ props.option.place }} •
                            {{ props.option.unloading_location }} •
                            {{ props.option.velocity }}</template
                        >
                        <template
                            slot="maxElements"
                            v-text="$t('social.max-amount-of-options')"
                        ></template>
                    </multiselect>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="error darken-1"
                    text
                    @click="dialog = false"
                    v-text="$t('helpers.close')"
                ></v-btn>
                <v-btn
                    color="primary darken-1"
                    text
                    @click="addResultsToPostCreate"
                    v-text="$t('social.add-results')"
                    dusk="add-result-to-post"
                ></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
    props: ['index', 'pigeon'],

    data() {
        return {
            dialog: false,
            value: [],
            showAddResultInput: false,
        };
    },

    computed: {
        options() {
            return this.pigeon.results;
        },

        selectedResults: {
            get: function () {
                return this.$store.getters.getPostCreateSelectedResults(this.index);
            },
            set: function () {
                this.$store.commit('UPDATE_SELECTED_RESULTS', [this.value, this.index]);
            },
        },
    },

    components: {
        Multiselect,
    },

    methods: {
        enableAddResultsInput() {
            this.showAddResultInput = true;
        },

        resultLabel({ date_start_time, place, unloading_location, velocity }) {
            return `${date_start_time} • ${place} • ${unloading_location} • ${velocity}`;
        },

        addResultsToPostCreate() {
            this.$store.commit('UPDATE_SELECTED_RESULTS', [this.value, this.index]);
            this.dialog = false;
        },

        removeSelectedResult(index) {
            this.$store.commit('DELETE_SELECTED_RESULT', index);
            this.value.splice(index, 1);
        },
    },

    mounted() {
        if (Array.isArray(this.selectedResults) && this.selectedResults.length) {
            this.value = this.selectedResults;
        }
    },
};
</script>

<style scoped>
.v-dialog {
    overflow-y: visible !important;
}
</style>
