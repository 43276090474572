$(document).ready(function () {
    const signUpButton = $('#signUp');
    const signInButton = $('#signIn');
    const switchForm = $('.switch-form');
    const registerButton = $('#registerButton');
    const loginButton = $('#loginButton');
    const oldRegister = $("[data-old-register]");
    const signUpContainer = $('.sign-up-container');
    const signInContainer = $('.sign-in-container');

    // Mobile Responsive Classes
    var w = $(window).width();

    if (w < 1025) {
        registerButton.on('click', function () {
            signUpContainer.css({
                "z-index": 5,
                "opacity": 1,
            });
            signInContainer.css({
                "z-index": 2,
                "opacity": 0,
            });
        });

        loginButton.on('click', function () {
            signInContainer.css({
                "z-index": 5,
                "opacity": 1,
            });
            signUpContainer.css({
                "z-index": 2,
                "opacity": 0,
            });
        });
    } else {
        signUpButton.on('click', function () {
            switchForm.addClass("right-panel-active");
        });

        signInButton.on('click', function () {
            switchForm.removeClass("right-panel-active");
        });

        registerButton.on('click', function () {
            switchForm.addClass("right-panel-active");
        });

        loginButton.on('click', function () {
            switchForm.removeClass("right-panel-active");
        });

        if (oldRegister.length) {
            switchForm.addClass("right-panel-active");
        }

    }

});
