<template>
    <v-card>
        <UserActivity :active="active" />
        <Geographics :users="getUsersWithLocation" v-if="getUsersWithLocation.length > 0" />
    </v-card>
</template>

<script>
import Geographics from './Geographics.vue';
import UserActivity from './UserActivity.vue';

export default {
    props: ['active'],

    components: {
        Geographics,
        UserActivity
    },

    data() {
        return {
            headers: [
                {
                    text: 'User',
                    value: 'user_name',
                    align: 'start',
                    sortable: true,
                },
                {
                    text: 'Amount of pigeons',
                    value: 'amount_of_pigeons',
                    align: 'start',
                    sortable: true,
                },
                {
                    text: 'First active',
                    value: 'first_active',
                    align: 'start',
                    sortable: true,
                },
                {
                    text: 'Last active',
                    value: 'last_active',
                    align: 'start',
                    sortable: true,
                },
            ],
        };
    },

    computed: {
        getUsersWithLocation() {
            const uniqueUsers = Object.values(this.active).reduce((accumulator, pigeons) => {
                pigeons.forEach((pigeon) => {
                    const { owner } = pigeon;
                    const key = owner.name;

                    if (!accumulator[key]) {
                        accumulator[key] = {
                            user_name: owner.name,
                            fancier_lat: owner.fancier_lat,
                            fancier_long: owner.fancier_long,
                            fancier_country: owner.fancier_country || 'Unknown',
                        };
                    }
                });

                return accumulator;
            }, {});

            return Object.values(uniqueUsers);
        },
    },
};
</script>