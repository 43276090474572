<template>
    <div>
        <div class="tw-flex tw-flex-wrap">
            <div
                class="tw-w-full md:tw-w-1/2 xl:tw-w-1/3"
                v-for="(user, index) in users"
                :key="index"
            >
                <user-card :user="user" :card-index="index"></user-card>
            </div>
        </div>
        <infinite-loading :distance="1" @infinite="infiniteHandler">
            <div slot="no-more" v-text="$t('social.no-more')"></div>
            <div slot="no-results" v-text="$t('social.no-results')"></div>
        </infinite-loading>
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';

export default {
    components: {
        InfiniteLoading,
    },

    props: ['route'],

    data() {
        return {
            page: 1,
            users: [],
        };
    },

    methods: {
        infiniteHandler($state) {
            axios
                .post(this.route, {
                    params: {
                        page: this.page,
                    },
                })
                .then(({ data }) => {
                    if (this.page <= data.meta.last_page) {
                        this.users = this.users.concat(data.data);
                        $state.loaded();
                        if (this.users.length / 20 === 10) {
                            $state.complete();
                        }
                        this.page = this.page + 1;
                    } else {
                        $state.complete();
                    }
                });
        },
    },
};
</script>
