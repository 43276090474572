<template>
    <div class="tw-p-2 tw-bg-white">
        <div dusk="clock_members">
            <ul class="max-h-400 tw-overflow-y-scroll">
                <li v-for="(member, index) in members" :key="index">
                    <clock-member :member="member"></clock-member>
                </li>
            </ul>
        </div>

        <div class="tw-pl-2 tw-pr-2 tw-pb-2" v-if="authorizations.update">
            <clock-member-add :clock-group-id="clockGroupId"></clock-member-add>
            <span
                class="tw-group tw-mt-4 tw-text-gray-600 tw-text-xs tw-bg-blue-100 tw-p-2 tw-block tw-rounded-lg"
            >
                <i class="fas fa-info-circle tw-group-hover:tw-text-gray-900"></i>
                <span v-html="$t('clock.members-explanation')"></span>
            </span>
        </div>
    </div>
</template>

<script>
import Member from '../Clock/Member.vue';
import MemberAdd from '../Clock/MemberAdd.vue';

export default {
    props: ['clockGroupId', 'authorizations'],

    data() {
        return {
            members: [],
        };
    },

    components: {
        'clock-member': Member,
        'clock-member-add': MemberAdd,
    },

    mounted() {
        axios.get(`/clock_group/${this.clockGroupId}/members`).then((res) => {
            this.members = res.data;
        });
    },
};
</script>

