<template>
    <section class="tw-relative">
        <button
            type="Submit"
            value="Screen"
            class="tw-bg-green-500 tw-shadow tw-text-white tw-text-sm tw-rounded tw-p-2 hover:tw-opacity-75 tw-mt-2 lg:tw-mt-0"
            @click="show = !show"
        >
            <i class="far fa-file-pdf"></i>
            <span class="tw-ml-1" v-text="$t('pedigree.save-pedigree')"></span>
        </button>

        <div
            class="tw-absolute tw-bg-white tw-p-4 tw-z-100 tw-rounded-lg tw-shadow-xl tw-right-0 tw-mt-2 tw-border-4 tw-border-gray-100"
            style="width: 230px; bottom: 40px"
            v-show="show"
        >
            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center tw-py-1">
                <v-switch
                    v-model="withImage"
                    color="green darken-2"
                    :disabled="isMember == 1 ? false : true"
                    :label="$t('pedigree.with-image')"
                ></v-switch>
            </div>

            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center tw-py-1">
                <v-switch
                    v-model="withWatermark"
                    color="green darken-2"
                    :disabled="isMember == 1 ? false : true"
                    :label="$t('pedigree.with-watermark')"
                ></v-switch>
            </div>

            <div class="tw-flex tw-flex-row tw-justify-between tw-items-center tw-pt-1 tw-pb-3">
                <v-switch
                    v-model="withInfo"
                    color="green darken-2"
                    :label="$t('pedigree.with-info')"
                ></v-switch>
            </div>

            <button
                type="Submit"
                value="Screen"
                class="tw-bg-green-500 tw-shadow tw-text-white tw-text-sm tw-rounded tw-p-2 tw-w-full"
                :class="dialog || 'hover:opacity-75'"
                :disabled="dialog"
                v-on:click="savePedigree"
            >
                <i class="fas fa-download"></i>
                <span class="tw-ml-1" v-text="$t('pedigree.generate-pedigree')"></span>
            </button>
        </div>

        <v-dialog v-model="dialog" persistent width="300">
            <v-card color="green darken-2" dark>
                <v-card-text>
                    {{ $t('messages.generating-pedigree') }}
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </section>
</template>

<script>
export default {
    props: ['isMember', 'pigeon'],

    data() {
        return {
            show: false,

            withImage: false,
            withWatermark: true,
            withInfo: true,

            dialog: false,
        };
    },

    methods: {
        savePedigree() {
            if (this.dialog == false) {
                this.dialog = true;

                axios
                    .get(`/pigeon/${this.pigeon.id}/tree/action`, {
                        params: {
                            withImage: this.withImage,
                            withWatermark: this.withWatermark,
                            withInfo: this.withInfo,
                        },
                        responseType: 'blob',
                    })
                    .then((res) => {
                        this.dialog = false;

                        const fileURL = window.URL.createObjectURL(new Blob([res.data]));
                        const fileLink = document.createElement('a');

                        const fileName = `${this.pigeon.ringnumber}_pedigree_${Date.now('YmdHis')}`;

                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', `${fileName}.pdf`);
                        document.body.appendChild(fileLink);

                        fileLink.click();
                    })
                    .catch((err) => {
                        this.dialog = false;
                    });
            }
        },
    },
};
</script>
