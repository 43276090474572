<template>
    <div class="tw-text-center tw-w-full">
        <img
            v-if="currentAvatarMutable"
            :src="currentAvatarMutable"
            class="tw-rounded-t-lg tw-w-full"
            :class="withShadow ? 'tw-shadow-xl' : ''"
        />
        <!-- Adding image currently unavailable. -->
        <button
            v-if="authorizedToUpdate"
            id="pick-avatar"
            class="tw-bg-primary-500 hover:tw-opacity-75 tw-p-2 tw-rounded-b tw-text-white tw-w-full"
            v-text="$t('image-upload.select-an-image')"
        ></button>
        <avatar-cropper
            v-if="authorizedToUpdate"
            @uploading="handleUploading"
            @uploaded="handleUploaded"
            @completed="handleCompleted"
            @error="handlerError"
            trigger="#pick-avatar"
            :upload-url="uploadUrl"
            :labels="labels"
            :upload-headers="headers"
            :cropper-options="cropperOptions"
            :output-options="outputOptions"
            output-mime="image/jpeg"
            :output-quality="0.9"
            mimes="image/png, image/gif, image/jpeg, image/jpg"
        />

        <v-dialog v-model="submitting" persistent width="300">
            <v-card color="green darken-2" dark>
                <v-card-text>
                    {{ $t('messages.saving') }}
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import AvatarCropper from 'vue-avatar-cropper';

export default {
    props: ['currentAvatar', 'uploadUrl', 'model', 'withShadow'],

    data() {
        return {
            currentAvatarMutable: this.currentAvatar,
            labels: {
                submit: window.trans('image-upload.submit'),
                cancel: window.trans('image-upload.cancel'),
            },
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-TOKEN': document
                    .querySelector('meta[name="csrf-token"]')
                    .getAttribute('content'),
            },
            outputOptions: {
                width: 512,
                height: 512,
            },
            cropperOptions: {
                aspectRatio: 1,
                autoCropArea: 1,
                viewMode: 1,
                movable: true,
                zoomable: true,
            },
            authorizedToUpdate: false,
            submitting: false,
        };
    },

    components: { AvatarCropper },

    methods: {
        handleUploading(form, xhr) {
            this.submitting = true;
        },

        handleUploaded(res) {
            this.currentAvatarMutable = `data:image/jpeg;base64,${res.image}`;
            this.submitting = false;
        },

        handleCompleted(res, form, xhr) {
            if (xhr.status == 200) {
                flash('Avatar changed', 'success');
            }
        },

        handlerError(message, type, xhr) {
            flash(xhr.statusText, 'error');
        },
    },

    mounted() {
        this.authorizedToUpdate = this.model.authorizations.update;
    },
};
</script>

<style>
.avatar-cropper-container {
    border-radius: 5px;
}
</style>
