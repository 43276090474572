<template>
    <div
        class="tw-absolute tw-bg-white max-h-400 tw-overflow-y-auto tw-rounded tw-shadow-2xl tw-right-0 notifications-wrapper top-media"
        dusk="notification_wrapper"
    >
        <ul v-if="notifications.length > 0">
            <li
                class="tw-bg-gray-100 tw-border-b tw-text-sm hover:tw-bg-white"
                :key="index"
                v-for="(notification, index) in notifications"
            >
                <notification
                    :notification="notification"
                    :data-id="notification.id"
                ></notification>
            </li>
            <!-- <li class="tw-bg-gray-100 tw-border-b tw-text-sm hover:tw-bg-white">
                <a href>Clear all notications</a>
            </li>-->
        </ul>
        <ul class v-else>
            <li class="tw-text-sm">
                <span
                    class="tw-block tw-py-2 tw-pl-2"
                    v-text="$t('notification-bell.no-new')"
                ></span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: ['notifications'],
};
</script>
