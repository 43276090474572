<template>
    <a
        id="notification-route"
        class="tw-block tw-w-full tw-h-full tw-p-3"
        :href="routeNotification(notification)"
    >
        <div class="tw-flex tw-flex-row tw-relative">
            <img class="tw-w-16 tw-h-16 tw-rounded-full tw-mr-3" :src="this.avatar" />
            <div class="tw-flex tw-flex-col">
                <span v-html="notificationText(notification)"></span>
                <span class="tw-text-xs tw-mt-1">
                    <i class="far fa-clock"></i>
                    {{ notification.created_at | ago }}
                </span>
            </div>
            <!-- <a
                href="#"
                @click="markAsRead(notification)"
                class="close tw--mt-2 tw-absolute tw-top-0 tw-right-0 tw-mr-2 tw-text-2xl hover:tw-opacity-75"
                data-dismiss="alert"
                aria-label="close"
            >&times;</a> -->
        </div>
    </a>
</template>

<script>
import moment from 'moment';
var language = window.location.pathname.substring(1, 3);

export default {
    props: ['notification'],

    data() {
        return {
            avatar: this.notification.avatar,
            NOTIFICATION_TYPES: [
                'App\\Notifications\\UserFollowed',
                'App\\Notifications\\NewPost',
                'App\\Notifications\\RecoverRequestNotification',
                'App\\Notifications\\NewComment',
                'App\\Notifications\\PigeonClocked',
                'App\\Notifications\\PigeonUnclocked',
                'App\\Notifications\\RecoverRequestAcceptedNotification',
                'App\\Notifications\\RecoverRequestRejectedNotification',
            ],
        };
    },

    filters: {
        ago(date) {
            return moment(date).locale(language).local().fromNow();
        },
    },

    methods: {
        /**
         * Get the notification route based on it's type
         */
        routeNotification(notification) {
            switch (notification.type) {
                case this.NOTIFICATION_TYPES[0]: // UserFollowed
                    var userId = notification.data.issuer_id;
                    var url = 'user/' + userId + '?read=' + notification.id;
                    break;

                case this.NOTIFICATION_TYPES[1]: // NewPost
                    var postId = notification.data.post_id;
                    var url = 'post/' + postId + '?read=' + notification.id;
                    break;

                case this.NOTIFICATION_TYPES[3]: // NewComment
                    var postId = notification.data.post_id;
                    var commentId = notification.data.comment_id;
                    var url = 'post/' + postId + '?read=' + notification.id + '#' + commentId;
                    break;

                case this.NOTIFICATION_TYPES[2]: // RecoverRequestNotification
                case this.NOTIFICATION_TYPES[6]: // RecoverRequestAcceptedNotification
                case this.NOTIFICATION_TYPES[7]: // RecoverRequestRejectedNotification
                    var url = 'pigeon/recover/index?read=' + notification.id;
                    break;

                case this.NOTIFICATION_TYPES[4]: // PigeonClocked
                    var clockGroupId = notification.data.clock_group_id;
                    var url = 'clock/' + clockGroupId + '?read=' + notification.id;
                    break;
            }
            return '/' + url;
        },

        markAsRead(notification) {
            axios.get(this.routeNotification(notification));
        },

        /**
         * Generate the message to display for a notification
         */
        notificationText(notification) {
            var text = '';

            switch (notification.type) {
                case this.NOTIFICATION_TYPES[0]: // UserFollowed
                    var name = '<strong>' + notification.data.issuer_name + '</strong>';
                    text += name + window.trans('notification-bell.follows-now');
                    break;

                case this.NOTIFICATION_TYPES[1]: // NewPost
                    var name = '<strong>' + notification.data.issuer_name + '</strong>';
                    text += name + window.trans('notification-bell.published-post');
                    break;

                case this.NOTIFICATION_TYPES[2]: // RecoverRequestNotification
                    var name = '<strong>' + notification.data.issuer_name + '</strong>';
                    var ringnumber =
                        '<strong>' +
                        notification.data.landcode +
                        ' ' +
                        notification.data.ringnumber +
                        '</strong>';
                    text +=
                        name +
                        window.trans('notification-bell.requested-for') +
                        '<br />' +
                        ringnumber;
                    break;

                case this.NOTIFICATION_TYPES[3]: // NewComment
                    var name = '<strong>' + notification.data.issuer_name + '</strong>';
                    text += name + window.trans('notification-bell.commented-on-your-post');
                    break;

                case this.NOTIFICATION_TYPES[4]: // PigeonClocked
                    const clockedParsed = moment(notification.data.clocked).format('HH:mm:ss');
                    var name = '<strong>' + notification.data.issuer_name + '</strong>';
                    var time = '<strong>' + clockedParsed + '</strong>';
                    text += name + trans('clock.just-clocked-a-pigeon') + time;
                    break;

                case this.NOTIFICATION_TYPES[6]: // RecoverRequestAcceptedNotification
                    var name = '<strong>' + notification.data.issuer_name + '</strong>';
                    var ringnumber =
                        '<strong>' +
                        notification.data.landcode +
                        ' ' +
                        notification.data.ringnumber +
                        '</strong>';
                    text += name + ' accepted your request for ownership of: <br />' + ringnumber;
                    break;

                case this.NOTIFICATION_TYPES[7]: // RecoverRequestRejectedNotification
                    var name = '<strong>' + notification.data.issuer_name + '</strong>';
                    var ringnumber =
                        '<strong>' +
                        notification.data.landcode +
                        ' ' +
                        notification.data.ringnumber +
                        '</strong>';
                    text += name + ' rejected your request for ownership of: <br />' + ringnumber;
                    break;
            }

            return text;
        },
    },
};
</script>
