export function figureOutMask(landcode) {
    switch (landcode) {
        case 'AU':
            return '(9999-A{1,8}-9{3,5})|(999-99-9{3,4})';
        case 'BE':
        case 'NL':
        case 'PT':
            return '99-9999999';
        case 'BG':
            return '99-99999';
        case 'CU':
            return '(99-9999)|(99-A{1,4}-9999)';
        case 'CZ':
            return '9{2,4}-9{2,3}-9{2,4}';
        case 'DV':
            return '9{3,5}-99-9{3,4}';
        case 'GB':
            return '99-A99999';
        case 'KU':
        case 'RO':
        case 'TR':
            return '99-999999';
        case 'SK':
            return '99-9{4,5}-9{3,4}';
        case 'ZA':
            return '(99-A{1,20}-9{2,6})|(99-9{5,6})';
        case 'IF':
            return '99-A{1,20}-9{3,6}';
        case 'NPA':
            return '9-99-A{1,10}-9{3,6}';
        case 'SU':
            return '99-9{3,4}';
        case 'NBRC':
            return '99-A{1}-999';
        case 'NEHU':
        case 'IHU':
        case 'WHU':
        case 'NWHU':
            return '99-A{1,10}-9{1,5}';
        case 'ACS':
            return '99-A{1}-99';
        case 'PL':
            return '99-9{4,5}-9{3,5}';
        case 'CHN':

        case 'IN': // India
        case 'KRPA':
        case 'MRPC':
        case 'RRPA':
        case 'WRPC':
        case 'KRPC':
        case 'TRPC':
        case 'RHPA':
        case 'PHPA':
        case 'RPS':
        case 'RPSC':
        case 'MRPA':
        case 'PRPA':
        case 'PMRA':
        case 'SIRPS':
        case 'IRPA':
        case 'ROLR':
        case 'IDI':
        case 'CRPC':
            return '99-9{2,7}';
    }
}
