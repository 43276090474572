<template>
    <a
        :href="'/user/' + member.id"
        class="tw-block tw-py-2 tw-px-1 hover:tw-bg-gray-200 tw-rounded-lg tw-flex"
    >
        <img class="tw-w-8 tw-h-8 lg:tw-w-12 lg:tw-h-12 tw-rounded-full tw-mr-3" :src="user_avatar" />
        <span class="tw-self-center tw-text-sm md:tw-text-base xl:tw-text-md">{{ member.name }}</span>
    </a>
</template>

<script>
import getAvatarUrl from '../../mixins/avatar';

export default {
    props: ['member'],

    data() {
        return {
            deleting: false,
        };
    },

    computed: {
        user_avatar() {
            return getAvatarUrl(this.member);
        },
    },
};
</script>

