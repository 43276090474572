import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import { pigeon } from "./modules/pigeon";
import { breeder } from "./modules/breeder";
import { pigeon_name } from "./modules/pigeon_name";

export const store = new Vuex.Store({
    modules: {
        pigeon,
        breeder,
        pigeon_name,
    },
});
