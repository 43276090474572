<template v-cloak>
    <v-card>
        <v-card-title>
            <span v-text="$t('list.your-pigeons')"></span>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('helpers.search')"
            ></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :single-expand="false"
            :expanded.sync="expanded"
            item-key="id"
            show-expand
            :items-per-page="10"
            class="elevation-1"
            :footer-props="footerProps"
            :loading="loadingState"
            :sort-by="['ringnumber']"
            :sort-desc="[false, true]"
            :multi-sort="true"
            @click:row="clicked"
        >
            <template v-slot:[`item.pigeon_name`]="{ item }">
                <a
                    v-text="item.pigeon_name"
                    :href="`/pigeon/${item.id}`"
                    class="tw-cursor-pointer hover:tw-text-blue-400"
                ></a>
            </template>
            <template v-slot:[`item.ringnumber`]="{ item }">
                <a
                    v-text="item.ringnumber"
                    :href="`/pigeon/${item.id}`"
                    class="tw-cursor-pointer hover:tw-text-blue-400"
                ></a>
            </template>
            <template v-slot:[`item.ringnumber_father`]="{ item }">
                <a
                    v-text="item.ringnumber_father"
                    :href="`/pigeon/${item.father_id}`"
                    class="tw-cursor-pointer hover:tw-text-blue-400"
                ></a>
            </template>
            <template v-slot:[`item.ringnumber_mother`]="{ item }">
                <a
                    v-text="item.ringnumber_mother"
                    :href="`/pigeon/${item.mother_id}`"
                    class="tw-cursor-pointer hover:tw-text-blue-400"
                ></a>
            </template>
            <template v-slot:[`item.gender`]="{ item }">
                <span v-html="formatGender(item.gender)"></span>
            </template>
            <template v-slot:[`item.public`]="{ item }">
                <span v-html="formatPublicVisibility(item.public)"></span>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <div class="tw-flex tw-flex-col lg:tw-flex-row tw-py-3">
                        <a
                            :title="$t('tooltip.view-pigeon-profile')"
                            :href="`pigeon/${item.id}`"
                            class="tw-p-2"
                        >
                            <v-btn text small dusk="pigeon-profile">
                                <i class="far fa-eye fa-fw mr-1"></i>
                                {{ $t('tooltip.view-pigeon-profile') }}
                            </v-btn>
                        </a>
                        <a
                            :title="$t('tooltip.view-pigeon-pedigree-tree')"
                            :href="`pigeon/${item.id}/pedigree/tree`"
                            class="tw-p-2"
                        >
                            <v-btn text small dusk="pigeon-pedigree">
                                <i class="fas fa-tree fa-fw"></i>
                                {{ $t('tooltip.view-pigeon-pedigree-tree') }}
                            </v-btn>
                        </a>
                        <a
                            :title="$t('tooltip.edit-pigeon')"
                            :href="`pigeon/${item.id}/edit`"
                            class="tw-p-2"
                        >
                            <v-btn text small dusk="pigeon-edit">
                                <i class="fas fa-edit fa-fw"></i>
                                {{ $t('tooltip.edit-pigeon') }}
                            </v-btn>
                        </a>
                        <span :title="$t('tooltip.delete-pigeon')" class="tw-p-2">
                            <v-dialog
                                v-model="dialog"
                                persistent
                                max-width="600"
                                @keydown.esc="dialog = false"
                                @keydown.enter="deletePigeon(item.id)"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn text small color="error" v-on="on" dusk="pigeon-delete">
                                        <i class="far fa-trash-alt fa-fw"></i>
                                        {{ $t('tooltip.delete-pigeon') }}
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title
                                        v-text="$t('list.are-you-sure')"
                                        class="tw-tracking-tighter"
                                    ></v-card-title>
                                    <v-card-text v-text="$t('list.deleting-info')"></v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="green darken-1"
                                            text
                                            @click="dialog = false"
                                            v-text="$t('result.no')"
                                        ></v-btn>
                                        <v-btn
                                            color="green darken-1"
                                            text
                                            @click="deletePigeon(item.id)"
                                            v-text="$t('result.yes')"
                                            dusk="pigeon-delete-confirm"
                                        ></v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </span>
                    </div>
                </td>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
export default {
    props: ['pigeons'],

    data() {
        return {
            headers: [
                { text: trans('list.name'), value: 'pigeon_name', align: 'start' },
                { text: trans('list.landcode'), value: 'landcode', align: 'start' },
                { text: trans('list.ringnumber'), value: 'ringnumber', align: 'start' },
                {
                    text: trans('list.ringnumberfather'),
                    value: 'ringnumber_father',
                    align: 'start',
                },
                {
                    text: trans('list.ringnumbermother'),
                    value: 'ringnumber_mother',
                    align: 'start',
                },
                { text: trans('list.color'), value: 'pigeon_color', align: 'start' },
                { text: trans('list.gender'), value: 'gender', align: 'start' },
                { text: trans('list.public'), value: 'public', align: 'start' },
                { text: trans('list.actions'), value: 'data-table-expand' },
            ],
            items: this.pigeons,
            expanded: [],
            search: '',
            footerProps: { 'items-per-page-options': [5, 10, 15, 30, 50, 100] },

            dialog: false,
            loadingState: false,
        };
    },

    computed: {},

    methods: {
        formatGender(gender) {
            return gender == 'male'
                ? '<i class="fas fa-mars fa-fw tw-text-blue-500 tw-text-xl"></i>'
                : '<i class="fas fa-venus fa-fw tw-text-red-500 tw-text-xl"></i>';
        },

        formatPublicVisibility(visibility) {
            return visibility == 1
                ? '<i class="far fa-eye fa-fw tw-text-blue-500 tw-text-xl"></i>'
                : '<i class="fas fa-eye-slash fa-fw tw-text-gray-500 tw-text-xl"></i>';
        },

        deletePigeon(pigeonId) {
            this.loadingState = true;

            axios
                .delete(route('pigeon.destroy', pigeonId).url())
                .then((res) => {
                    this.items = this.items.filter((pigeon) => {
                        return pigeonId != pigeon.id;
                    });
                    flash(res.data['message'], res.data['type']);
                    this.dialog = false;
                    this.loadingState = false;
                })
                .catch((err) => {
                    this.loadingState = false;
                });
        },

        clicked(value, data) {
            if (event.target.tagName == 'TD') {
                if (!data.isExpanded) {
                    this.expanded.push(value);
                } else {
                    this.expanded = this.expanded.filter((pigeon) => {
                        return value.id != pigeon.id;
                    });
                }
            }
        },
    },
};
</script>
