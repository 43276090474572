<template v-cloak>
    <v-card>
        <v-card-title>
            <span v-text="$t('recover.issued-requests')"></span>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('helpers.search')"
            ></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page="10"
            :footer-props="footerProps"
            :loading="loadingState"
            :multi-sort="true"
            item-key="id"
            class="elevation-1"
        >
            <template v-slot:item.landcode="{ item }">
                <span>{{ item.landcode }}</span>
            </template>

            <template v-slot:item.ringnumber="{ item }">
                <a :href="`/pigeon/${item.id}`" class="tw-cursor-pointer hover:tw-text-blue-400">
                    <span>{{ item.ringnumber }}</span>
                </a>
            </template>
            <template v-slot:item.info="{ item }">
                <span
                    class="tw-cursor-pointer hover:tw-text-primary-400"
                    @click="openMoreInfoModal(item)"
                    v-html="parseLongInfoText(item.info)"
                ></span>
            </template>

            <template v-slot:item.status="{ item }">
                <span class="tw-flex tw-justify-between">
                    {{ item.status }}
                    <v-btn
                        v-if="item.status == 'rejected'"
                        color="yellow lighten-2"
                        :href="`/pigeon/${item.pigeon_id}/dispute`"
                        :link="true"
                    >
                        <v-icon>mdi-account-multiple-outline</v-icon>
                        <span v-text="$t('dispute.start-dispute')"></span>
                    </v-btn>
                </span>
            </template>
        </v-data-table>

        <v-dialog v-model="dialog" max-width="600" @keydown.esc="dialog = false">
            <v-card>
                <v-card-title class="headline" v-text="$t('recover.info')"></v-card-title>
                <v-card-text>{{ infoToShow }}</v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
export default {
    props: ['issuedRecoverRequests'],

    data() {
        return {
            headers: [
                { text: trans('recover.landcode'), value: 'landcode' },
                { text: trans('recover.ringnumber'), value: 'ringnumber' },
                { text: trans('recover.extra-info'), value: 'info' },
                { text: trans('recover.status'), value: 'status' },
            ],
            items: this.issuedRecoverRequests,
            search: '',
            footerProps: { 'items-per-page-options': [5, 10, 15, 30, 50, 100] },

            dialog: false,
            infoToShow: null,
            loadingState: false,
        };
    },

    methods: {
        openMoreInfoModal(itemId) {
            // Very weird way to get the index of the clicked row
            // We pass that index to the items array to get the current info to display in the modal
            this.infoToShow = this.items[this.items.indexOf(itemId)].info;

            // Show the modal
            this.dialog = true;
        },

        parseLongInfoText(info) {
            if (info != null && info != undefined) {
                return info.length > 45 ? info.substring(0, 45) + '...' : info;
            }
        },

        fetchHumanReadableStatus(status) {
            switch (status) {
                case 'accepted':
                    return trans('recover.accepted');
                case 'rejected':
                    return trans('recover.rejected');
                case 'pending':
                    return trans('recover.pending');
            }
        },
    },
};
</script>
