<template>
    <v-row justify="center" class="tw-mt-2">
        <v-btn color="cyan lighten-2" dark @click.stop="dialog = true">
            <i class="fas fa-sync fa-fw tw-mr-2"></i>
            {{ $t('pigeon.new-owner') }}
        </v-btn>

        <v-dialog
            v-model="dialog"
            max-width="600"
            @keydown.esc="dialog = false"
            @keydown.enter="submit"
        >
            <v-card>
                <v-card-title class="headline">{{
                    $t('pigeon.transfer-owner-title')
                }}</v-card-title>

                <v-card-text v-html="$t('pigeon.new-owner-more-info')"></v-card-text>

                <v-list-item>
                    <v-text-field
                        v-model="email"
                        color="success"
                        :label="$t('pigeon.enter-new-owner-email')"
                        :loading="submitting"
                        :disabled="submitting"
                        :rules="emailRules"
                    ></v-text-field>
                </v-list-item>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="red darken-1" text @click="dialog = false">Cancel</v-btn>

                    <v-btn color="green darken-1" text @click="submit">{{
                        $t('pigeon.submit-new-owner')
                    }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    props: ['pigeonId', 'url'],

    data() {
        return {
            dialog: false,
            dialogAreYouSure: false,
            submitting: false,
            email: '',
            emailRules: [
                (v) => !!v || trans('helpers.email-required'),
                (v) => /.+@.+\..+/.test(v) || trans('helpers.email-required'),
            ],
        };
    },

    methods: {
        submit() {
            this.submitting = true;

            axios
                .post(this.url, {
                    new_owner: this.email,
                })
                .then((res) => {
                    this.submitting = false;
                    this.dialog = false;
                    window.location = route('pigeon.index');
                    flash(res.data.success, 'success');
                })
                .catch((err) => {
                    this.submitting = false;
                    this.dialog = false;
                    flash('Something went wrong', 'error');
                });
        },
    },
};
</script>
