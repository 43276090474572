$(document).ready(function () {

    /**
     * Remember scroll position when a form is submitted
     */
    $(function () {
        var pathName = document.location.pathname;
        window.onbeforeunload = function () {
            var scrollPosition = $(document).scrollTop();
            localStorage.setItem('scrollPosition_' + pathName, scrollPosition.toString());
        };
        if (localStorage['scrollPosition_' + pathName]) {
            $(document).scrollTop(localStorage.getItem('scrollPosition_' + pathName));
        }
    });

});
