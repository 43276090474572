$(document).ready(function () {

    /**
     * Confirm if a given new owner is the correct one
     */
    // $('[data-newowner]').submit(function (e) {

    //     var value = document.querySelector('[name="new_owner"]').value;

    //     if (!value) {
    //         return;
    //     }

    //     if (!confirm('Did you really sell this pigeon to ' + value + '?')) {
    //         e.preventDefault();
    //     }

    // });

    /**
     * Mobile menu expansion
     */
    // $('.expander-button').click(function () {

    //     var w = $(window).width();

    //     if (w < 1025) {

    //         $(this).siblings().find('.expander-content')
    //             .slideUp()
    //             .addClass('mobile-menu-active')
    //             .removeClass('mobile-menu-active');

    //         $(this).find('.expander-content')
    //             .removeClass('mobile-menu-active')
    //             .addClass('mobile-menu-active');
    //     }
    // });
});
