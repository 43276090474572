<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            max-width="600"
            @keydown.esc="dialog = false"
            @keydown.enter="startRequest"
        >
            <v-card>
                <v-card-title class="headline">{{
                    $t('pigeon.ringnumber-exists-modal-title')
                }}</v-card-title>
                <v-card-text>{{ $t('pigeon.ringnumber-exists-modal-body3') }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="dialog = false">{{
                        $t('pigeon.ringnumber-exists-modal-cancel')
                    }}</v-btn>
                    <v-btn color="green darken-1" text @click="addAsCoBreeder">{{
                        $t('pigeon.add-co-breeder-button')
                    }}</v-btn>
                </v-card-actions>

                <v-spacer></v-spacer>

                <v-card-title class="headline">{{
                    $t('pigeon.ringnumber-exists-modal-title-my-pigeon')
                }}</v-card-title>
                <v-card-text>{{ $t('pigeon.ringnumber-exists-modal-body1') }}</v-card-text>
                <v-card-text>{{ $t('pigeon.ringnumber-exists-modal-body2') }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="dialog = false">{{
                        $t('pigeon.ringnumber-exists-modal-cancel')
                    }}</v-btn>
                    <v-btn color="green darken-1" text @click="dialogAreYouSure = true">{{
                        $t('pigeon.ringnumber-exists-modal-reclaim')
                    }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogAreYouSure" persistent max-width="600">
            <v-card>
                <v-card-title class="headline">{{ $t('pigeon.are-you-sure-title') }}</v-card-title>
                <v-card-text>{{ $t('pigeon.are-you-sure-body') }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="dialogAreYouSure = false">{{
                        $t('pigeon.are-you-sure-cancel')
                    }}</v-btn>
                    <v-btn color="green darken-1" text @click="startRequest">{{
                        $t('pigeon.are-you-sure-submit')
                    }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
export default {
    props: ['url'],

    data() {
        return {
            dialog: true,
            dialogAreYouSure: false,
        };
    },

    methods: {
        addAsCoBreeder() {
            const landcode = document.getElementById('landcode_node').value;
            const ringnumber = document.getElementById('ringnumber_node').value;

            axios
                .post(this.url, {
                    landcode: landcode,
                    ringnumber: ringnumber,
                })
                .then((res) => {
                    flash(res.data.message, res.data.type);
                    setTimeout(function () {
                        window.location.reload();
                    }, 1500);
                })
                .catch((err) => {
                    flash('error', err);
                });

            this.dialog = false;
            this.dialogAreYouSure = false;
        },

        startRequest() {
            this.dialog = false;
            this.dialogAreYouSure = false;
            window.location.href = '/pigeon/recover/index';
        },
    },
};
</script>
