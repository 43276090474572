export const breeder = {
    state: () => ({
        breeder: null,
        isBreederSetViaPedigreeDetails: false,
        show_breeder: false,
    }),

    mutations: {
        changeBreeder(state, payload) {
            state.breeder = payload;
        },

        changeIsBreederSetViaPedigreeDetails(state, payload) {
            state.isBreederSetViaPedigreeDetails = payload;
        },

        changeShowBreeder(state, payload) {
            state.show_breeder = payload;
        },
    },

    actions: {
        changeBreederAction({ commit }, breeder) {
            commit("changeBreeder", breeder);
        },

        changeIsBreederSetViaPedigreeDetailsAction({ commit }, breeder) {
            commit("changeIsBreederSetViaPedigreeDetails", breeder);
        },

        changeShowBreederAction({ commit }, show_breeder) {
            commit("changeShowBreeder", show_breeder);
        },
    },
};
